import { FunctionComponent } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { generateEmptyItemsArray } from '../../../utils/generateEmptyItems';
import 'swiper/css';
import { CarouselContentProps } from '../../CarouselContent';
import { CarouselItem } from '../CarouselItem';
import { EmptySlide } from '../EmptySlide';

const Carousel8: FunctionComponent<CarouselContentProps> = ({
  itemsCarousel,
  autoplayCarousel,
  speedCarousel,
  objectFit,
}) => {
  const minItems = 3;
  const emptyItems = generateEmptyItemsArray(minItems, itemsCarousel.length);
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2%',
        width: '100%',
        height: '100%',
      }}
    >
      <Swiper
        allowTouchMove={false}
        autoplay={{ disableOnInteraction: false }}
        css={{
          width: '100%',
          height: '80%',
        }}
        modules={autoplayCarousel ? [Autoplay] : []}
        noSwiping={true}
        noSwipingClass="swiper-slide"
        spaceBetween={6}
        speed={speedCarousel}
        style={{ objectFit, pointerEvents: 'none' }}
      >
        {itemsCarousel.map((item) => (
          <SwiperSlide key={item.id}>
            <CarouselItem item={item} objectFit={objectFit} />
          </SwiperSlide>
        ))}
        {emptyItems.map((item) => (
          <SwiperSlide key={item}>
            <EmptySlide>{item}</EmptySlide>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        allowTouchMove={false}
        css={{
          width: '100%',
          height: '20%',
        }}
        freeMode={true}
        noSwiping={true}
        noSwipingClass="swiper-slide"
        slidesPerView={3}
        spaceBetween={6}
        style={{ objectFit, pointerEvents: 'none' }}
        watchSlidesProgress={true}
      >
        {itemsCarousel.map((item) => (
          <SwiperSlide key={item.id}>
            <CarouselItem item={item} />
          </SwiperSlide>
        ))}
        {emptyItems.map((item) => (
          <SwiperSlide key={item}>
            <EmptySlide>{item}</EmptySlide>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel8;
