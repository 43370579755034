import XIcon from '@duyank/icons/regular/X';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { CarouselEditor } from '../../common/carousel-editor/CarouselEditor';
import { useEditor } from '../../hooks';
import Sidebar, { SidebarProps } from './Sidebar';

type CarouselSettingsSidebarProps = SidebarProps;
const CarouselSettingsSidebar: ForwardRefRenderFunction<
  HTMLDivElement,
  CarouselSettingsSidebarProps
> = ({ ...props }, ref) => {
  const { actions } = useEditor();

  return (
    <Sidebar ref={ref} {...props}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Carousel Settings
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            actions.setSidebar();
          }}
        >
          <XIcon />
        </div>
      </div>
      <CarouselEditor />
    </Sidebar>
  );
};

export default forwardRef<HTMLDivElement, CarouselSettingsSidebarProps>(
  CarouselSettingsSidebar
);
