
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const InstagramIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#D8D8D8'
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_818_1456)'>
        <path
          d='M21.27 39.9995H18.77C18.28 39.9395 17.79 39.8895 17.3 39.8195C13.61 39.2995 10.29 37.8895 7.4 35.5195C3.57 32.3795 1.19 28.3695 0.3 23.4895C0.18 22.7495 0.1 21.9995 0 21.2495C0 20.4195 0 19.5795 0 18.7495C0.13 17.8795 0.22 16.9995 0.38 16.1295C2.03 7.54951 9.35 0.899505 18.05 0.0995052C22.33 -0.290495 26.39 0.469505 30.05 2.72951C37.22 7.13951 40.54 13.6195 39.92 22.0095C39.63 25.9795 38.08 29.5195 35.56 32.5995C32.42 36.4295 28.39 38.8095 23.51 39.6995C22.77 39.8295 22.02 39.9095 21.27 40.0095V39.9995ZM8.46 20.0095C8.53 21.9095 8.53 23.8295 8.69 25.7295C8.93 28.6395 10.97 30.8195 13.85 31.2295C15.3 31.4395 16.78 31.4595 18.25 31.4595C20.74 31.4595 23.23 31.4695 25.71 31.2995C28.01 31.1395 29.76 29.9595 30.77 27.8295C31.21 26.8995 31.41 25.8995 31.42 24.8795C31.46 22.1895 31.48 19.4895 31.49 16.7995C31.49 15.9595 31.43 15.1095 31.35 14.2695C31.05 11.3495 29.07 9.18951 26.18 8.76951C24.74 8.55951 23.27 8.53951 21.82 8.53951C19.32 8.53951 16.81 8.5295 14.32 8.6995C11.21 8.9095 8.95 11.2095 8.69 14.3095C8.53 16.1995 8.53 18.0995 8.46 20.0195V20.0095Z'
          fill={color}
        />
        <path
          d='M8.45996 20.0094C8.52996 18.0994 8.52996 16.1894 8.68996 14.2994C8.94996 11.1994 11.21 8.90941 14.32 8.68941C16.81 8.51941 19.32 8.52941 21.82 8.52941C23.28 8.52941 24.75 8.54941 26.18 8.75941C29.07 9.17941 31.05 11.3394 31.35 14.2594C31.44 15.0994 31.49 15.9494 31.49 16.7894C31.49 19.4794 31.47 22.1794 31.42 24.8694C31.4 25.8894 31.21 26.8894 30.77 27.8194C29.76 29.9494 28.01 31.1294 25.71 31.2894C23.23 31.4594 20.74 31.4494 18.25 31.4494C16.78 31.4494 15.3 31.4194 13.85 31.2194C10.97 30.8094 8.92996 28.6294 8.68996 25.7194C8.52996 23.8194 8.52996 21.8994 8.45996 19.9994V20.0094ZM10.56 19.9794C10.61 21.7194 10.62 23.4794 10.72 25.2294C10.86 27.6094 12.39 29.1494 14.76 29.2894C18.26 29.4894 21.77 29.4894 25.27 29.2894C27.64 29.1494 29.25 27.6094 29.31 25.2394C29.4 21.7694 29.41 18.2894 29.31 14.8194C29.24 12.3594 27.67 10.7794 25.22 10.7194C21.75 10.6294 18.26 10.6294 14.79 10.7194C12.39 10.7794 10.86 12.3694 10.72 14.7694C10.62 16.4994 10.61 18.2294 10.56 19.9694V19.9794Z'
          fill='white'
        />
        <path
          d='M10.5596 19.9796C10.6096 18.2396 10.6196 16.4996 10.7196 14.7796C10.8696 12.3796 12.3896 10.7896 14.7896 10.7296C18.2596 10.6396 21.7496 10.6396 25.2196 10.7296C27.6796 10.7996 29.2396 12.3696 29.3096 14.8296C29.3996 18.2996 29.3996 21.7796 29.3096 25.2496C29.2496 27.6196 27.6496 29.1596 25.2696 29.2996C21.7696 29.4996 18.2596 29.4996 14.7596 29.2996C12.3896 29.1596 10.8596 27.6296 10.7196 25.2396C10.6196 23.4896 10.6096 21.7296 10.5596 19.9896V19.9796ZM20.0296 25.8896C23.2896 25.8896 25.9096 23.2696 25.9196 20.0096C25.9296 16.7496 23.2896 14.0996 20.0196 14.0996C16.7596 14.0996 14.1396 16.7196 14.1196 19.9796C14.1096 23.2396 16.7596 25.8896 20.0296 25.8896ZM26.1396 15.2396C26.9196 15.2396 27.5296 14.6396 27.5296 13.8696C27.5296 13.1196 26.9296 12.5096 26.1696 12.4996C25.3896 12.4996 24.7796 13.0996 24.7796 13.8696C24.7796 14.6296 25.3796 15.2296 26.1396 15.2396Z'
          fill={color}
        />
        <path
          d='M20.0301 25.8896C16.7601 25.8896 14.1101 23.2496 14.1201 19.9796C14.1301 16.7196 16.7601 14.1096 20.0201 14.0996C23.2901 14.0996 25.9401 16.7396 25.9201 20.0096C25.9101 23.2696 23.2801 25.8796 20.0301 25.8896ZM23.8501 20.0096C23.8501 17.8996 22.1401 16.1796 20.0401 16.1696C17.9301 16.1696 16.2001 17.8696 16.1901 19.9696C16.1801 22.0896 17.9001 23.8296 20.0201 23.8296C22.1301 23.8296 23.8501 22.1196 23.8501 20.0096Z'
          fill='white'
        />
        <path
          d='M26.1403 15.2401C25.3803 15.2401 24.7903 14.6301 24.7803 13.8701C24.7803 13.1001 25.3903 12.4901 26.1703 12.5001C26.9203 12.5001 27.5303 13.1201 27.5303 13.8701C27.5303 14.6401 26.9103 15.2501 26.1403 15.2401Z'
          fill='white'
        />
        <path
          d='M23.8505 20.01C23.8505 22.11 22.1305 23.83 20.0205 23.83C17.9005 23.83 16.1805 22.1 16.1905 19.97C16.2005 17.87 17.9305 16.16 20.0405 16.17C22.1505 16.17 23.8605 17.9 23.8505 20.01Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_818_1456'>
          <rect fill='white' height={40} width={40} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InstagramIcon
