import XIcon from '@duyank/icons/regular/X';
import { useEditor } from '@lidojs/design-editor';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { carouselModelList } from './data/carousel';

interface CarouselContentProps {
  onClose: () => void;
}

const CarouselContent: FC<CarouselContentProps> = ({ onClose }) => {
  const { actions, isPremium } = useEditor((state) => ({
    isPremium: state.pages[state.activePage].layers.ROOT.data?.isPremium,
  }));

  const addCarousel = (carouselId: string, size: {height: number, width: number}) => {
    if(!isPremium){
      actions.addCarouselLayer( carouselId ,{
        type: {
          resolvedName: 'CarouselLayer',
        },
        props: {
          position: {
            x: 0,
            y: 400,
          },
          boxSize: {
            width: size.width,
            height: size.height,
          },
          rotate: 0,
          color: '#5E6278',
        },
      });
    }

    if (isMobile) {
      onClose();
    }
  };

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Carousel
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClose}
        >
          <XIcon />
        </div>
      </div>
      <div css={{margin: 16}}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '1rem',
          }}
        >
          {carouselModelList.map((carousel) => (
            <div
              key={carousel.id}
              css={{
                border: '1px solid #e5e7eb',
                borderRadius: '0.75rem',
                padding: '1rem 0.5rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                cursor: 'pointer',
                height: '10rem',
                transition: 'border-color 0.2s ease',
                ':hover': {
                  borderColor: '#000000',
                },
              }}
              onClick={() => addCarousel(carousel.id, carousel.size)}
            >
              <p
                css={{
                  textAlign: 'center',
                  fontSize: '0.75rem',
                }}
              >
                {carousel.title}
              </p>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                {carousel.icon}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselContent;
