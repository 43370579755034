import { SerializedLayers } from '@lidojs/design-core';

export function extractFonts(data: SerializedLayers) {
  const fonts = new Set();

  Object.values(data).forEach((node) => {
    if (node.props && Array.isArray(node.props.fonts)) {
      node.props.fonts.forEach((font) => {
        font.fonts.forEach((f) => {
          f.urls.forEach((url) => {
            fonts.add(`
              @import url('${url}');`);
          });
        });
      });
    }
  });

  return Array.from(fonts).join('\n');
}
