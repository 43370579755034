import React, { useEffect, useRef } from 'react';
import { useEditor } from '../../../hooks';
import { ImageLayerProps } from '../../../layers';
import { ImageRenderer } from './ImagePremiumRenderer';
import { VideoRenderer } from './VideoPremiumRenderer';

export const MediaPremiumSettings = () => {
  const { actions, activePage, pages } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
  }));
  const currentPage = pages[activePage].layers;
  const { ROOT, ...restOfcomponents } = currentPage;

  const sortedComponentsArray = Object.entries(restOfcomponents).sort(([keyA], [keyB]) => {
    return keyA.localeCompare(keyB); // sort alphabetically
  });

  const components = Object.fromEntries(sortedComponentsArray);

  const imageObjectUrlsRef = useRef<Map<string, string>>(new Map());
  const videoObjectUrlsRef = useRef<Map<string, string>>(new Map());

  useEffect(() => {
    return () => {
      imageObjectUrlsRef.current.forEach((url) => URL.revokeObjectURL(url));
      videoObjectUrlsRef.current.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []);

  const getObjectUrl = (file: File, type: 'video' | 'image') => {
    let objectUrl;

    if (type === 'image' && imageObjectUrlsRef.current.has(file.name)) {
      objectUrl = imageObjectUrlsRef.current.get(file.name);
    } else if (type === 'video' && videoObjectUrlsRef.current.has(file.name)) {
      objectUrl = videoObjectUrlsRef.current.get(file.name);
    } else {
      objectUrl = URL.createObjectURL(file);
      if (type === 'image') {
        imageObjectUrlsRef.current.set(file.name, objectUrl);
      } else if (type === 'video') {
        videoObjectUrlsRef.current.set(file.name, objectUrl);
      }
    }
    return objectUrl;
  };

  const onChangeMedia = (id: string, file: File, type: 'video' | 'image') => {
    if (file instanceof File) {
      actions.setProp<ImageLayerProps>(activePage, id, {
        [type]: {
          url: file,
        },
      });
    }
  };

  const groupings = ROOT?.data?.props?.groupings || {};

  const renderImageComponents = () => {
    let globalIndex = 1;

    if (groupings['ImageLayer']) {
      return (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            gridColumn: 'span 2 / span 2',
          }}
        >
          {groupings['ImageLayer'].map(
            (group: { id: string; label: string; items: string[] }) => (
              <div key={group.id}>
                <h3
                  css={{
                    fontWeight: 'bolder',
                    marginBottom: 8,
                    textAlign: 'center',
                  }}
                >
                  {group.label}
                </h3>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 16,
                  }}
                >
                  {group.items.map((itemId) => {
                    const component = components[itemId];
                    if (!component) return null;

                    const currentIndex = globalIndex++;
                    return (
                      <ImageRenderer
                        key={itemId}
                        component={component}
                        getObjectUrl={getObjectUrl}
                        index={currentIndex}
                        onChangeMedia={onChangeMedia}
                      />
                    );
                  })}
                </div>
              </div>
            )
          )}
        </div>
      );
    } else {
      return Object.values(components)
        .filter((component) => component.data.type === 'Image')
        .map((component, index) => (
          <ImageRenderer
            key={component.id}
            component={component}
            getObjectUrl={getObjectUrl}
            index={index + 1}
            onChangeMedia={onChangeMedia}
          />
        ));
    }
  };


  const renderVideoComponents = () => {
    if (groupings['VideoLayer']) {
      return (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            gridColumn: 'span 2 / span 2',
          }}
        >
          {groupings['VideoLayer'].map(
            (group: { id: string; label: string; items: string[] }) => (
              <div key={group.id}>
                <h3
                  css={{
                    fontWeight: 'bolder',
                    marginBottom: 8,
                    textAlign: 'center',
                  }}
                >
                  {group.label}
                </h3>
                <div
                  css={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 16,
                  }}
                >
                  {group.items.map((itemId, index) => {
                    const component = components[itemId];
                    if (!component) return null;
                    return (
                      <VideoRenderer
                        key={itemId}
                        component={component}
                        getObjectUrl={getObjectUrl}
                        index={index + 1}
                        onChangeMedia={onChangeMedia}
                      />
                    );
                  })}
                </div>
              </div>
            )
          )}
        </div>
      );
    } else {
      const ungroupedComponents = Object.values(components).filter(
        (component) => component.data.type === 'Video'
      );

      return ungroupedComponents.map((component, index) => (
        <VideoRenderer
          key={component.id}
          component={component}
          getObjectUrl={getObjectUrl}
          index={index + 1}
          onChangeMedia={onChangeMedia}
        />
      ));
    }
  };

  return (
    <>
      {renderImageComponents()}
      {renderVideoComponents()}
    </>
  );

};
