import { SerializedPage } from '@lidojs/design-core';
import { Popover, useEditor } from '@lidojs/design-editor';
import { Button, Select, SelectItem, Spinner } from '@nextui-org/react';
import { useEffect, useRef, useState } from 'react';
import { downloadObjectAsHTML, downloadObjectAsJson } from '../utils/download';
import { generatePageLabels } from 'libs/design-editor/src/ultils/generatePageLabels';

export const ExportButton = () => {
  const exportButtonRef = useRef<HTMLButtonElement>(null);
  const [openExportSetting, setOpenExportSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typeExport, setTypeExport] = useState(new Set(['html']));
  const [pageExport, setPageExport] = useState(new Set(['all']));
  const { query,pagesState } = useEditor((state) => ({
    pagesState: state.pages,
  }));


  const pages = query.serialize();

  const itemsExport = [
    { label: 'HTML', value: 'html' },
    { label: 'JSON', value: 'json' },
  ];

  const itemPages = [
    { label: 'All Pages', id: 'all' },
    ...generatePageLabels(pagesState).filter((page) => page.label.startsWith("Page"))
  ];

  const onChangeTypeExport = (value) => {
    setTypeExport(new Set(value));
  };

  const onChangePageExport = (value) => {
    setPageExport(new Set(value));
  };

  const onDownload = async () => {
    setIsLoading(true);

    try {
      const processAllPages = pageExport.has('all');
      const fileNamePrefix = 'file';

      const downloadHTML = async (pagesToDownload: SerializedPage[]) => {
        await Promise.all(pagesToDownload.map(page => downloadObjectAsHTML(page, pages)));
      };

      const downloadJSON = (pages: SerializedPage[], fileName: string) => {
        downloadObjectAsJson(fileName, pages);
      };

      if (typeExport.has('html')) {
        const pagesToDownload = processAllPages ? pages.filter(item => !item.layers.ROOT.props?.relatesTo) : [pages[Array.from(pageExport)[0]]];
        await downloadHTML(pagesToDownload);
      } else {
        const selectedPageIndex = Array.from(pageExport)[0];
        const fileName = processAllPages
          ? fileNamePrefix
          : `${fileNamePrefix} ${Number(selectedPageIndex) + 1}`;

        const pagesToDownload = processAllPages ? pages : [pages[selectedPageIndex]];
        downloadJSON(pagesToDownload, fileName);
      }
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedPageIndex = Array.from(pageExport)[0];
    if (selectedPageIndex !== 'all' && !pages[selectedPageIndex]) {
      setPageExport(new Set(['all']));
    }
  }, [pages, pageExport]);

  return (
    <>
      <Button
        ref={exportButtonRef}
        css={{
          fontWeight: 700,
          padding: 0,
          ':hover': {
            textDecoration: 'underline',
            backgroundColor: 'inherit',
          },
        }}
        size="lg"
        variant="light"
        onClick={() => setOpenExportSetting(true)}
      >
        Export
      </Button>
      <Popover
        anchorEl={exportButtonRef.current}
        offsets={{
          'bottom-end': { x: 0, y: 8 },
        }}
        open={openExportSetting}
        placement={'bottom-end'}
        onClose={() => setOpenExportSetting(false)}
      >
        <div css={{ padding: 16, minWidth: 220, display: 'grid', rowGap: 8 }}>
          <div>
            <label>File type</label>
            <Select
              aria-label='fileType'
              className="max-w-xs text-black"
              color="primary"
              items={itemsExport}
              name="fileType"
              selectedKeys={typeExport}
              variant="bordered"
              onSelectionChange={onChangeTypeExport}
            >
              {(item) => <SelectItem key={item.value}>{item.label}</SelectItem>}
            </Select>
          </div>
          {pages?.length > 1 && (
            <div>
              <label>Select pages</label>
              <Select
                aria-label='selectPages'
                className="max-w-xs text-black"
                color="primary"
                items={itemPages}
                name="selectPages"
                selectedKeys={pageExport}
                variant="bordered"
                onSelectionChange={onChangePageExport}
              >
                {(item) => (
                  <SelectItem key={item.id}>{item.label}</SelectItem>
                )}
              </Select>
            </div>
          )}

          <Button
            css={{
              background: '#3a3a4c',
              color: '#fff',
              padding: '8px 16px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 40,
              lineHeight: 40,
            }}
            disabled={isLoading}
            isLoading={isLoading}
            spinner={<Spinner className='mr-2' color="default" size="sm" />}
            onClick={() => onDownload()}
          >
            {isLoading ? 'Downloading' : 'Download'}
          </Button>
        </div>
      </Popover>
    </>
  );
};
