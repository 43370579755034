export const sanitizeProps = (props: Record<string, unknown>): Record<string, unknown> => {
  const sanitizedProps: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(props)) {
    sanitizedProps[key] = processValue(value);
  }

  return sanitizedProps;
};

const processValue = (value: unknown): unknown => {
  if (isObject(value)) {
    if (isSvgImage(value)) {
      const svgUrl = convertSvgToBlobUrl(value.image as string);
      return { ...value, image: svgUrl };
    }
  }
  return value;
};

const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null;
};

const isSvgImage = (value: Record<string, unknown>): boolean => {
  return "image" in value &&
         typeof value.image === 'string' &&
         value.image.trim().startsWith('<svg');
};

const convertSvgToBlobUrl = (svgString: string): string => {
  const parser = new DOMParser();
  const ele = parser.parseFromString(svgString.trim(), 'image/svg+xml').documentElement;

  if (ele.nodeName === 'parsererror') {
    console.error('Error parsing SVG:', svgString);
    return svgString;
  }

  const svgBlob = new Blob([ele.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
  return URL.createObjectURL(svgBlob);
};
