import { updateImageCanvas } from '@lidojs/design-core';
import { FrameContent, FrameContentProps } from '@lidojs/design-layers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { EditorContext } from '../editor/EditorContext';
import { useEditor, useLayer, useSelectedLayers } from '../hooks';
import { LayerComponent } from '../types';
import { ImageLayerProps } from './ImageLayer';

export interface FrameLayerProps extends FrameContentProps {
  image:
    | (FrameContentProps['image'] & {
        thumb: string;
      })
    | null;
}

const FrameLayer: LayerComponent<FrameLayerProps> = ({
  layerId,
  clipPath,
  image,
  color,
  gradientBackground,
  boxSize,
  position,
  rotate,
  scale,
  ...props
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { config, uploadImage } = useContext(EditorContext);
  const { actions, pageIndex, id } = useLayer();
  const { selectedLayerIds } = useSelectedLayers();
  const { imageEditor, sidebar, isResizing, isDragging } = useEditor(
    (state) => ({
      imageEditor: state.imageEditor,
      sidebar: state.sidebar,
      isResizing: state.resizeData.status,
      isDragging: state.dragData.status,
    })
  );
  const [imageData, setImageData] = useState<FrameLayerProps['image']>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageSettings, setImageSettings] = useState<{
    brightness?: number | null;
    contrast?: number | null;
    saturation?: number | null;
    hueRotate?: number | null;
    grayscale?: number | null;
    blur?: number | null;
  }>({
    brightness: image?.brightness || null,
    contrast: image?.contrast || null,
    saturation: image?.saturation || null,
    hueRotate: image?.hueRotate || null,
    grayscale: image?.grayscale || null,
    blur: image?.blur || null,
  });
  const [imageEle, setImageEle] = useState<HTMLImageElement | null>(null);

  const showCanvas =
    Boolean(image &&
    (image.brightness || image.contrast || image.saturation || image.hueRotate ||  image.grayscale || image.blur )) ;

  useEffect(() => {
    if (image && !isResizing && !isDragging) {
      const imageUrl = typeof image.url === 'string' ? image.url : URL.createObjectURL(image.url);
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        setImageData((prevState) => {
          if (prevState) {
            return { ...prevState, url: imageUrl };
          }
          return prevState;
        });
        setImageEle(img);
      };
      img.src = imageUrl;
    }
  }, [image, isDragging, isResizing]);

  useEffect(() => {
    const getImageSetting = () => {
      const imgRatio =
        config.frame.defaultImage.width / config.frame.defaultImage.height;
      const boxRatio = boxSize.width / boxSize.height;
      const w =
        imgRatio > boxRatio
          ? (boxSize.height / scale) * imgRatio
          : boxSize.width / scale;
      const h =
        imgRatio > boxRatio
          ? boxSize.height / scale
          : (boxSize.width / scale) * imgRatio;
      const res: FrameLayerProps['image'] = {
        boxSize: {
          width: w,
          height: h,
        },
        position: {
          x: -(w - boxSize.width / scale) / 2,
          y: -(h - boxSize.height / scale) / 2,
        },
        rotate: 0,
        url: config.frame.defaultImage.url,
        thumb: config.frame.defaultImage.url,
      };
      return res;
    };
    if (!image && !color && !gradientBackground) {
      setImageData(getImageSetting());
    } else {
      setImageData(image);
    }
  }, [
    image,
    color,
    gradientBackground,
    config.frame.defaultImage.width,
    config.frame.defaultImage.height,
    config.frame.defaultImage.url,
    boxSize.width,
    boxSize.height,
    scale,
  ]);

  const openEditor = () => {
    if (image && selectedLayerIds.includes(id)) {
      actions.openImageEditor({
        boxSize,
        position,
        rotate,
        image: {
          boxSize: {
            width: image.boxSize.width * scale,
            height: image.boxSize.height * scale,
          },
          position: {
            x: image.position.x * scale,
            y: image.position.y * scale,
          },
          rotate: image.rotate || 0,
          url: image.url,
          flipVertical: image.flipVertical,
          flipHorizontal: image.flipHorizontal,
        },
      });
    }
  };

  useEffect(() => {
    if (
      !sidebar &&
      !isUploading &&
      image &&
      (imageSettings.brightness !== (image.brightness || null) ||
        imageSettings.contrast !== (image.contrast || null) ||
        imageSettings.saturation !== (image.saturation || null) ||
        imageSettings.hueRotate !== (image.hueRotate || null) ||
        imageSettings.grayscale !== (image.grayscale || null) ||
        imageSettings.blur !== (image.blur || null))
    ) {
      setIsUploading(true);
      const data = {
        brightness: image.brightness || null,
        contrast: image.contrast || null,
        saturation: image.saturation || null,
        hueRotate: image.hueRotate || null,
        grayscale: image.grayscale || null,
        blur: image.blur || null,
      };
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.toBlob(async (blob) => {
          const file = new File([blob as Blob], 'file.png', {
            type: 'image/png',
          });
          const { url, thumb } = await uploadImage(file);
          const img = new Image();
          img.src = url;
          img.crossOrigin = 'anonymous';
          img.onload = () => {
            const originalUrl = typeof image.original === 'string'
            ? image.original
            : URL.createObjectURL(image.original ?? image.url);
            // avoid flicker
            setImageSettings(data);
            setIsUploading(false);
            actions.setProp<ImageLayerProps>({
              image: {
                url,
                thumb,
                original: originalUrl,
              },
            });
          };
        }, 'image/png');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actions,
    image?.blur,
    image?.brightness,
    image?.contrast,
    image?.original,
    image?.saturation,
    image?.hueRotate,
    image?.grayscale,
    image?.url,
    imageSettings.blur,
    imageSettings.brightness,
    imageSettings.contrast,
    imageSettings.saturation,
    imageSettings.grayscale,
    imageSettings.hueRotate,
    isUploading,
    sidebar,
    uploadImage,
  ]);

  useAsync(async () => {
    if (
      canvasRef.current &&
      !isResizing &&
      !isDragging &&
      showCanvas &&
      imageEle &&
      image
    ) {
      await updateImageCanvas(
        canvasRef.current,
        image,
        imageEle,
        image.boxSize
      );
    }
  }, [isResizing, isDragging, image, imageEle]);
  return (
    <div
      css={{
        transformOrigin: '0 0',
      }}
      style={{
        width: boxSize.width / scale,
        height: boxSize.height / scale,
        transform: `scale(${scale})`,
        visibility:
          imageEditor &&
          imageEditor.pageIndex === pageIndex &&
          imageEditor.layerId === id
            ? 'hidden'
            : undefined,
      }}
      onDoubleClick={openEditor}
    >
      <FrameContent
        boxSize={boxSize}
        clipPath={clipPath}
        color={color}
        gradientBackground={gradientBackground}
        image={imageData}
        layerId={layerId}
        position={position}
        rotate={rotate}
        scale={scale}
        {...props}
      >
        {image && (
          <>
            <img
              alt={typeof image.url === 'string' ? image.url : URL.createObjectURL(image.url)}
              css={{
                width: '100%',
                height: '100%',
                objectFit: 'fill',
                position: 'absolute',
                pointerEvents: 'none',
                visibility: showCanvas ? 'hidden' : 'visible',
              }}
              src={typeof image.url === 'string' ? image.url : URL.createObjectURL(image.url)}
            />

            <div
              css={{
                position: 'absolute',
                inset: 0,
                visibility: !showCanvas || !image ? 'hidden' : 'visible',
              }}
            >
              <div
                css={{ width: '100%', height: '100%', pointerEvents: 'auto' }}
              >
                <canvas ref={canvasRef} />
              </div>
            </div>
          </>
        )}
      </FrameContent>
    </div>
  );
};

FrameLayer.info = {
  name: 'Frame',
  type: 'Frame',
};
export default FrameLayer;
