import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const FormatIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 18 18'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 6V2H12V0H18V6H16ZM0 18V12H2V16H6V18H0ZM0 10V8H2V10H0ZM0 6V4H2V6H0ZM0 2V0H2V2H0ZM4 2V0H6V2H4ZM8 18V16H10V18H8ZM8 2V0H10V2H8ZM12 18V16H14V18H12ZM16 18V16H18V18H16ZM16 14V12H18V14H16ZM16 10V8H18V10H16Z'
        fill='#676767'
      />
    </svg>
  )
}

export default FormatIcon
