import { Icon } from '@lidojs/design-editor'
import { CarouselModel, CarouselType } from '../../../types/carousel'

export const carouselModelConfig: Partial<Record<CarouselType, CarouselModel>> = {
  carousel1: {
    icon: <Icon name='CarouselModel1Icon' />,
    id:   'carousel1',
    size: {
      height: 265,
      width:  290
    },
    title: 'Modelo 1'
  },
  carousel2: {
    icon: <Icon name='CarouselModel2Icon' />,
    id:   'carousel2',
    size: {
      height: 104,
      width:  305
    },
    title: 'Modelo 2'
  },
  carousel3: {
    icon: <Icon name='CarouselModel3Icon' />,
    id:   'carousel3',
    size: {
      height: 220,
      width:  400
    },
    title: 'Modelo 3'
  },
  carousel4: {
    icon: <Icon name='CarouselModel4Icon' />,
    id:   'carousel4',
    size: {
      height: 346,
      width:  304
    },
    title: 'Modelo 4'
  },
  carousel5: {
    icon: <Icon name='CarouselModel5Icon' />,
    id:   'carousel5',
    size: {
      height: 99,
      width:  375
    },
    title: 'Modelo 5'
  },
  carousel6: {
    icon: <Icon name='CarouselModel6Icon' />,
    id:   'carousel6',
    size: {
      height: 160,
      width:  263
    },
    title: 'Modelo 6'
  },
  carousel7: {
    icon: <Icon name='CarouselModel7Icon' />,
    id:   'carousel7',
    size: {
      height: 104,
      width:  305
    },
    title: 'Modelo 7'
  },
  carousel8: {
    icon: <Icon name='CarouselModel8Icon' />,
    id:   'carousel8',
    size: {
      height: 291,
      width:  338
    },
    title: 'Modelo 8'
  }
}

export const carouselModelList = Object.values(carouselModelConfig)
