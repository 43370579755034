import VideoIcon from '@duyank/icons/regular/Video';
import { ObjectFit, VideoContentProps } from '@lidojs/design-layers';
import { Checkbox, Select, SelectItem } from '@nextui-org/react';
import React, { FC, Fragment, useRef, useState } from 'react';
import Popover from '../common/popover/Popover';

import { useEditor, useSelectedLayers } from '../hooks';
import SettingButton from './SettingButton';

const VideoSettings: FC = () => {
  const videoButtonref = useRef<HTMLDivElement>(null);
  const [openVideoSetting, setOpenVideoSetting] = useState(false);
  const { selectedLayers } = useSelectedLayers();
  const { actions, activePage } = useEditor((state) => ({
    activePage: state.activePage,
  }));

  const selectedLayer = selectedLayers[0];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentVideo = selectedLayer?.data?.props?.video;

  const onChange = (
    name: keyof VideoContentProps['video'],
    value: boolean | string
  ) => {
    actions.setProp<VideoContentProps>(activePage, selectedLayers[0].id, {
      video: {
        ...currentVideo,
        [name]: value,
      },
    });
  };

  const ObjectFitOptions = Object.entries(ObjectFit).map(([label, value]) => ({
    label,
    value,
  }));

  return (
    <Fragment>
      <SettingButton
        ref={videoButtonref}
        css={{ fontSize: 20 }}
        onClick={() => setOpenVideoSetting(true)}
      >
        <span>
          <VideoIcon />
        </span>
      </SettingButton>
      <Popover
        anchorEl={videoButtonref.current}
        offsets={{
          'bottom-end': { x: 0, y: 8 },
        }}
        open={openVideoSetting}
        placement={'bottom-end'}
        onClose={() => setOpenVideoSetting(false)}
      >
        <div
          css={{
            padding: 16,
            width: 215,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <div
            css={{
              width: '100%',
              gap: 12,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <div>
              <Checkbox
                color="default"
                isSelected={currentVideo?.autoPlay}
                onValueChange={(value) => onChange('autoPlay', value)}
              >
                Autoplay
              </Checkbox>
            </div>
            <div>
              <Checkbox
                color="default"
                isSelected={currentVideo?.mute}
                onValueChange={(value) => onChange('mute', value)}
              >
                Mute
              </Checkbox>
            </div>
            <div>
              <Checkbox
                color="default"
                isSelected={currentVideo?.controls}
                onValueChange={(value) => onChange('controls', value)}
              >
                Controls
              </Checkbox>
            </div>
            <div>
              <Checkbox
                color="default"
                isSelected={currentVideo?.loop}
                onValueChange={(value) => onChange('loop', value)}
              >
                Loop
              </Checkbox>
            </div>
          </div>
          <div>
            <label className="mb-1 inline-block text-[#676767]">
              Object fit
            </label>
            <Select
              className="max-w-xs text-black"
              color="primary"
              defaultSelectedKeys={[currentVideo.objectFit || 'contain']}
              items={ObjectFitOptions}
              name="objectFit"
              value={(currentVideo.objectFit || 'contain')}
              variant="bordered"
              onChange={(e) => onChange('objectFit', e.target.value)}
            >
              {(item) => <SelectItem key={item.value}>{item.label}</SelectItem>}
            </Select>
          </div>
        </div>
      </Popover>
    </Fragment>
  );
};

export default VideoSettings;
