export const VideoboxCarousel = () => {
  return (
    <svg
      height={'auto'}
      id="Capa_1"
      viewBox="0 0 469 887"
      width={'100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <rect
            fill="#e5e5e5"
            height="697.66"
            rx="33.8"
            ry="33.8"
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            fill="#fff"
            stroke="#ccc"
            strokeMiterlimit="10"
          />
        </g>
        <g>
          <rect
            fill="#fff"
            height="31.23"
            rx="7"
            ry="7"
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle cx="269.8" cy="132.79" fill="#e5e5e5" r="4.13" />
        </g>
      </g>
      <g>
        <rect fill="gray" height="147.2" width="136" x="238" y="569" />
        <rect fill="gray" height="147.2" width="135.63" x="95.37" y="569" />
        <rect fill="gray" height="151" width="278.63" x="95.37" y="412" />
        <rect fill="#4d4d4d" height="195.52" width="279" x="95" y="210.56" />
        <g>
          <polygon
            fill="#fff"
            points="230.99 295.56 230.99 322.9 244.9 308.99 230.99 295.56"
          />
          <circle
            cx="234.68"
            cy="308.41"
            fill="none"
            r="27.37"
            stroke="#fff"
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};
