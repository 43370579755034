export function getScale(width: number, height: number, orientation?: 'landscape' | 'portrait') {
  const cases = [
      { width: 1640, height: 924, scale: 0.74, landscapeScale: 0.48, portraitScale: 0.2 },
      { width: 1080, height: 1920, scale: 0.33, landscapeScale: 0.28, portraitScale: 0.28 },
      { width: 50, height: 50, scale: 5.0, landscapeScale: 5.0, portraitScale: 5.0 },
      { width: 200, height: 200, scale: 2.4, landscapeScale: 2.37, portraitScale: 1.5 },
      { width: 250, height: 250, scale: 2.1, landscapeScale: 1.66, portraitScale: 1.15 },
      { width: 300, height: 1050, scale: 0.61, landscapeScale: 0.53, portraitScale: 0.56 },
      { width: 120, height: 600, scale: 1.0, landscapeScale: 0.97, portraitScale: 0.97 },
      { width: 160, height: 600, scale: 1.0, landscapeScale: 0.89, portraitScale: 0.97 },
      { width: 320, height: 480, scale: 1.17, landscapeScale: 1.04, portraitScale: 0.89 },
      { width: 970, height: 250, scale: 0.99, landscapeScale: 1.02, portraitScale: 0.30 },
      { width: 300, height: 250, scale: 1.45, landscapeScale: 1.78, portraitScale: 0.94 },
      { width: 300, height: 50, scale: 3.55, landscapeScale: 3.32, portraitScale: 0.97 },
      { width: 320, height: 50, scale: 2.65, landscapeScale: 2.52, portraitScale: 0.89 },
      { width: 728, height: 90, scale: 1.33, landscapeScale: 1.25, portraitScale: 0.38 },
      { width: 970, height: 90, scale: 1.22, landscapeScale: 1.00, portraitScale: 0.3 },
      { width: 120, height: 20, scale: 4.57, landscapeScale: 5.0, portraitScale: 2.42 },
      { width: 168, height: 28, scale: 4.87, landscapeScale: 5.0, portraitScale: 1.73 },
      { width: 216, height: 36, scale: 4.16, landscapeScale: 3.88, portraitScale: 1.33 },
      { width: 480, height: 320, scale: 1.2, landscapeScale: 1.45, portraitScale: 0.61 }
  ];

  const result = cases.find(c => c.width === width && c.height === height);

  if (result) {
    if (!orientation) {
      return result.scale;
    }
    return orientation === 'landscape' ? result.landscapeScale : result.portraitScale;
  } else {
    return 1.0;
  }
}
