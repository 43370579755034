import { LayerComponentProps, SerializedLayer } from '@lidojs/design-core';

export const generateStyles = (
  props: LayerComponentProps,
  zPosition: number,
  container: SerializedLayer,
  groupContainer?: SerializedLayer
): string => {
  const { boxSize, position, rotate } = props;
  const boxSizeContainer = groupContainer
    ? (groupContainer.props as LayerComponentProps).boxSize
    : (container.props as LayerComponentProps).boxSize;

  const widthPercentage =
    boxSize.width && boxSizeContainer.width
      ? (boxSize.width / boxSizeContainer.width) * 100
      : 100;
  const heightPercentage =
    boxSize.height && boxSizeContainer.height
      ? (boxSize.height / boxSizeContainer.height) * 100
      : 100;

  const leftPercentage =
    position?.x && boxSizeContainer?.width
      ? (position.x / boxSizeContainer.width) * 100
      : 0;
  const topPercentage =
    position?.y && boxSizeContainer?.height
      ? (position.y / boxSizeContainer.height) * 100
      : 0;

  const styles = `
  width: ${widthPercentage}%;
  height: ${heightPercentage}%;
  z-index: ${zPosition};
  ${
    position && boxSizeContainer?.width && boxSizeContainer?.height
      ? `position: absolute; left: ${leftPercentage}%; top: ${topPercentage}%;`
      : ''
  }
  ${rotate ? 'transform: ' : ''}
  ${rotate ? `rotate(${rotate}deg)` : ''}
  ${rotate ? ';' : ''}
`.trim();
  return styles;
};
