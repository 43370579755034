
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const PhoneIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 24 23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.6 23C19.8222 23 17.0778 22.4197 14.3667 21.259C11.6556 20.0984 9.18889 18.4532 6.96667 16.3236C4.74444 14.194 3.02778 11.8301 1.81667 9.23195C0.605556 6.6338 0 4.0037 0 1.34167C0 0.958333 0.133333 0.638889 0.4 0.383333C0.666667 0.127778 1 0 1.4 0H6.8C7.11111 0 7.38889 0.101157 7.63333 0.303472C7.87778 0.505787 8.02222 0.74537 8.06667 1.02222L8.93333 5.49444C8.97778 5.83519 8.96667 6.12269 8.9 6.35695C8.83333 6.5912 8.71111 6.79352 8.53333 6.96389L5.3 10.0944C5.74444 10.8824 6.27222 11.6438 6.88333 12.3785C7.49444 13.1132 8.16667 13.8213 8.9 14.5028C9.58889 15.163 10.3111 15.7752 11.0667 16.3396C11.8222 16.9039 12.6222 17.4204 13.4667 17.8889L16.6 14.8861C16.8 14.6944 17.0611 14.5507 17.3833 14.4549C17.7056 14.359 18.0222 14.3324 18.3333 14.375L22.9333 15.2694C23.2444 15.3546 23.5 15.509 23.7 15.7326C23.9 15.9563 24 16.2065 24 16.4833V21.6583C24 22.0417 23.8667 22.3611 23.6 22.6167C23.3333 22.8722 23 23 22.6 23ZM4.03333 7.66667L6.23333 5.55833L5.66667 2.55556H2.7C2.81111 3.4287 2.96667 4.2912 3.16667 5.14306C3.36667 5.99491 3.65556 6.83611 4.03333 7.66667ZM15.9667 19.1028C16.8333 19.4648 17.7167 19.7523 18.6167 19.9653C19.5167 20.1782 20.4222 20.3167 21.3333 20.3806V17.5694L18.2 16.9625L15.9667 19.1028Z'
        fill='#297FB8'
      />
    </svg>
  )
}

export default PhoneIcon
