import CheckIcon from '@duyank/icons/regular/Check';
import XIcon from '@duyank/icons/regular/X';
import { FontData } from '@lidojs/design-core';
import { fonts } from '@lidojs/design-layers';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
} from 'react';
import { useEditor } from '../../hooks';
import { useUsedFont } from '../../layers/hooks/useUsedFont';
import FontStyle from './FontStyle';
import Sidebar, { SidebarProps } from './Sidebar';

interface FontSidebarProps extends SidebarProps {
  selected: FontData[];
  onChangeFontFamily: (font: FontData) => void;
}

const FontSidebar: ForwardRefRenderFunction<
  HTMLDivElement,
  FontSidebarProps
> = ({ selected, onChangeFontFamily, ...props }, ref) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { usedFonts } = useUsedFont();
  const { actions, fontList } = useEditor((state) => ({
    fontList: state.fontList,
  }));

  useEffect(() => {
    actions.setFontList(fonts);
  },[]);

  return (
    <Sidebar ref={ref} {...props}>
      <FontStyle />
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            height: 48,
            borderBottom: '1px solid rgba(57,76,96,.15)',
            padding: '0 20px',
          }}
        >
          <p
            css={{
              lineHeight: '48px',
              fontWeight: 600,
              color: '#181C32',
              flexGrow: 1,
            }}
          >
            Font
          </p>
          <div
            css={{
              fontSize: 20,
              flexShrink: 0,
              width: 32,
              height: 32,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              actions.setSidebar();
            }}
          >
            <XIcon />
          </div>
        </div>
        <div ref={scrollRef} css={{ flexGrow: 1, overflowY: 'auto' }}>
          <div css={{ padding: '16px 20px', fontWeight: 700 }}>
            Document fonts
          </div>
          {usedFonts.map((font) => (
            <div
              key={font.name}
              css={{
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                padding: '0 24px',
                ':hover': {
                  background: '#F9F9F9',
                },
              }}
              onClick={() => onChangeFontFamily(font)}
            >
              <span css={{ fontFamily: font.name }}>{font.name}</span>
              {selected.map((s) => s.name).includes(font.name) && (
                <span>
                  <CheckIcon />
                </span>
              )}
            </div>
          ))}
          <div css={{ borderTop: '1px solid rgba(217, 219, 228, 0.6)' }}>
            <div css={{ padding: '16px 20px', fontWeight: 700 }}>Fonts</div>
            {fontList.map((font) => (
              <div
                key={font.name}
                css={{
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  padding: '0 24px',
                  ':hover': {
                    background: '#F9F9F9',
                  },
                }}
                onClick={() => onChangeFontFamily(font)}
              >
                <span css={{ fontFamily: font.name }}>{font.name}</span>
                {selected.map((s) => s.name).includes(font.name) && (
                  <span>
                    <CheckIcon />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default forwardRef<HTMLDivElement, FontSidebarProps>(FontSidebar);
