import { FontData } from '@lidojs/design-core';

export const fonts: FontData[] = [
  {
    name: 'A Bee Zee',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
        ],
        style: 'Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Aldrich',
    fonts: [
      {
        urls: ['https://fonts.googleapis.com/css2?family=Aldrich&display=swap'],
      },
    ],
  },
  {
    name: 'Aleo',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Cabin',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Chewy',
    fonts: [
      {
        urls: ['https://fonts.googleapis.com/css2?family=Chewy&display=swap'],
      },
    ],
  },
  {
    name: 'Dosis',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Inter',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Joti One',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Joti+One&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Lato',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Lilita One',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lilita+One&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Lobster',
    fonts: [
      {
        urls: ['https://fonts.googleapis.com/css2?family=Lobster&display=swap'],
      },
    ],
  },
  {
    name: 'Lora',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Montserrat',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
      {
        style: 'Bold',
        urls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Nunito',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Poppins',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Raleway',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Roboto',
    fonts: [
      {
        style: 'Bold',
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
      {
        style: 'Bold_Italic',
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
      {
        style: 'Bold',
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
      {
        style: 'Italic',
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Roboto Condensed',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
        style: 'Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Roboto Mono',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Rubik',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap',
        ],
      },
    ],
  },
  {
    name: 'Ubuntu',
    fonts: [
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        ],
        style: 'Bold_Italic',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        ],
        style: 'Bold',
      },
      {
        urls: [
          'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
        ],
      },
    ],
  },
];

