
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const VideoIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 16 16'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.93333 11.7556L11.7333 8L5.93333 4.24444V11.7556ZM1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333ZM1.33333 14.6667H14.6667V1.33333H1.33333V14.6667Z'
        fill='#676767'
      />
    </svg>
  )
}

export default VideoIcon
