import { redirectURLDefault } from "@lidojs/design-editor";

export const data = [
  {
    locked: false,
    layers: {
      ROOT: {
        type: { resolvedName: 'RootLayer' },
        props: {
          boxSize: { width: 1080, height: 1920 },
          position: { x: 0, y: 0 },
          rotate: 0,
          color: 'rgb(255, 255, 255)',
          image: null,
          hyperlink: {
            hasHyperlink: false,
            typeLink: 'a-href',
            linkRedirect: redirectURLDefault
          },
          hasMobileFrame: false,
          mobileOrientation: 'portrait',
        },
        locked: false,
        child: [
        ],
        parent: null,
      }
    },
  },
];
