import { Color } from '@lidojs/design-utils';

export const applyColorsToSvg = (svgContent: HTMLElement, colors: string[]): HTMLElement => {
  svgContent.style.fill = '';
  svgContent.style.stroke = '';
  const originalColorList: string[] = [];
  const paths = svgContent.querySelectorAll(
    'path, circle, ellipse,line, rect, polygon,polyline, text'
  ) as unknown as NodeListOf<HTMLElement>;
  for (let j = 0; j < paths.length; j++) {
    const style = paths[j].getAttribute('style');
    let stroke = paths[j].getAttribute('stroke') || 'none';
    let fill = paths[j].getAttribute('fill') || '#000000';
    const styleObj: Record<string, string> = {};
    if (style) {
      const styleList = style.split(';').filter((s) => !!s);
      styleList.forEach((attr) => {
        const [key, value] = attr.split(':');
        styleObj[key.trim()] = value.trim();
      });
    }
    if (styleObj.stroke) {
      stroke = styleObj.stroke;
    }
    if (styleObj.fill) {
      fill = styleObj.fill;
    }
    if (
      stroke &&
      !['none', 'currentcolor'].includes(stroke.toLowerCase()) &&
      !/url\((.*?)\)/.test(stroke)
    ) {
      if (!originalColorList.includes(new Color(stroke).toRgbString())) {
        originalColorList.push(new Color(stroke).toRgbString());
      }
      paths[j].style.fill = '';
      paths[j].style.stroke = '';
      paths[j].setAttribute(
        'stroke',
        colors[originalColorList.indexOf(new Color(stroke).toRgbString())] ||
          '#000000'
      );
    } else if (
      fill &&
      !['none', 'currentcolor'].includes(fill.toLowerCase()) &&
      !/url\((.*?)\)/.test(fill)
    ) {
      if (!originalColorList.includes(new Color(fill).toRgbString())) {
        originalColorList.push(new Color(fill).toRgbString());
      }
      paths[j].style.fill = '';
      paths[j].style.stroke = '';
      paths[j].setAttribute(
        'fill',
        colors[originalColorList.indexOf(new Color(fill).toRgbString())] ||
          '#000000'
      );
    }
  }
  return svgContent;
};
