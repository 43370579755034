
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const GridIcon: FunctionComponent<IconProps> = ({
  size,
  className,
  color = '#676767',
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 16 16'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333ZM1.33333 14.6667H4.88889V11.1111H1.33333V14.6667ZM6.22222 14.6667H9.77778V11.1111H6.22222V14.6667ZM11.1111 14.6667H14.6667V11.1111H11.1111V14.6667ZM1.33333 9.77778H4.88889V6.22222H1.33333V9.77778ZM6.22222 9.77778H9.77778V6.22222H6.22222V9.77778ZM11.1111 9.77778H14.6667V6.22222H11.1111V9.77778ZM1.33333 4.88889H4.88889V1.33333H1.33333V4.88889ZM6.22222 4.88889H9.77778V1.33333H6.22222V4.88889ZM11.1111 4.88889H14.6667V1.33333H11.1111V4.88889Z'
        fill={color}
      />
    </svg>
  )
}

export default GridIcon
