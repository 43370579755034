import { Icon } from '../common';
import { useEditor } from '../hooks';
import SettingButton from './SettingButton';

export const AnimationSettings = () => {
  const { actions } = useEditor((state) => ({}));

  return (
    <SettingButton onClick={() => actions.setSidebar('ANIMATION_SETTINGS')}>
      <span css={{ padding: '0 4px' }}>
        <Icon name="AnimationIcon" />
      </span>
    </SettingButton>
  );
};
