export const mobileFrameStyles = `
.mobile-preview {
  height: 100vh;
  width: auto;
  aspect-ratio: 402/822;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(https://creatives.wortise.com/1726030755970.png);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13vh 2.6vh 10vh 2.6vh;
}`

export const videoControlStyles = `
.controls {
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  gap: 10px;
  z-index: 2;
  justify-content: space-between;
  width: 100%;
  padding: 0 3%;
  left: 0;
  bottom: 3%;
}

.control-button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 11%;
  aspect-ratio: 1 / 1;
  background: black;
  padding: 2.7%;
}
`
