import { CarouselEditor } from '../../../common/carousel-editor/CarouselEditor';
import { useEditor } from '../../../hooks/useEditor';

export const CarouselPremiumSettings = () => {
  const { activePage, pages } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
  }));
  const currentPage = pages[activePage].layers;
  const { ROOT, ...components } = currentPage;

  const carouselComponents = Object.values(components).filter(
    (component) => component.data.type === 'Carousel'
  );

  if (!carouselComponents.length) return null;
  return carouselComponents.map((carousel, index) => (
    <div>
      <p
        css={{
          textAlign: 'center',
          fontWeight: 'bolder',
          marginTop: 24,
        }}
      >
        Carousel {index + 1}
      </p>
      <CarouselEditor key={carousel.id} carouselId={carousel.id} />
    </div>
  ));
};
