export const scriptAdjustFontSize = (widthContainer: number) => `
<script>
  function adjustFontSizes() {
      const container = document.querySelector('[data-id="ROOT"]');
      const containerWidth = container.offsetWidth;
      const elements = container.querySelectorAll('[data-type="TextLayer"] p');

      elements.forEach(element => {
          if (!element.dataset.originalFontSize) {
              element.dataset.originalFontSize = window.getComputedStyle(element).fontSize;
          }

          const originalFontSize = parseFloat(element.dataset.originalFontSize);
          const originalContainerWidth = ${widthContainer};

          const newFontSize = (containerWidth / originalContainerWidth) * originalFontSize;
          element.style.fontSize = newFontSize+"px";
      });
  }

  window.addEventListener("load", adjustFontSizes);
  window.addEventListener("resize", adjustFontSizes);
</script>
`

export const scriptChangeSizeFrame = `
<script>
  (() => {
    const containers = document.querySelectorAll('.frame-layer-container');

    const updateScale = (container) => {
      const frameSizeWidth = parseFloat(container.getAttribute('data-frame-width'));
      const transformDiv = container.querySelector('.frame-layer-transform');
      const calculatedScale = container.clientWidth / frameSizeWidth;
      transformDiv.style.transform = \`scale(\${calculatedScale})\`;
    };

    containers.forEach((container) => {
      updateScale(container);

      const resizeObserver = new ResizeObserver(() => {
        updateScale(container);
      });
      resizeObserver.observe(container);
    });
  })();
</script>
`;

