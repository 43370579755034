import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const DeleteIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 10 / 12)}
      style={style}
      viewBox='0 0 10 12'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.52941 9.6C3.20588 9.6 2.92892 9.4825 2.69853 9.2475C2.46814 9.0125 2.35294 8.73 2.35294 8.4V1.2C2.35294 0.87 2.46814 0.5875 2.69853 0.3525C2.92892 0.1175 3.20588 0 3.52941 0H8.82353C9.14706 0 9.42402 0.1175 9.65441 0.3525C9.8848 0.5875 10 0.87 10 1.2V8.4C10 8.73 9.8848 9.0125 9.65441 9.2475C9.42402 9.4825 9.14706 9.6 8.82353 9.6H3.52941ZM3.52941 8.4H8.82353V1.2H3.52941V8.4ZM1.17647 12C0.852941 12 0.57598 11.8825 0.345588 11.6475C0.115196 11.4125 0 11.13 0 10.8V2.4H1.17647V10.8H7.64706V12H1.17647Z'
        fill='#676767'
      />
    </svg>
  )
}

export default DeleteIcon
