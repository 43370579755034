import { LayerComponentProps } from "@lidojs/design-core";
import { Dropzone } from '../../../common';
import { ImageLayerProps } from "../../../layers";
import { Layer } from "../../../types";

const acceptedImageFormats = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
];

export const ImageRenderer = ({
  component,
  onChangeMedia,
  getObjectUrl,
  index
}: {
  component: Layer<LayerComponentProps>;
  onChangeMedia: (id: string, file: File, type: 'image') => void;
  getObjectUrl: (file: File, type: 'image') => string;
  index: number;
}) => {
  return (
    <div key={component.id} css={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        <p css={{ textAlign: 'center', marginBottom: 2, fontWeight: 'bolder' }}>Image {index}</p>
        <p css={{ textAlign: 'center' }}>Suggested Dimensions</p>
        <p css={{ textAlign: 'center', marginBottom: 6 }}>
          {(component.data.props as ImageLayerProps).image.boxSize.width} x{' '}
          {(component.data.props as ImageLayerProps).image.boxSize.height.toFixed(0)}
        </p>
        <Dropzone
          key={component.id}
          acceptedFormats={acceptedImageFormats}
          enableEdit={true}
          preview={
            typeof (component.data.props as ImageLayerProps).image.url ===
            'string'
              ? (component.data.props as ImageLayerProps).image.url as string
              : getObjectUrl(
                  (component.data.props as ImageLayerProps).image
                    .url as File,
                  'image'
                )
          }
          onChange={(file) => onChangeMedia(component.id, file, 'image')}
        />
      </div>
    </div>
  );
};

