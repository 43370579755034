
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const TemplateIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 18 18'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 7.5H1.5C1.1 7.5 0.75 7.35 0.45 7.05C0.15 6.75 0 6.4 0 6V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H6C6.4 0 6.75 0.15 7.05 0.45C7.35 0.75 7.5 1.1 7.5 1.5V6C7.5 6.4 7.35 6.75 7.05 7.05C6.75 7.35 6.4 7.5 6 7.5ZM1.5 6H6V1.5H1.5V6ZM6 18H1.5C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V12C0 11.6 0.15 11.25 0.45 10.95C0.75 10.65 1.1 10.5 1.5 10.5H6C6.4 10.5 6.75 10.65 7.05 10.95C7.35 11.25 7.5 11.6 7.5 12V16.5C7.5 16.9 7.35 17.25 7.05 17.55C6.75 17.85 6.4 18 6 18ZM1.5 16.5H6V12H1.5V16.5ZM16.5 7.5H12C11.6 7.5 11.25 7.35 10.95 7.05C10.65 6.75 10.5 6.4 10.5 6V1.5C10.5 1.1 10.65 0.75 10.95 0.45C11.25 0.15 11.6 0 12 0H16.5C16.9 0 17.25 0.15 17.55 0.45C17.85 0.75 18 1.1 18 1.5V6C18 6.4 17.85 6.75 17.55 7.05C17.25 7.35 16.9 7.5 16.5 7.5ZM12 6H16.5V1.5H12V6ZM16.5 18H12C11.6 18 11.25 17.85 10.95 17.55C10.65 17.25 10.5 16.9 10.5 16.5V12C10.5 11.6 10.65 11.25 10.95 10.95C11.25 10.65 11.6 10.5 12 10.5H16.5C16.9 10.5 17.25 10.65 17.55 10.95C17.85 11.25 18 11.6 18 12V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18ZM12 16.5H16.5V12H12V16.5Z'
        fill='#676767'
      />
    </svg>
  )
}

export default TemplateIcon
