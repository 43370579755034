import { css, Global } from '@emotion/react';
import { FontData } from '@lidojs/design-core';
import React, { FC, useMemo } from 'react';

export interface FontStyleProps {
  font: FontData;
}

const FontStyle: FC<FontStyleProps> = ({ font }) => {
  const fontFaceString = useMemo(() => {
    const fontFaceCss: string[] = [];
    fontFaceCss.push(`
        @import url('${font.fonts[0].urls[0]}');
    `);
    return fontFaceCss.join('\n');
  }, [font]);

  return (
    <Global
      styles={css`
        ${fontFaceString}
      `}
    />
  );
};

export default React.memo(FontStyle);
