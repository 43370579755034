import { SerializedLayer } from '@lidojs/design-core';
import { ImageContentProps, VideoContentProps } from '@lidojs/design-layers';
import { generateCarouselHTML } from './generateCarouselHTML';
import { generatecarouselJS } from './generateCarouselJs';
import { generateFilters } from './generateFilters';
import { generateFrameHTML } from './generateFrameHTML';
import { JSControlsVideo, JSListenerVideo, controlsVideo } from './htmlComponents';

export const generateChildComponent = (
  component: SerializedLayer,
  id: string
) => {
  const { type, props } = component;
  switch (type.resolvedName) {
    case 'ImageLayer': {
      const imageProps = props as unknown as ImageContentProps;

      const filterString = generateFilters(imageProps.image);
      return `<img src="${imageProps.image.url}" alt="" style="filter: ${filterString};"
    width="100%">`;
    }
    case 'VideoLayer': {
      const videoProps = props as unknown as VideoContentProps;
      const { autoPlay, mute, loop, controls, objectFit } = videoProps.video;
      return `<video style="object-fit: ${objectFit}"
                id="${id}" width="100%" height="100%"
                ${loop ? "loop" : ""} ${autoPlay ? "autoplay" : ""} ${mute ? "muted" : ""}>
                  <source src="${videoProps.video.url}" type="video/mp4">
                </video>
                ${controls ? `${controlsVideo(id, autoPlay, mute)} ${JSControlsVideo(id)}` : ""}
                ${JSListenerVideo}
                `;
    }
    case 'TextLayer': {
      const { text } = props;
      return text;
    }
    case 'SvgLayer': {
      const imageSVG = props.image;
      return imageSVG;
    }
    case 'CarouselLayer': {
      const carouselHTML = generateCarouselHTML(props, id);
      const carouselJS = generatecarouselJS(props, id);
      return carouselHTML + carouselJS;
    }
    case 'FrameLayer': {
      return generateFrameHTML(props);
    }
    default:
      return '';
  }
};
