import { CSSProperties } from "react";

type Orientation = 'portrait' | 'landscape';

interface StyleParams {
  mobileOrientation: Orientation;
  width: number;
  height: number;
  widthContainerScale: number;
  heightContainerScale: number;
}

interface PaddingSettings {
  paddingTop: string;
  paddingBottom: string;
  paddingLeft?: string;
}

const settings = {
  portrait: {
    backgroundImage: "https://creatives.wortise.com/1726030755970.png",
    aspectRatio: 402 / 822,
    widthThreshold: 0.54,
    widthScaleFactor: 0.108,
    defaultPadding: { paddingTop: "18%", paddingBottom: "5%" } as PaddingSettings
  },
  landscape: {
    backgroundImage: "https://creatives.wortise.com/1726426293342.png",
    aspectRatio: 822 / 402,
    widthThreshold: 3.5,
    widthScaleFactor: 0.16,
    defaultPadding: { paddingTop: "2.5%", paddingBottom: "2.5%", paddingLeft: "6%" } as PaddingSettings
  }
};

export const generateStylesContainer = ({
  mobileOrientation,
  width,
  height,
  widthContainerScale,
  heightContainerScale
}: StyleParams): CSSProperties => {
  const aspectRatio = width / height;
  const orientationSettings = settings[mobileOrientation];

  const styles: CSSProperties = {
    backgroundImage: `url(${orientationSettings.backgroundImage})`,
    aspectRatio: orientationSettings.aspectRatio,
    paddingTop: orientationSettings.defaultPadding.paddingTop,
    paddingBottom: orientationSettings.defaultPadding.paddingBottom
  };

  if (aspectRatio > orientationSettings.widthThreshold) {
    styles.width = widthContainerScale * (1 + orientationSettings.widthScaleFactor);

    if (mobileOrientation === 'landscape' && orientationSettings.defaultPadding.paddingLeft) {
      styles.paddingLeft = orientationSettings.defaultPadding.paddingLeft;
    }
  } else {
    styles.height = heightContainerScale * (1 + 0.13);
  }

  return styles;
};
