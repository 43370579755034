import 'swiper/css';
import { FunctionComponent } from 'react';
import { ObjectFit } from '../../enum';

export interface ICarouselItem{
  id: string;
  image: string | File | undefined;
  hasHyperlink?: boolean;
  typeLink?: 'a-href' | 'click-tag';
  linkRedirect?: string;
}

export interface ICarouselItemProps {
  item: ICarouselItem;
  objectFit?: ObjectFit
}

export const CarouselItem: FunctionComponent<ICarouselItemProps> = ({ item, objectFit }) => {

  const image = typeof item.image==='string' ? item.image : URL.createObjectURL(item.image);

  return (
      <img alt="" css={{
        borderRadius: '10px',
        height: '100%',
        objectFit,
      }} height="100%" src={image} width="100%"/>
  );
};
