import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const AlignTextIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='19'
      style={style}
      viewBox='0 0 19 19'
      width='19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 19V17.4167H19V19H0ZM4.22222 14.6458V13.0625H14.7778V14.6458H4.22222ZM0 10.2917V8.70833H19V10.2917H0ZM4.22222 5.9375V4.35417H14.7778V5.9375H4.22222ZM0 1.58333V0H19V1.58333H0Z'
        fill='#676767'
      />
    </svg>
  )
}

export default AlignTextIcon
