
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const UploadIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 61 / 43)}
      style={style}
      viewBox='0 0 61 43'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.725 43C9.92013 43 6.68153 41.6675 4.00923 39.0025C1.33641 36.3381 0 33.0811 0 29.2316C0 25.5934 1.24338 22.4393 3.73015 19.7692C6.21742 17.0997 9.13856 15.6643 12.4936 15.4631C13.1778 10.9901 15.2208 7.29464 18.6225 4.37679C22.0243 1.45893 25.9835 0 30.5 0C35.5981 0 39.9225 1.78808 43.4732 5.36425C47.0244 8.9399 48.8 13.2947 48.8 18.4286V21.5H50.6773C53.5982 21.5947 56.0488 22.6746 58.0293 24.7396C60.0098 26.8051 61 29.3086 61 32.25C61 35.2626 59.9882 37.8072 57.9645 39.884C55.9408 41.9613 53.4335 43 50.4424 43H33.9023C32.4983 43 31.326 42.5265 30.3856 41.5795C29.4452 40.6324 28.975 39.452 28.975 38.0381V20.2123L22.57 26.627L20.4114 24.5123L30.5 14.3528L40.5886 24.5123L38.43 26.627L32.025 20.2123V38.0381C32.025 38.5111 32.2205 38.9444 32.6114 39.3381C33.0023 39.7317 33.4326 39.9286 33.9023 39.9286H50.325C52.46 39.9286 54.2646 39.1863 55.7388 37.7018C57.2129 36.2173 57.95 34.4 57.95 32.25C57.95 30.1 57.2129 28.2827 55.7388 26.7982C54.2646 25.3137 52.46 24.5714 50.325 24.5714H45.75V18.4286C45.75 14.1798 44.2631 10.558 41.2894 7.56339C38.3156 4.56875 34.7192 3.07143 30.5 3.07143C26.2808 3.07143 22.6844 4.56875 19.7106 7.56339C16.7369 10.558 15.25 14.1798 15.25 18.4286H13.6076C10.7767 18.4286 8.30922 19.478 6.20523 21.5768C4.10174 23.6756 3.05 26.2095 3.05 29.1786C3.05 32.1476 4.09208 34.6815 6.17625 36.7804C8.26042 38.8792 10.7767 39.9286 13.725 39.9286H21.35V43H13.725Z'
        fill='#959595'
      />
    </svg>
  )
}

export default UploadIcon

