import { extractJSProps } from "./extractJSProps";

export const htmlToJson = (doc: Document) => {
  const result = { layers: {} };
  const props = extractJSProps(doc);

  function traverse(element: Element) {
      const id = element.getAttribute('data-id');
      if (!id) return;

      const type = element.getAttribute('data-type') || '';
      const child = JSON.parse(element.getAttribute('data-child') || '[]');
      const parentAttr = element.getAttribute('data-parent');
      const parent = parentAttr === "null" ? null : parentAttr;

      const layer = {
          type: { resolvedName: type },
          props: props[id] || {},
          locked: element.getAttribute('data-locked') === 'true',
          child: child,
          parent
      };

      result.layers[id] = layer;

      child.forEach((childId: string) => {
          const childElement = doc.querySelector(`[data-id="${childId}"]`);
          if (childElement) {
              traverse(childElement);
          }
      });
  }

  const rootElements = doc.querySelectorAll('[data-id]');
  rootElements.forEach(rootElement => {
      traverse(rootElement);
  });

  return result;
};
