
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const ImgIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 17 17'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.41667 17C1.03889 17 0.708333 16.8583 0.425 16.575C0.141667 16.2917 0 15.9611 0 15.5833V1.41667C0 1.03889 0.141667 0.708333 0.425 0.425C0.708333 0.141667 1.03889 0 1.41667 0H15.5833C15.9611 0 16.2917 0.141667 16.575 0.425C16.8583 0.708333 17 1.03889 17 1.41667V15.5833C17 15.9611 16.8583 16.2917 16.575 16.575C16.2917 16.8583 15.9611 17 15.5833 17H1.41667ZM1.41667 15.5833H15.5833V1.41667H1.41667V15.5833ZM2.73889 13.2931H14.2847L10.8139 8.66528L7.69722 12.7028L5.50139 9.70417L2.73889 13.2931Z'
        fill='#676767'
      />
    </svg>
  )
}

export default ImgIcon
