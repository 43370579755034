import {
  BoxSize,
  Delta,
  getTransformStyle,
  LayerComponentProps,
} from '@lidojs/design-core';
import React, { FC, PropsWithChildren, useMemo } from 'react';

export interface ImageContentProps extends LayerComponentProps {
  image: {
    url: string | File;
    original?: string | null;
    position: Delta;
    rotate: number;
    boxSize: BoxSize;
    transparency?: number;
    flipHorizontal?: boolean;
    flipVertical?: boolean;
    brightness?: number | null;
    contrast?: number | null;
    grayscale?: number | null;
    saturation?: number | null;
    hueRotate?: number | null;
    blur?: number | null;
  };
}

export const ImageContent: FC<PropsWithChildren<ImageContentProps>> = ({
  image,
  boxSize,
  children,
}) => {
  const { flipVertical, flipHorizontal } = useMemo(() => image, [image]);
  const wrapperStyle = useMemo(
    () => ({
      transform:
        flipVertical || flipHorizontal
          ? `scale(${flipHorizontal ? -1 : 1}, ${flipVertical ? -1 : 1})`
          : undefined,
      width: boxSize.width,
      height: boxSize.height,
    }),
    [flipHorizontal, flipVertical, boxSize]
  );
  const imageUrl = typeof image.url === 'string' ? image.url : URL.createObjectURL(image.url);

  return (
    <div
      css={{
        overflow: 'hidden',
        pointerEvents: 'auto',
        width: boxSize.width,
        height: boxSize.height,
        ...wrapperStyle,
      }}
    >
      <div
        css={{
          width: image.boxSize.width,
          height: image.boxSize.height,
          transform: getTransformStyle({
            position: image.position,
            rotate: image.rotate,
          }),
          position: 'relative',
          userSelect: 'none',
          opacity: image.transparency,
        }}
      >
        {children ? (
          children
        ) : (
          <img
            alt={imageUrl}
            css={{
              objectFit: 'fill',
              width: '100%',
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
            }}
            src={imageUrl}
          />
        )}
      </div>
    </div>
  );
};
