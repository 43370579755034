import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const BoldIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='12'
      style={style}
      viewBox='0 0 11 12'
      width='11'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.710813 12V0.799999H6.18281C7.59081 0.799999 8.64681 1.06667 9.35081 1.6C10.0655 2.13333 10.4228 2.83733 10.4228 3.712C10.4228 4.29867 10.2788 4.81067 9.99081 5.248C9.70281 5.67467 9.30815 6.00533 8.80681 6.24C8.30548 6.47467 7.72948 6.592 7.07881 6.592L7.38281 5.936C8.08681 5.936 8.71081 6.05333 9.25481 6.288C9.79881 6.512 10.2201 6.848 10.5188 7.296C10.8281 7.744 10.9828 8.29333 10.9828 8.944C10.9828 9.904 10.6041 10.656 9.84681 11.2C9.08948 11.7333 7.97481 12 6.50281 12H0.710813ZM3.28681 10.048H6.31081C6.98281 10.048 7.48948 9.94133 7.83081 9.728C8.18281 9.504 8.35881 9.152 8.35881 8.672C8.35881 8.20267 8.18281 7.856 7.83081 7.632C7.48948 7.39733 6.98281 7.28 6.31081 7.28H3.09481V5.392H5.86281C6.49215 5.392 6.97215 5.28533 7.30281 5.072C7.64415 4.848 7.81481 4.512 7.81481 4.064C7.81481 3.62667 7.64415 3.30133 7.30281 3.088C6.97215 2.864 6.49215 2.752 5.86281 2.752H3.28681V10.048Z'
        fill='#676767'
      />
    </svg>
  )
}

export default BoldIcon
