import { useEditor } from '../hooks';
import CarouselSettingsSidebar from './sidebar/CarouselSettingsSidebar';

export const CarouselSettings = () => {
  const { sidebar } = useEditor((state) => ({
    sidebar: state.sidebar,
  }));

  return (
    sidebar === 'CAROUSEL_SETTINGS' && <CarouselSettingsSidebar open={true} />
  );
};
