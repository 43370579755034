
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const WallpaperIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 19 / 23)}
      style={style}
      viewBox='0 0 19 23'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.58333 23C1.16111 23 0.791667 22.8432 0.475 22.5295C0.158333 22.2159 0 21.85 0 21.4318V5.75C0 5.33182 0.158333 4.96591 0.475 4.65227C0.791667 4.33864 1.16111 4.18182 1.58333 4.18182H5.30417L9.53958 0L13.775 4.18182H17.4167C17.8389 4.18182 18.2083 4.33864 18.525 4.65227C18.8417 4.96591 19 5.33182 19 5.75V21.4318C19 21.85 18.8417 22.2159 18.525 22.5295C18.2083 22.8432 17.8389 23 17.4167 23H1.58333ZM1.58333 21.4318H17.4167V5.75H1.58333V21.4318ZM3.03472 18.8443H16.0181L12.1125 13.6432L8.62917 18.1909L6.175 14.8193L3.03472 18.8443ZM7.07222 4.18182H11.9806L9.52639 1.75114L7.07222 4.18182Z'
        fill='#676767'
      />
    </svg>
  )
}

export default WallpaperIcon
