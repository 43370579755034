
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const TwitterIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#D8D8D8'
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 40 41'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.25 40.4193H18.75C18.66 40.3993 18.58 40.3594 18.49 40.3594C16.48 40.2194 14.52 39.7894 12.66 38.9994C5.89 36.1194 1.77 31.0294 0.32 23.7394C0.17 22.9994 0.1 22.2394 0 21.4894C0 20.6494 0 19.8094 0 18.9594C0.02 18.8493 0.05 18.7294 0.07 18.6194C0.2 17.7094 0.28 16.7894 0.48 15.8994C2.86 4.80935 13.98 -2.17065 24.82 0.609352C35.23 3.26935 41.67 13.5194 39.62 24.1594C38.05 32.2994 31.54 38.7294 23.45 40.1194C22.72 40.2393 21.99 40.3294 21.26 40.4294L21.25 40.4193ZM14.84 26.2794C12.54 28.0194 10.02 28.7094 7.23 28.4894C7.26 28.5594 7.26 28.5894 7.27 28.5994C7.39 28.6794 7.51 28.7594 7.63 28.8294C10.63 30.5594 13.84 31.1694 17.26 30.7394C20.46 30.3294 23.27 29.0594 25.57 26.7694C28.75 23.6094 30.32 19.7394 30.3 15.2294C30.3 14.9994 30.37 14.8494 30.55 14.6994C30.97 14.3494 31.39 13.9894 31.77 13.5894C32.17 13.1594 32.52 12.6894 32.93 12.1994C31.95 12.4694 31.05 12.7194 30.15 12.9594C30.13 12.9194 30.11 12.8694 30.1 12.8294C31.09 12.1394 31.81 11.2394 32.17 10.1494C31.19 10.4994 30.21 10.8594 29.24 11.1994C29.03 11.2694 28.85 11.2694 28.65 11.0694C27.01 9.47935 24.61 9.12935 22.61 10.1494C20.61 11.1694 19.45 13.3394 19.71 15.5994C19.73 15.7794 19.76 15.9494 19.79 16.1794C15.34 15.8494 11.72 13.9894 8.85 10.5694C8.16 11.7994 7.97 13.0694 8.25 14.4094C8.54 15.7594 9.26 16.8294 10.36 17.6594C10.34 17.7094 10.31 17.7494 10.29 17.7994C9.57 17.5993 8.84 17.3894 8.13 17.1894C8.02 19.4894 9.54 21.4394 12.23 22.3994C12.14 22.4294 12.07 22.4794 12 22.4894C11.34 22.5294 10.68 22.5594 10.03 22.5894C10.29 24.2894 12.18 25.7694 14.86 26.2794H14.84Z'
        fill={color}
      />
    </svg>
  )
}

export default TwitterIcon
