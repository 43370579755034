import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const DownloadIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 24 23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9999 17.1028L4.62815 10.0515L6.69226 8.00661L10.5256 11.6733V0.179688H13.4743V11.6733L17.3076 8.00661L19.3717 10.0515L11.9999 17.1028ZM3.1538 22.7438C2.3429 22.7438 1.64872 22.4676 1.07126 21.9153C0.493807 21.3629 0.205078 20.6989 0.205078 19.9233V15.6925H3.1538V19.9233H20.8461V15.6925H23.7948V19.9233C23.7948 20.6989 23.5061 21.3629 22.9286 21.9153C22.3512 22.4676 21.657 22.7438 20.8461 22.7438H3.1538Z'
        fill='#94C745'
      />
    </svg>
  )
}

export default DownloadIcon
