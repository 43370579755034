
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel6: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='81'
      style={style}
      viewBox='0 0 126 81'
      width='126'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='81' width='126' />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='7.5'
        y='22.5'
      />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='45.5'
        y='22.5'
      />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='83.5'
        y='22.5'
      />
      <rect
        height='58'
        rx='4.5'
        stroke='#676767'
        strokeDasharray='2 2'
        width='61'
        x='32.5'
        y='11.5'
      />
    </svg>
  )
}

export default CarouselModel6
