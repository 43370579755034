export const formats = [
  {
    id: 'interstitial',
    title: 'Interstitial',
    child: [{ width: 1080, height: 1920 }],
    icon: <div css={{width: 15, height: 30, border: "2px solid #2b2b2b", borderRadius: 3}}/>
  },
  {
    id: 'square',
    title: 'Cuadrados',
    child: [
      { width: 200, height: 200 },
      { width: 250, height: 250 },
      { width: 50, height: 50 },
    ],
    icon: <div css={{width: 20, height: 20, border: "2px solid #2b2b2b", borderRadius: 3}}/>
  },
  {
    id: 'portrait',
    title: 'Vertical',
    child: [
      { width: 300, height: 1050 },
      { width: 160, height: 600 },
      { width: 120, height: 600 },
      { width: 320, height: 480 },
    ],
    icon: <div css={{width: 20, height: 30, border: "2px solid #2b2b2b", borderRadius: 3}}/>
  },
  {
    id: 'landscape',
    title: 'Horizontal',
    child: [
      { width: 970, height: 250 },
      { width: 300, height: 250 },
      { width: 300, height: 50 },
      { width: 320, height: 50 },
      { width: 728, height: 90 },
      { width: 970, height: 90 },
      { width: 120, height: 20 },
      { width: 168, height: 28 },
      { width: 216, height: 36 },
      { width: 480, height: 320 },
    ],
    icon: <div css={{width: 30, height: 20, border: "2px solid #2b2b2b", borderRadius: 3}}/>
  },
];
