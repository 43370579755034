import XIcon from '@duyank/icons/regular/X';
import { useEditor } from '@lidojs/design-editor';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getImageCompressed } from '../../utils/images/getImageCompressed';

interface UploadContentProps {
  visibility: boolean;
  onClose: () => void;
}

const UploadContent: FC<UploadContentProps> = ({ visibility, onClose }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const objectUrlsRef = useRef<Map<string, string>>(new Map());
  const { actions, activePage, pages } = useEditor(
    (state) => ({
      activePage: state.activePage,
      pages: state.pages
    })
  );

  const [mediaContent, setMediaContent] = useState<
    { url: string | File; type: 'video' | 'image'; objectUrl?: string }[]
  >([]);

  const currentPage = pages[activePage];
  const containerSize = currentPage.layers?.ROOT?.data?.props?.boxSize;
  const formatsAccepted = "image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/webm, video/quicktime"

  const addImage = async (url: string | File) => {
    const img = new Image();
    img.onerror = (err) => window.alert(err);
    img.src = url instanceof File ? URL.createObjectURL(url) : url;
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      actions.addImageLayer(
        { url, thumb: url },
        { width: img.naturalWidth, height: img.naturalHeight }
      );
      if (isMobile) {
        onClose();
      }
    };
  };

  const addVideo = async (url: string | File) => {
    const video = document.createElement('video');
    video.src = url instanceof File ? URL.createObjectURL(url) : url;

    video.onloadedmetadata = () => {
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      actions.addVideoLayer(
        { url },
        { width: videoWidth, height: videoHeight }
      );

      if (isMobile) {
        onClose();
      }
    };

    video.onerror = (err) => console.error('Error loading video: ' + err);
  };

  const addMedia = async (url: string | File) => {
    if (url instanceof File) {
      if (url.type.startsWith('video')) {
        addVideo(url);
      } else {
        addImage(url);
      }
    }
  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const promises = Array.from(files).map(async (file) => {
        let processedFile = file;

        if (file.type.startsWith('image/')) {
          processedFile = await getImageCompressed(file, {
            containerSize,
            quality: 0.8,
          });
        }

        const objectUrl = URL.createObjectURL(processedFile);
        objectUrlsRef.current.set(objectUrl, processedFile.name);
        setMediaContent((prevState) => [
          ...prevState,
          {
            url: processedFile,
            type: processedFile.type.startsWith('video') ? 'video' : 'image',
            objectUrl,
          },
        ]);
      });

      await Promise.all(promises);
    }
  };

  const cleanupObjectUrls = () => {
    objectUrlsRef.current.forEach((name, url) => {
      URL.revokeObjectURL(url);
      objectUrlsRef.current.delete(url);
    });
  };

  useEffect(() => {
    return () => {
      cleanupObjectUrls();
    };
  }, []);

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        display: visibility ? 'flex' : 'none',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Upload
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClose}
        >
          <XIcon />
        </div>
      </div>
      <div
        css={{
          margin: 16,
          background: '#3a3a4c',
          borderRadius: 8,
          color: '#fff',
          padding: '8px 16px',
          cursor: 'pointer',
          textAlign: 'center',
        }}
        onClick={() => inputFileRef.current?.click()}
      >
        Upload
      </div>
      <input
        ref={inputFileRef}
        multiple
        accept={formatsAccepted}
        css={{ display: 'none' }}
        type={'file'}
        onChange={handleUpload}
      />
      <div css={{ padding: '16px' }}>
        <div
          css={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
            gridGap: 8,
          }}
        >
          {mediaContent.map((item, idx) => (
            <div
              key={idx}
              css={{ cursor: 'pointer', position: 'relative' }}
              onClick={() => addMedia(item.url)}
            >
              <div css={{ paddingBottom: '100%', height: 0 }} />
              <div
                css={{
                  position: 'absolute',
                  inset: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {item.type === 'video' ? (
                  <video
                    muted
                    css={{ maxHeight: '100%' }}
                    src={
                      item.objectUrl ||
                      (typeof item.url === 'string'
                        ? item.url
                        : URL.createObjectURL(item.url))
                    }
                  />
                ) : (
                  <img
                    alt='uploaded images'
                    css={{ maxHeight: '100%' }}
                    loading="lazy"
                    src={
                      item.objectUrl ||
                      (typeof item.url === 'string'
                        ? item.url
                        : URL.createObjectURL(item.url))
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UploadContent;
