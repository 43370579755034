
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel4: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='90'
      style={style}
      viewBox='0 0 100 90'
      width='100'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='90' width='100' />
      <rect
        fill='white'
        height='27'
        rx='4.5'
        stroke='#676767'
        width='80'
        x='10.5'
        y='36.5'
      />
      <rect
        fill='white'
        height='27'
        rx='4.5'
        stroke='#676767'
        width='80'
        x='10.5'
        y='9.5'
      />
      <rect
        fill='white'
        height='26'
        rx='4.5'
        stroke='#676767'
        width='80'
        x='10.5'
        y='63.5'
      />
    </svg>
  )
}

export default CarouselModel4
