import { LayerComponentProps } from "@lidojs/design-core";
import { Dropzone } from '../../../common';
import { VideoLayerProps } from "../../../layers";
import {Layer} from "../../../types"

const acceptedVideoFormats = ['video/mp4', 'video/webm', 'video/quicktime'];

export const VideoRenderer = ({
  component,
  onChangeMedia,
  getObjectUrl,
  index
}: {
  component: Layer<LayerComponentProps>;
  onChangeMedia: (id: string, file: File, type: 'video') => void;
  getObjectUrl: (file: File, type: 'video') => string;
  index: number
}) => {
  return (
    <div key={component.id} css={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        <p css={{ textAlign: 'center', fontWeight: 'bolder', marginBottom: 2 }}>Video {index}</p>
        <p css={{ textAlign: 'center' }}>Suggested Dimensions</p>
        <p css={{ textAlign: 'center', marginBottom: 6 }}>
          {(component.data.props as VideoLayerProps).video.boxSize.width} x{' '}
          {(component.data.props as VideoLayerProps).video.boxSize.height.toFixed(0)}
        </p>
        <Dropzone
          key={component.id}
          acceptedFormats={acceptedVideoFormats}
          enableEdit={true}
          preview={
            typeof (component.data.props as VideoLayerProps).video.url === 'string'
              ? (component.data.props as VideoLayerProps).video.url as string
              : getObjectUrl((component.data.props as VideoLayerProps).video.url as File, 'video')
          }
          previewFileType="video"
          onChange={(file) => onChangeMedia(component.id, file, 'video')}
        />
      </div>
    </div>
  );
};
