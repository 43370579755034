export const DepthCard2 = () => {
  return (
    <svg
      height={'auto'}
      id="Capa_1"
      viewBox="0 0 469 887"
      width={'100%'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <rect
            height="697.66"
            rx="33.8"
            ry="33.8"
            style={{ fill: '#e5e5e5' }}
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            style={{ fill: '#fff', stroke: '#ccc', strokeWidth: 1 }}
          />
        </g>
        <g>
          <rect
            height="31.23"
            rx="7"
            ry="7"
            style={{ fill: '#fff' }}
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle cx="269.8" cy="132.79" r="4.13" style={{ fill: '#e5e5e5' }} />
        </g>
      </g>
      <rect
        height="10241"
        style={{ fill: 'none', stroke: 'red', strokeWidth: 6 }}
        width="3265"
        x="-1222"
        y="-661"
      />
      <polygon
        points="96 703.53 371.77 703.53 349.29 218.71 96 203 96 703.53"
        style={{ fill: 'gray' }}
      />
    </svg>
  );
};
