import { FunctionComponent } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { generateEmptyItemsArray } from '../../../utils/generateEmptyItems';

import { CarouselContentProps } from '../../CarouselContent';
import { CarouselItem } from '../CarouselItem';
import { EmptySlide } from '../EmptySlide';

const Carousel5: FunctionComponent<CarouselContentProps> = ({
  itemsCarousel,
  autoplayCarousel,
  loopCarousel,
  speedCarousel,
  objectFit,
}) => {
  const minItems = 3;
  const emptyItems = generateEmptyItemsArray(minItems, itemsCarousel.length);
  return (
    <Swiper
      allowTouchMove={false}
      autoplay={{ disableOnInteraction: false }}
      css={{
        width: '100%',
        height: '100%',
      }}
      loop={loopCarousel}
      modules={autoplayCarousel ? [Autoplay] : []}
      noSwiping={true}
      noSwipingClass="swiper-slide"
      slidesPerView={3}
      spaceBetween={8}
      speed={speedCarousel}
    >
      {itemsCarousel.map((item) => (
        <SwiperSlide key={item.id}>
          <CarouselItem item={item} objectFit={objectFit} />
        </SwiperSlide>
      ))}
      {emptyItems.map((item) => (
        <SwiperSlide key={item}>
          <EmptySlide>{item}</EmptySlide>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel5;
