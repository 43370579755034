import { SerializedLayer } from "@lidojs/design-core";
import { RootContentProps } from "@lidojs/design-layers";
import { filterProps } from "./filterProps";
import { generateFilters } from "./generateFilters";
import { XMobileFrameHTML } from "./htmlComponents";

export const generateContainer = (container: SerializedLayer, hasMobileFrame?: boolean): string => {
  const child = JSON.stringify(container.child);
  const { props } = container;

  const validProps = filterProps(props);
  const { boxSize, rotate, color, image, video } = validProps as unknown as RootContentProps;

  const isPortrait = boxSize.height > boxSize.width;
  const isInterstitial = boxSize.width === 1080 && boxSize.height === 1920;

  const generatewidthStyle = (): string => {
    if(hasMobileFrame) {
      return `width: 100%; height: auto;`
    } else if(isPortrait) {
      return `height: 100vh; width: auto;`
    } else{
      return `width: 100vw; height: auto;`
    }
  }

  const styles = `
    ${generatewidthStyle()}
    aspect-ratio: ${boxSize.width} / ${boxSize.height};
    position: relative;
    overflow: hidden;
    ${rotate ? `transform: rotate(${rotate}deg);` : ''}
  `.trim();

  const background = color ? `background-color: ${color};` : "";

  let content = '';
  if (image) {
    const filterString = generateFilters(image);
    content = `<img src="${image.url}" style="position:absolute; width:${(image.boxSize.width / boxSize.width) * 100}%;
                  height:${(image.boxSize.height / boxSize.height) * 100}%; top:${(image.position.y / boxSize.height) * 100}%;
                  left:${(image.position.x / boxSize.width) * 100}%; transform: rotate(${image.rotate}deg); filter: ${filterString};" alt="">`;
  } else if (video) {
    content = `<video style="position:absolute; width:${(video.boxSize.width / boxSize.width) * 100}%;
                height:${(video.boxSize.height / boxSize.height) * 100}%; top:${(video.position.y / boxSize.height) * 100}%;
                left:${(video.position.x / boxSize.width) * 100}%; transform: rotate(${video.rotate}deg);" autoplay muted>
                <source src="${video.url}" type="video/mp4">
              </video>`;
  }

  return `
    <div style="width: 100%; height: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center">
    ${hasMobileFrame ? `<div class="mobile-preview">` : ""}
    <div data-locked="${container.locked}"
        data-id="ROOT"
        data-type="${container.type.resolvedName}"
        data-child='${child}'
        data-parent="${container.parent}"
        style="${styles} ${background}">
      ${(hasMobileFrame && isInterstitial) ? XMobileFrameHTML : ""}
      ${content}
    </div>
    ${hasMobileFrame ? "<div>" : ""}
    </div>
  `;
}
