
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const Upload2Icon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 21 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.1875 16.5V5.29375L5.775 8.86875L3.9375 6.875L10.5 0L17.0625 6.875L15.225 8.86875L11.8125 5.29375V16.5H9.1875ZM2.625 22C1.90312 22 1.28516 21.7307 0.771094 21.1922C0.257031 20.6536 0 20.0063 0 19.25V15.125H2.625V19.25H18.375V15.125H21V19.25C21 20.0063 20.743 20.6536 20.2289 21.1922C19.7148 21.7307 19.0969 22 18.375 22H2.625Z'
        fill='#EE1A30'
      />
    </svg>
  )
}

export default Upload2Icon
