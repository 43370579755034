import { FunctionComponent } from 'react';
import { IconProps } from '../Icon';

const MobileOffIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#000000',
  size = 24,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      style={style}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          clip-rule="evenodd"
          d="M5.38062 4.29483C5.1365 4.81208 5 5.3901 5 6V18C5 20.2091 6.79086 22 9 22H15C17.2091 22 19 20.2091 19 18V17.9142L17 15.9142V18C17 19.1046 16.1046 20 15 20H9C7.89543 20 7 19.1046 7 18V6C7 5.97184 7.00058 5.94382 7.00173 5.91595L5.38062 4.29483ZM17 13.0858V6C17 4.89543 16.1046 4 15 4H9C8.68362 4 8.3844 4.07346 8.11847 4.20426L6.66565 2.75143C7.32258 2.27854 8.12876 2 9 2H15C17.2091 2 19 3.79086 19 6V15.0858L17 13.0858ZM11 16C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18H13C13.5523 18 14 17.5523 14 17C14 16.4477 13.5523 16 13 16H11Z"
          fill={color}
          fill-rule="evenodd"
        ></path>{' '}
        <path
          d="M3 3L21 21"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        ></path>{' '}
      </g>
    </svg>
  );
};

export default MobileOffIcon;
