import {
  BoxSize,
  Delta,
  getTransformStyle,
  LayerComponentProps,
} from '@lidojs/design-core';
import React, { FC, useEffect, useState } from 'react';
import { ObjectFit } from '../enum';

export interface VideoContentProps extends LayerComponentProps {
  video: {
    url: string | File;
    position: Delta;
    rotate: number;
    boxSize: BoxSize;
    transparency?: number;
    autoPlay?: boolean;
    mute?: boolean;
    controls?: boolean;
    loop?: boolean;
    objectFit?: ObjectFit;
  };
}

export const VideoContent: FC<VideoContentProps> = ({ video, boxSize }) => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(
    typeof video.url === 'string' ? video.url : undefined
  );

  useEffect(() => {
    if (typeof video.url !== 'string') {
      const objectUrl = URL.createObjectURL(video.url);
      setVideoUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      setVideoUrl(video.url);
    }
  }, [video.url]);

  return (
    <div
      css={{
        overflow: 'hidden',
        pointerEvents: 'auto',
        width: boxSize.width,
        height: boxSize.height,
      }}
    >
      <div
        css={{
          width: video.boxSize.width,
          height: video.boxSize.height,
          transform: getTransformStyle({
            position: video.position,
            rotate: video.rotate,
          }),
          position: 'relative',
          userSelect: 'none',
          opacity: video.transparency,
        }}
      >
        <video
          crossOrigin="anonymous"
          css={{ objectFit: (video?.objectFit ?? "contain"), width: '100%', height: '100%' }}
          muted={true}
          src={videoUrl}
        />
      </div>
    </div>
  );
};
