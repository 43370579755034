import { CarouselContentProps } from "@lidojs/design-layers";
import { generateSlide } from "./generateSlide";

export const generateSlides = (props: CarouselContentProps) => {
  const { itemsCarousel: items } = props;

  if (items?.length) {
    const validItems = items.filter((item) => item && typeof item.image === 'string');

    const carouselSliders = validItems
      .map((item, ind) => generateSlide(props, item.image as string, validItems[ind]))
      .join('');

    return carouselSliders;
  }

  return "";
};
