import { redirectURLDefault } from "../../constant/redirectURL";
import {
  Colector1,
  Colector2,
  DepthCard1,
  DepthCard2,
  FlipSlides1,
  FlipSlides2,
  FlipSlides3,
  FlipUp1,
  FlipUp2,
  FlipUp3,
  Rotate1,
  Rotate2,
  Rotate3,
  Scratch1,
  Scratch2,
  Videobox,
  VideoboxCarousel,
} from './premium-templates-images';

export const premiumTemplates = [
  {
    id: 1,
    name: 'Depth Card',
    mainImage: <DepthCard1/>,
    images: [<DepthCard2/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'depthcard_image1'
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'depthCard'
        },
        'depthcard_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730481971103.jpg',
              thumb: 'https://creatives.wortise.com/1730481971103.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'depthcard_image1',
        },
      },
    },
  },
  {
    id: 2,
    name: 'Flip UP',
    mainImage: <FlipUp1/>,
    images: [<FlipUp2/>, <FlipUp3/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'flipUp_image2',
            'flipUp_image1',
            'flipUp_line1',
            'flipUp_image3'
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'flipUp'
        },
        'flipUp_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487036108.jpg',
              thumb: 'https://creatives.wortise.com/1730487036108.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 960,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipUp_image1',
        },
        'flipUp_image2': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487139630.jpg',
              thumb: 'https://creatives.wortise.com/1730487139630.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipUp_image2',
        },
        'flipUp_line1': {
          type: {
            resolvedName: 'LineLayer'
          },
          child: [],
          props: {
            boxSize: {
              width: 1080,
              height: 20
            },
            position: {
                x: 0,
                y: 940
            },
            style: "solid",
            color: "rgb(255, 255, 255)",
            scale: 1,
            rotate: 360,
            hyperlink: {
              hasHyperlink: false,
              typeLink: "a-href",
              url: "https://wortise.com"
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipUp_line1',
        },
        'flipUp_image3': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1729775778885.png',
              thumb: 'https://creatives.wortise.com/1729775778885.png',
              rotate: 0,
              boxSize: {
                width: 80,
                height: 80,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 80,
              height: 80,
            },
            position: {
              x: 500,
              y: 910,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipUp_image3',
        },
      },
    },
  },
  {
    id: 3,
    name: 'Rotate',
    mainImage: <Rotate1/>,
    images: [<Rotate2/>, <Rotate3/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'rotate_image3',
            'rotate_image4',
            'rotate_image1',
            'rotate_image2',
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'rotate'
        },
        'rotate_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487261669.jpg',
              thumb: 'https://creatives.wortise.com/1730487261669.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'rotate_image1',
        },
        'rotate_image2': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487469309.png',
              thumb: 'https://creatives.wortise.com/1730487469309.png',
              rotate: 0,
              boxSize: {
                width: 788.4,
                height: 1338.816,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 788.4,
              height: 1338.816,
            },
            position: {
              x: 151.2,
              y: 460.8,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'rotate_image2',
        },
        'rotate_image3': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487343710.jpg',
              thumb: 'https://creatives.wortise.com/1730487343710.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'rotate_image3',
        },
        'rotate_image4': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487394047.png',
              thumb: 'https://creatives.wortise.com/1730487394047.png',
              rotate: 0,
              boxSize: {
                width: 788.4,
                height: 1338.816,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 788.4,
              height: 1338.816,
            },
            position: {
              x: 151.2,
              y: 460.8,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'rotate_image4',
        },
      },
    },
  },
  {
    id: 4,
    name: 'Flip Slides',
    mainImage: <FlipSlides1/>,
    images: [<FlipSlides2/>, <FlipSlides3/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'flipSlides_image2',
            'flipSlides_image1',
            'flipSlides_line1',
            'flipSlides_image3'
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'flipSlides'
        },
        'flipSlides_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487585794.jpg',
              thumb: 'https://creatives.wortise.com/1730487585794.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 540,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipSlides_image1',
        },
        'flipSlides_image2': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730487608311.jpg',
              thumb: 'https://creatives.wortise.com/1730487608311.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipSlides_image2',
        },
        'flipSlides_line1': {
          type: {
            resolvedName: 'LineLayer'
          },
          child: [],
          props: {
            boxSize: {
              width: 8,
              height: 1920
            },
            position: {
                x: 540,
                y: 0
            },
            style: "solid",
            color: "rgb(255, 255, 255)",
            scale: 1,
            rotate: 0,
            hyperlink: {
              hasHyperlink: false,
              typeLink: "a-href",
              url: "https://wortise.com"
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipSlides_line1',
        },
        'flipSlides_image3': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1729775668543.png',
              thumb: 'https://creatives.wortise.com/1729775668543.png',
              rotate: 0,
              boxSize: {
                width: 80,
                height: 80,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 80,
              height: 80,
            },
            position: {
              x: 504,
              y: 910,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'flipSlides_image3',
        },
      },
    },
  },
  {
    id: 5,
    name: 'Videobox',
    mainImage: <Videobox/>,
    images: [],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'videobox_image1',
            'videobox_video1'
          ],
          props: {
            color: '#000000',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'videobox'
        },
        'videobox_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730732220729.jpg',
              thumb: 'https://creatives.wortise.com/1730732220729.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1344,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1344,
            },
            position: {
              x: 0,
              y: 576,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'videobox_image1',
        },
        'videobox_video1': {
          type: {
            resolvedName: 'VideoLayer',
          },
          child: [],
          props: {
            video: {
              url: 'https://creatives.wortise.com/1730732299650.mp4',
              Autoplay: true,
              rotate: 0,
              controls: false,
              objectFit: 'contain',
              loop: true,
              mute: false,
              boxSize: {
                width: 1080,
                height: 576,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 576,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'videobox_video1',
        },
      },
    },
  },
  {
    id: 6,
    name: 'Videobox Carousel',
    mainImage: <VideoboxCarousel/>,
    images: [],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'videoboxCarousel_image1',
            'videoboxCarousel_video1',
            'videoboxCarousel_carousel1'
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: true,
          parent: null,
          isPremium: true,
          idPremium: 'videoboxCarousel'
        },
        'videoboxCarousel_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730733455245.jpg',
              thumb: 'https://creatives.wortise.com/1730733455245.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 866.508814,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 866.508814,
            },
            position: {
              x: 0,
              y: 613.254282504,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'videoboxCarousel_image1',
        },
        'videoboxCarousel_video1': {
          type: {
            resolvedName: 'VideoLayer',
          },
          child: [],
          props: {
            video: {
              url: 'https://creatives.wortise.com/1730732299650.mp4',
              Autoplay: true,
              rotate: 0,
              controls: false,
              objectFit: 'contain',
              loop: true,
              mute: false,
              boxSize: {
                width: 1080,
                height: 609.264009584,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 609.264009584,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'videoboxCarousel_video1',
        },
        'videoboxCarousel_carousel1': {
          type: {
            resolvedName: 'CarouselLayer',
          },
          child: [],
          props: {
            position: {
              x: 0,
              y: 1570.9824
            },
            boxSize: {
              width: 1080,
              height: 349.0176
            },
            rotate: 0,
            color: "#5E6278",
            scale: 1.312,
            itemsCarousel: [{
              id: "carouselItem1",
              image: "https://creatives.wortise.com/1730733544812.jpg",
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            {
              image: "https://creatives.wortise.com/1730733544812.jpg",
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault,
              id: "carouselItem2",
            },
            {
              image: "https://creatives.wortise.com/1730733544812.jpg",
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault,
              id: "carouselItem3",
            },
            ],
            autoplayCarousel: true,
            loopCarousel: true,
            speedCarousel: 1000,
            objectFit: "contain",
            typeCarousel: "carousel5",
            hyperlink: {
              hasHyperlink: false,
              typeLink: "a-href",
              url: "https://wortise.com"
            }
        },
        locked: true,
        parent: 'ROOT',
        idPremium: 'videoboxCarousel_carousel1',
        },
      },
    },
  },
  {
    id: 7,
    name: 'Scratch',
    mainImage: <Scratch1/>,
    images: [<Scratch2/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'scratch_image3',
            'scratch_image1',
            'scratch_image2'
          ],
          props: {
            color: 'rgb(255,255,255)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
            redirectCreative: {
              active: true,
              idCreative: 'scratchRelates',
            },
            groupings: {
              ImageLayer: [{
                  id: 'imageToDiscover',
                  label: 'Image to Discover',
                  items: ['scratch_image3']
                },
                {
                  id: 'background',
                  label: 'Background',
                  items: ['scratch_image1']
                },
                {
                  id: 'imageToScratch',
                  label: 'Image to Scratch',
                  items: ['scratch_image2']
                }
              ],
            }
          },
          locked: true,
          parent: null,
          isPremium: true,
          idCreative: 'scratch',
          idPremium: 'scratch'
        },
        'scratch_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730483619089.png',
              thumb: 'https://creatives.wortise.com/1730483619089.png',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'scratch_image1',
        },
        'scratch_image2': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730483851574.jpg',
              thumb: 'https://creatives.wortise.com/1730483851574.jpg',
              rotate: 0,
              boxSize: {
                width: 704,
                height: 765,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 704,
              height: 765,
            },
            position: {
              x: 188,
              y: 969,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'scratch_image2',
        },
        'scratch_image3': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730483732997.jpg',
              thumb: 'https://creatives.wortise.com/1730483732997.jpg',
              rotate: 0,
              boxSize: {
                width: 704,
                height: 765,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 704,
              height: 765,
            },
            position: {
              x: 188,
              y: 969,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'scratch_image3',
        },
      },
    }
  },
  {
    id: 8,
    name: 'Collector',
    mainImage: <Colector1/>,
    images: [<Colector2/>],
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer'
          },
          child: [
            'colector_text4',
            'colector_text5',
            'colector_image1',
            'colector_image2',
            'colector_image3',
            'colector_image4',
            'colector_image5',
            'colector_image6',
            'colector_text1',
            'colector_text2',
            'colector_text3'
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
            redirectCreative: {
              active: true,
              idCreative: 'colectorRelates',
            },
            configCreative: {
              livesNumber: {
                id: 'livesNumber',
                label: 'Number of lives',
                value: 3
              },
              pointsToWin: {
                id: 'pointsToWin',
                label: 'Points to win',
                value: 5
              },
              elementsToWin: {
                id: 'elementsToWin',
                label: 'Number of elements that add points',
                value: 15
              },
              elementsToLose: {
                id: 'elementsToLose',
                label: 'Number of elements that subtract points',
                value: 15
              }
            },
            groupings: {
              ImageLayer: [{
                  id: 'background',
                  label: 'Background',
                  items: ['colector_image1']
                },
                {
                  id: 'awayLife',
                  label: ' Image that takes away life',
                  items: ['colector_image2']
                },
                {
                  id: 'givesYouPoints',
                  label: 'Image that gives you points',
                  items: ['colector_image3', 'colector_image4', 'colector_image5']
                },
                {
                  id: 'thatCollects',
                  label: ' Image that collects',
                  items: ['colector_image6']
                }
              ],
            }
          },
          locked: true,
          parent: null,
          isPremium: true,
          idCreative: 'colector',
          idPremium: 'colector'
        },
        'colector_image1': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735093010.jpg',
              thumb: 'https://creatives.wortise.com/1730735093010.jpg',
              rotate: 0,
              boxSize: {
                width: 1080,
                height: 1920,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1080,
              height: 1920,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image1',
        },
        'colector_image2': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735431089.png',
              thumb: 'https://creatives.wortise.com/1730735431089.png',
              rotate: 0,
              boxSize: {
                width: 177,
                height: 199,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 177,
              height: 199,
            },
            position: {
              x: 500,
              y: 600,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image2',
        },
        'colector_image3': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735346689.png',
              thumb: 'https://creatives.wortise.com/1730735346689.png',
              rotate: 0,
              boxSize: {
                width: 177,
                height: 199,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 177,
              height: 199,
            },
            position: {
              x: 700,
              y: 1200,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image3',
        },
        'colector_image4': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735346689.png',
              thumb: 'https://creatives.wortise.com/1730735346689.png',
              rotate: 0,
              boxSize: {
                width: 177,
                height: 199,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 177,
              height: 199,
            },
            position: {
              x: 200,
              y: 1000,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image4',
        },
        'colector_image5': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735346689.png',
              thumb: 'https://creatives.wortise.com/1730735346689.png',
              rotate: 0,
              boxSize: {
                width: 177,
                height: 199,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 177,
              height: 199,
            },
            position: {
              x: 600,
              y: 200,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image5',
        },
        'colector_image6': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://creatives.wortise.com/1730735241449.png',
              thumb: 'https://creatives.wortise.com/1730735241449.png',
              rotate: 0,
              boxSize: {
                width: 189,
                height: 150,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 189,
              height: 150,
            },
            position: {
              x: 500,
              y: 1760,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_image6',
        },
        'colector_text1': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Montserrat;font-size: 38px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(255, 255, 255);">Puntaje: </span></strong></p>',
            fonts: [
              {
                  "name": "Montserrat",
                  "fonts": [
                      {
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      },
                      {
                          "style": "Bold",
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      }
                  ]
              }
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 0,
              y: 0,
              width: 200,
              height: 45,
            },
            position: {
              x: 10,
              y: 130,
            },
            fontSizes: [20],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            isHideSettings: true
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_text1',
        },
        'colector_text2': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Montserrat;font-size: 38px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(255, 255, 255);">Vidas: </span></strong></p>',
            fonts: [
              {
                  "name": "Montserrat",
                  "fonts": [
                      {
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      },
                      {
                          "style": "Bold",
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      }
                  ]
              }
          ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 0,
              y: 0,
              width: 200,
              height: 45,
            },
            position: {
              x: 835,
              y: 130,
            },
            fontSizes: [20],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            isHideSettings: true
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_text2',
        },
        'colector_text3': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Montserrat;font-size: 70px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(255, 255, 255); font-weight: bold">Recolecta los regalos</span></strong></p>',
            fonts: [
              {
                  "name": "Montserrat",
                  "fonts": [
                      {
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      },
                      {
                          "style": "Bold",
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      }
                  ]
              }
          ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 0,
              y: 0,
              width: 500,
              height: 225,
            },
            position: {
              x: 300,
              y: 850,
            },
            fontSizes: [70],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            label: 'Welcome text'
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_text3',
        },
        'colector_text4': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Montserrat;font-size: 70px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(255, 255, 255); font-weight: bold">¡Ganaste!</span></strong></p>',
            fonts: [
              {
                  "name": "Montserrat",
                  "fonts": [
                      {
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      },
                      {
                          "style": "Bold",
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      }
                  ]
              }
          ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 0,
              y: 0,
              width: 500,
              height: 225,
            },
            position: {
              x: 300,
              y: 850,
            },
            fontSizes: [70],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            label: 'Text when you win'
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_text4',
        },
        'colector_text5': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Montserrat;font-size: 70px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(255, 255, 255); font-weight: bold">¡Perdiste!</span></strong></p>',
            fonts: [
              {
                  "name": "Montserrat",
                  "fonts": [
                      {
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      },
                      {
                          "style": "Bold",
                          "urls": [
                              "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          ]
                      }
                  ]
              }
          ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 0,
              y: 0,
              width: 500,
              height: 225,
            },
            position: {
              x: 300,
              y: 850,
            },
            fontSizes: [70],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            label: 'Text when you lose'
          },
          locked: true,
          parent: 'ROOT',
          idPremium: 'colector_text5',
        }
      },
    },
  },
];
