import XIcon from '@duyank/icons/regular/X';
import { SerializedLayer, SerializedPage } from '@lidojs/design-core';
import { RootLayerProps, useEditor } from '@lidojs/design-editor';
import { premiumTemplates as templatesDesign } from '@lidojs/design-layers';
import { FC, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import ImageSwitcher from '../../components/ImageSwitcher';
import { getRandomId } from 'libs/design-editor/src/ultils/layer/layers';
import { generateLayerPremium } from '../../utils/generateLayerPremium';

interface Template {
  elements: SerializedPage;
  name: string;
  mainImage: JSX.Element;
  images: JSX.Element[];
}

const PremiumTemplateContent: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { actions, activePage, relatesToCreative, pages, redirectCreative } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
    redirectCreative: state.pages[state.activePage] && state.pages[state.activePage].layers.ROOT.data?.props?.redirectCreative,
    relatesToCreative:
      state.pages[state.activePage] &&
      state.pages[state.activePage].layers.ROOT.data?.props?.relatesTo,
  }));

  const addPage = async (data: SerializedPage) => {
    const newLayer = generateLayerPremium(data, relatesToCreative);
    actions.setPage(activePage, newLayer);

    const newLayerProps = newLayer.layers.ROOT.props as unknown as RootLayerProps;
    const newLayerHasRedirectCreative = newLayerProps.redirectCreative?.active;

    if (!newLayerHasRedirectCreative) {
      if (isMobile) onClose();
      handleDeletePageIfRedirectActive(redirectCreative);
      return;
    }

    const idPremiumRelates = (newLayer.layers.ROOT as SerializedLayer).idCreative;
    const newIdCreative = newLayerProps.redirectCreative.idCreative;

    handleDeletePageIfRedirectActive(redirectCreative);

    actions.addPage({
      pageIndex: activePage,
      customId: newIdCreative,
      relatesTo: idPremiumRelates,
      changeActivePage: false,
    });

    if (isMobile) onClose();
  };

  const handleDeletePageIfRedirectActive = (redirectCreative?: { active: boolean; idCreative?: string }) => {
    if (redirectCreative?.active) {
      const relatedIndex = pages.findIndex(
        (page) => page.layers.ROOT?.data?.idCreative === redirectCreative.idCreative
      );
      if (relatedIndex !== -1) {
        actions.deletePage(relatedIndex);
      }
    }
  };

  const templates: Template[] = useMemo(() => {
    return relatesToCreative
      ? templatesDesign.filter(
          (item) => !item.elements.layers.ROOT.props.redirectCreative?.active
        )
      : templatesDesign;
  }, [relatesToCreative]);


  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Premium Templates
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClose}
        >
          <XIcon />
        </div>
      </div>
      <div
        css={{ flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      >
        <div
          css={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
            gridGap: 8,
            padding: '16px',
          }}
        >
          {templates.map((item) => (
            <div
              key={item.name}
              css={{ cursor: 'pointer' }}
              onClick={() => addPage(item.elements)}
            >
              <ImageSwitcher
                images={item.images}
                mainImage={item.mainImage}
                title={item.name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PremiumTemplateContent;
