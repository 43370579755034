import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const AdjustIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={size}
      style={style}
      viewBox='0 0 15 15'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.25 15C0.916667 15 0.625 14.875 0.375 14.625C0.125 14.375 0 14.0833 0 13.75V1.25C0 0.916667 0.125 0.625 0.375 0.375C0.625 0.125 0.916667 0 1.25 0H13.75C14.0833 0 14.375 0.125 14.625 0.375C14.875 0.625 15 0.916667 15 1.25V13.75C15 14.0833 14.875 14.375 14.625 14.625C14.375 14.875 14.0833 15 13.75 15H1.25ZM1.25 13.75H4.58333V10.4167H1.25V13.75ZM5.83333 13.75H9.16667V10.4167H5.83333V13.75ZM10.4167 13.75H13.75V10.4167H10.4167V13.75ZM1.25 9.16667H4.58333V5.83333H1.25V9.16667ZM5.83333 9.16667H9.16667V5.83333H5.83333V9.16667ZM10.4167 9.16667H13.75V5.83333H10.4167V9.16667ZM1.25 4.58333H4.58333V1.25H1.25V4.58333ZM5.83333 4.58333H9.16667V1.25H5.83333V4.58333ZM10.4167 4.58333H13.75V1.25H10.4167V4.58333Z'
        fill='#676767'
      />
    </svg>
  )
}

export default AdjustIcon
