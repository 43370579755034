type Props = Record<string , unknown>

export const filterProps = (props: Props): Props => {
  const filteredProps: Partial<Props> = {};
  for (const key in props) {
      if (props[key]) {
          filteredProps[key] = props[key];
      }
  }
  return filteredProps;
};
