import { Input } from '@nextui-org/react';
import React from 'react';
import { useEditor } from '../../../hooks';
import { ConfigCreative } from '../../../types';

export const ConfigPremiumettings = () => {
  const { actions, activePage, pages } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
  }));

  const currentPage = pages[activePage].layers;
  const { ROOT } = currentPage;

  const configSettings: ConfigCreative[] = ROOT.data?.props?.configCreative
    ? Object.values(ROOT.data?.props?.configCreative)?.map((config) => config)
    : [];

  const onChangeConfig = (id: string, value: string) => {
    actions.setProp(activePage, 'ROOT', {
      configCreative: {
        ...ROOT.data.props?.configCreative,
        [id]: {
          ...ROOT.data.props?.configCreative[id],
          value: parseInt(value),
        },
      },
    });
  };

  if (!configSettings?.length) return null;

  return (
    <>
      {configSettings.map((config, index) => (
        <div key={config.id}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <label css={{ fontWeight: 'bolder' }} htmlFor={config.id}>
              {config.label}
            </label>
            <Input
              max={9999}
              min={1}
              name={config.id}
              type="number"
              value={config.value.toString()}
              onChange={(e) => onChangeConfig(config.id, e.target.value)}
            />
          </div>
        </div>
      ))}
    </>
  );
};
