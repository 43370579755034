export const Rotate2 = () => {
  return (
    <svg height={"auto"} id="Capa_1" viewBox="0 0 469 887" width={"100%"} xmlns="http://www.w3.org/2000/svg">
      <defs />
      <rect
        height="10241"
        style={{ fill: 'none', stroke: 'red', strokeWidth: 6 }}
        width="3265"
        x="-1280"
        y="-5866"
      />
      <g>
        <g>
          <rect
            height="697.66"
            style={
              { fill: '#e5e5e5', rx: 33.8, ry: 33.8 } as React.CSSProperties
            }
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            style={{ fill: '#ccc', stroke: '#ccc', strokeMiterlimit: 10 }}
          />
        </g>
        <g>
          <rect
            height="31.23"
            style={{ fill: '#fff', rx: 7, ry: 7 } as React.CSSProperties}
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle cx="269.8" cy="132.79" r="4.13" style={{ fill: '#e5e5e5' }} />
        </g>
      </g>
      <polygon
        points="118.94 173.82 349.85 223.39 350.06 661.03 118.94 744.27 118.94 173.82"
        style={{ fill: 'gray' }}
      />
    </svg>
  );
};
