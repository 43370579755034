export const Scratch2 = () => {
  return (
    <svg height={"auto"} id="Capa_1" viewBox="0 0 469 887" width={"100%"} xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <rect
            height="697.66"
            rx="33.8"
            ry="33.8"
            style={{ fill: '#e5e5e5' }}
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            style={{ fill: '#fff', stroke: '#ccc', strokeMiterlimit: 10 }}
          />
        </g>
        <g>
          <rect
            height="31.23"
            rx="7"
            ry="7"
            style={{ fill: '#fff' }}
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle cx="269.8" cy="132.79" r="4.13" style={{ fill: '#e5e5e5' }} />
        </g>
      </g>
      <rect
        height="506"
        style={{ fill: 'gray' }}
        width="278.83"
        x="95.27"
        y="190"
      />
      <rect
        height="163"
        style={{ fill: '#e5e5e5' }}
        width="163"
        x="153"
        y="465"
      />
      <path
        d="M178.74,576.45c.92-2.82,1.96-5.6,3.11-8.33l-.76,1.79c3.74-8.83,8.58-17.14,14.07-25,1.38-1.98,2.81-3.93,4.26-5.85.34-.45.68-.91,1.04-1.36-.06.08-1.14,1.47-.39.51.18-.22.35-.45.53-.67.79-1.01,1.59-2.01,2.4-3.01,3.01-3.72,6.13-7.35,9.31-10.92,3.64-4.1,7.37-8.12,11.12-12.13l-12.54-7.3c-3.55,15.36-7.12,30.75-12.15,45.71-4.75,14.12-10.59,27.99-12.64,42.84-.31,2.27-.73,4.63-.71,6.93.03,3.46,1.04,6.54,3.24,9.2,1.48,1.79,3.6,3.07,5.85,3.64,3.83.97,7.39-.49,10.29-2.93,2.69-2.26,5.15-4.84,7.6-7.35s4.95-5.19,7.33-7.87c9.73-11,18.45-22.97,25.81-35.68,4.42-7.62,8.36-15.5,12.43-23.31,1.9-3.64,3.77-7.32,5.88-10.85.51-.86,1.04-1.7,1.59-2.53.24-.35.48-.71.72-1.06.34-.49,1.66-1.84.09-.19,1.37-1.45,2.56-2.98,4.08-4.3l-12.54-7.3c-2.66,12.98-6.12,25.78-10.4,38.32-2.09,6.14-4.55,12.14-6.75,18.24-1.1,3.05-2.05,6.17-2.56,9.38-.61,3.85-.71,7.3-.17,11.14.99,7.03,4.95,13.7,11.13,17.35,1.6.94,3.98,1.34,5.78.76,1.97-.64,3.25-1.8,4.48-3.45,1.77-2.36,3.03-5.01,4.33-7.64s2.83-5.37,4.41-7.97c3.22-5.31,6.68-10.46,9.8-15.83,6.17-10.61,11.52-21.68,16.55-32.88,2.86-6.35,5.61-12.75,8.37-19.15.83-1.93,1.34-3.67.76-5.78-.48-1.74-1.82-3.63-3.45-4.48-1.74-.92-3.85-1.38-5.78-.76-1.73.56-3.73,1.7-4.48,3.45-4.89,11.36-9.78,22.72-15.23,33.82-2.68,5.47-5.5,10.88-8.52,16.18s-6.45,10.59-9.73,15.85c-1.83,2.93-3.6,5.91-5.16,9s-2.83,5.97-4.82,8.63l10.26-2.69c-.36-.21-.69-.44-1.04-.66-.66-.43,1.58,1.32.42.31-.57-.5-1.07-1.05-1.57-1.61-.89-1,1.03,1.55.33.41-.18-.3-.39-.58-.57-.88-.42-.71-.78-1.45-1.11-2.2l.76,1.79c-.73-1.78-1.21-3.62-1.46-5.53l.27,1.99c-.27-2.24-.2-4.46.1-6.69l-.27,1.99c.65-4.38,2.11-8.56,3.69-12.68s3.25-8.42,4.75-12.69c2.99-8.51,5.6-17.14,7.84-25.88,1.29-5.02,2.45-10.08,3.49-15.16.65-3.16-.4-6.86-3.45-8.47s-6.47-1.1-9.09,1.17c-5.93,5.15-9.65,12.35-13.25,19.19s-7.37,14.4-11.28,21.48c-3.65,6.62-7.61,13.06-11.92,19.27-1.04,1.5-2.11,2.99-3.19,4.47-.52.71-1.04,1.41-1.57,2.11-.26.35-.53.7-.8,1.05-.13.17-.27.35-.4.52-.58.76.96-1.23.37-.48-2.31,2.93-4.66,5.83-"
        style={{ fill: 'gray' }}
      />
      <g>
        <path
          d="M313.08,520.21l-13.54,6.15c-.94.43-2.02.4-2.94-.08-3.6-1.87-12.89-6.68-16.24-8.2-4.19-1.9-2.27-6.57,1.45-5.17s9.57,4.08,9.57,4.08c0,0-8.88-12.58-11.47-15.83-2.21-2.77.4-6.69,3.68-3.67,3.27,3.03,8.33,8.41,8.33,8.41,0,0-4.19-9.67-6.2-13.4-1.47-2.72,2.88-5.69,4.63-2.1s7.25,13.34,7.25,13.34c0,0-3.94-12.11-4.66-14.51-.73-2.4,3.59-4.02,5.12-.67,1.28,2.83,5.05,13.73,5.05,13.73,0,0-.86-10.28-.57-12.26.29-1.98,4.23-2.19,4.54.86.31,3.05,1.57,9.59,2.42,11.7,1.33,3.29,3.31,8.84,5.25,13.19.76,1.69,0,3.67-1.68,4.43Z"
          style={{ fill: 'gray' }}
        />
        <path
          d="M313.08,520.21l-13.54,6.15c-.94.43-2.02.4-2.94-.08-3.6-1.87-12.89-6.68-16.24-8.2-4.19-1.9-2.27-6.57,1.45-5.17s9.57,4.08,9.57,4.08c0,0-8.88-12.58-11.47-15.83-2.21-2.77.4-6.69,3.68-3.67,3.27,3.03,8.33,8.41,8.33,8.41,0,0-4.19-9.67-6.2-13.4-1.47-2.72,2.88-5.69,4.63-2.1s7.25,13.34,7.25,13.34c0,0-3.94-12.11-4.66-14.51-.73-2.4,3.59-4.02,5.12-.67,1.28,2.83,5.05,13.73,5.05,13.73,0,0-.86-10.28-.57-12.26.29-1.98,4.23-2.19,4.54.86.31,3.05,1.57,9.59,2.42,11.7,1.33,3.29,3.31,8.84,5.25,13.19.76,1.69,0,3.67-1.68,4.43Z"
          style={{ fill: 'gray' }}
        />
      </g>
    </svg>
  );
};
