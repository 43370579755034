import { Animation } from "../enum/animation.enum"
import { AnimationGroup } from "../types/animation"


export const animationConfig: Record<string, AnimationGroup> = {
  attentionSeekers: {
    id:    'attentionSeekers',
    items: [
      Animation.bounce,
      Animation.flash,
      Animation.pulse,
      Animation.rubberBand,
      Animation.shakeX,
      Animation.shakeY,
      Animation.headShake,
      Animation.swing,
      Animation.tada,
      Animation.wobble,
      Animation.jello,
      Animation.heartBeat
    ],
    title: 'Attention seekers'
  },
  backEntrances: {
    id:    'backEntrances',
    items: [
      Animation.backInDown,
      Animation.backInLeft,
      Animation.backInRight,
      Animation.backInUp
    ],
    title: 'Back entrances'
  },
  backExits: {
    id:    'backExits',
    items: [
      Animation.backOutDown,
      Animation.backOutLeft,
      Animation.backOutRight,
      Animation.backOutUp
    ],
    title: 'Back exits'
  },
  bouncingEntrances: {
    id:    'bouncingEntrances',
    items: [
      Animation.bounceIn,
      Animation.bounceInDown,
      Animation.bounceInLeft,
      Animation.bounceInRight,
      Animation.bounceInUp
    ],
    title: 'Bouncing entrances'
  },
  bouncingExits: {
    id:    'bouncingExits',
    items: [
      Animation.bounceOut,
      Animation.bounceOutDown,
      Animation.bounceOutLeft,
      Animation.bounceOutRight,
      Animation.bounceOutUp
    ],
    title: 'Bouncing exits'
  },
  fadingEntrances: {
    id:    'fadingEntrances',
    items: [
      Animation.fadeIn,
      Animation.fadeInDown,
      Animation.fadeInDownBig,
      Animation.fadeInLeft,
      Animation.fadeInLeftBig,
      Animation.fadeInRight,
      Animation.fadeInRightBig,
      Animation.fadeInUp,
      Animation.fadeInUpBig,
      Animation.fadeInTopLeft,
      Animation.fadeInTopRight,
      Animation.fadeInBottomLeft,
      Animation.fadeInBottomRight
    ],
    title: 'Fading entrances'
  },
  fadingExits: {
    id:    'fadingExits',
    items: [
      Animation.fadeOut,
      Animation.fadeOutDown,
      Animation.fadeOutDownBig,
      Animation.fadeOutLeft,
      Animation.fadeOutLeftBig,
      Animation.fadeOutRight,
      Animation.fadeOutRightBig,
      Animation.fadeOutUp,
      Animation.fadeOutUpBig,
      Animation.fadeOutTopLeft,
      Animation.fadeOutTopRight,
      Animation.fadeOutBottomRight,
      Animation.fadeOutBottomLeft
    ],
    title: 'Fading exits'
  },
  flippers: {
    id:    'flippers',
    items: [
      Animation.flip,
      Animation.flipInX,
      Animation.flipInY,
      Animation.flipOutX,
      Animation.flipOutY
    ],
    title: 'Flippers'
  },
  lightSpeed: {
    id:    'lightSpeed',
    items: [
      Animation.lightSpeedInRight,
      Animation.lightSpeedInLeft,
      Animation.lightSpeedOutRight,
      Animation.lightSpeedOutLeft
    ],
    title: 'Lightspeed'
  },
  rotatingEntrances: {
    id:    'rotatingEntrances',
    items: [
      Animation.rotateIn,
      Animation.rotateInDownLeft,
      Animation.rotateInDownRight,
      Animation.rotateInUpLeft,
      Animation.rotateInUpRight
    ],
    title: 'Rotating entrances'
  },
  rotatingExits: {
    id:    'rotatingExits',
    items: [
      Animation.rotateOut,
      Animation.rotateOutDownLeft,
      Animation.rotateOutDownRight,
      Animation.rotateOutUpLeft,
      Animation.rotateOutUpRight
    ],
    title: 'Rotating exits'
  },
  specials: {
    id:    'specials',
    items: [
      Animation.hinge,
      Animation.jackInTheBox,
      Animation.rollIn,
      Animation.rollOut
    ],
    title: 'Specials'
  },
  zoomingEntrances: {
    id:    'zoomingEntrances',
    items: [
      Animation.zoomIn,
      Animation.zoomInDown,
      Animation.zoomInLeft,
      Animation.zoomInRight,
      Animation.zoomInUp
    ],
    title: 'Zooming entrances'
  },
  zoomingExits: {
    id:    'zoomingExits',
    items: [
      Animation.zoomOut,
      Animation.zoomOutDown,
      Animation.zoomOutLeft,
      Animation.zoomOutRight,
      Animation.zoomOutUp
    ],
    title: 'Zooming exits'
  },
  slidingEntrances: {
    id:    'slidingEntrances',
    items: [
      Animation.slideInDown,
      Animation.slideInLeft,
      Animation.slideInRight,
      Animation.slideInUp
    ],
    title: 'Sliding entrances'
  },
  slidingExits: {
    id:    'slidingExits',
    items: [
      Animation.slideOutDown,
      Animation.slideOutLeft,
      Animation.slideOutRight,
      Animation.slideOutUp
    ],
    title: 'Sliding exits'
  }
}

export const listAnimationConfig = Object.values(animationConfig)
