import {
  LayerComponentProps,
  SerializedLayer,
  SerializedLayers,
} from '@lidojs/design-core';
import { generateComponent } from './generateComponent';
import { generateRedirectionHTML } from './generateRedirectionHTML';
import { generateStyles } from './generateStyles';

export const generateGroupLayerComponent = (layer: {
  component: SerializedLayer;
  zPosition: number;
  id: string;
  components: SerializedLayers;
  container: SerializedLayer;
}) => {
  const { component, zPosition, id, components, container } = layer;
  const { props } = component;
  const children = component.child;

  const styles = generateStyles(
    props as LayerComponentProps,
    zPosition,
    container
  );
  const redirectionHTML = generateRedirectionHTML(props.hyperlink);

  const childComponents = children.map((childId) => {
    const childComponent = components[childId];
    return generateComponent({
      component: childComponent,
      zPosition: zPosition + 1,
      id: childId,
      container,
      groupContainer: component,
    });
  });

  return `<div data-id="${id}"
            data-locked="${component.locked}"
            data-type="${component.type.resolvedName}"
            data-parent="${component.parent}"
            data-child='${JSON.stringify(component.child)}'
            ${redirectionHTML}
            style="${styles}">
              ${childComponents.join('')}
          </div>`;
};
