
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel2: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='83'
      style={style}
      viewBox='0 0 120 83'
      width='120'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='83' width='120' />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='9.5'
        y='24.5'
      />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='46.9121'
        y='24.5'
      />
      <rect
        fill='white'
        height='35'
        rx='4.5'
        stroke='#676767'
        width='35'
        x='84.3237'
        y='24.5'
      />
    </svg>
  )
}

export default CarouselModel2
