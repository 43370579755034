import {
  LayerId,
  SerializedLayer,
  SerializedLayers,
  SerializedPage,
} from '@lidojs/design-core';
import { XMobileFrameHTML } from '../htmlComponents';
import { mobileFrameStyles } from '../stylesToExport';
import { generateColector } from './colector';
import { generateDepthCard } from './depthCard';
import { generateFlipSlides } from './flipSlides';
import { generateFlipUp } from './flipUp';
import { generateRotate } from './rotate';
import { generateScratch } from './scratch';
import { generateVideoBox } from './videoBox';
import { generateVideoboxCarousel } from './videoboxCarousel';


export type PageExport = {page: SerializedLayers, hasMobileFrame: boolean, pages?: SerializedPage[]}
export type PageHandler = ({page, hasMobileFrame, pages}:  PageExport) => (Promise<string> | string);

const handlers: Record<string, PageHandler> = {
  depthCard: generateDepthCard,
  colector: generateColector,
  flipUp: generateFlipUp,
  rotate: generateRotate,
  scratch: generateScratch,
  flipSlides: generateFlipSlides,
  videobox: generateVideoBox,
  videoboxCarousel: generateVideoboxCarousel,
};

export const generatePremiumHTML = async ({
  page,
  hasMobileFrame,
  pages,
}: {
  page: Record<LayerId, SerializedLayer>;
  hasMobileFrame?: boolean;
  pages?: SerializedPage[];
}): Promise<string> => {
  const { ROOT: container } = page;

  const pageId = container.idPremium;
  const handler = await handlers[pageId];

  if (handler) {
    const htmlGenerated = await handler({page, hasMobileFrame, pages});
    return htmlParentPremium(htmlGenerated, hasMobileFrame);
  } else {
    throw new Error(`No handler found for page ID: ${pageId}`);
  }
};

const htmlParentPremium = (html: string, hasMobileFrame?: boolean): string => {
  return `
        <!DOCTYPE html>
  <html lang="es">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    <style>
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }
    </style>
    ${hasMobileFrame ? `<style>${mobileFrameStyles}</style>` : ''}
      ${html}
    <script>
      function redirectToURL(url, event) {
        event.stopPropagation();
        window.location.href = url;
      }

      function redirectClickTag(url, event) {
        event.stopPropagation();
        window.open(url, "_blank");
      }
    </script>
    </body>
  </html>
  `;
};

export const containerStyleSize = (hasMobileFrame: boolean) => {
  if (hasMobileFrame) {
    return `width: 100%; height: auto;`;
  }
  return `height: 100vh; width: auto;`;
};

export const containerPremium = (hasMobileFrame: boolean) => {
  return `
  <div style="width: 100%; height: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center">
    ${hasMobileFrame ? `<div class="mobile-preview">` : ''}
        <div
      data-locked="false"
      data-id="ROOT"
      data-type="RootLayer"
      data-child="[]"
      data-parent="null"
      id="ROOT"
      style="${containerStyleSize(hasMobileFrame)}
      aspect-ratio: 1080 / 1920;
      position: relative;
      overflow: hidden; background-color: transparent;">
      ${hasMobileFrame ? XMobileFrameHTML : ""}`
}
