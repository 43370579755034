
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel1: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='83'
      style={style}
      viewBox='0 0 92 83'
      width='92'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='83' width='92' />
      <rect
        fill='white'
        height='13.6321'
        rx='4.5'
        stroke='#676767'
        width='13.6321'
        x='8.5'
        y='59.8672'
      />
      <rect
        fill='white'
        height='13.6321'
        rx='4.5'
        stroke='#676767'
        width='13.6321'
        x='23.7061'
        y='59.8672'
      />
      <rect
        fill='white'
        height='13.6321'
        rx='4.5'
        stroke='#676767'
        width='13.6321'
        x='38.9121'
        y='59.8672'
      />
      <rect
        fill='white'
        height='13.6321'
        rx='4.5'
        stroke='#676767'
        width='13.6321'
        x='54.1182'
        y='59.8672'
      />
      <rect
        fill='white'
        height='13.6321'
        rx='4.5'
        stroke='#676767'
        width='13.6321'
        x='69.3232'
        y='59.8672'
      />
      <rect
        height='44.5741'
        rx='9.5'
        stroke='#676767'
        width='74.7335'
        x='8.5'
        y='11.5'
      />
    </svg>
  )
}

export default CarouselModel1
