import { Page } from "../types";

export function generatePageLabels(pages: Page[]) {
  let pageCounter = 1;
  const labels = pages.map((page, index) => {
    const isRelatesTo = page.layers.ROOT.data?.props?.relatesTo;

    if (isRelatesTo) {
      return {
        id: index.toString(),
        label: `Subpage ${pageCounter - 1}`,
      };
    } else {
      const label = `Page ${pageCounter}`;
      pageCounter++;
      return {
        id: index.toString(),
        label: label,
      };
    }
  });
  return labels;
}
