export const rawFormats = [
  { width: 1080, height: 1920 },
  { width: 1640, height: 924 },
  { width: 200, height: 200 },
  { width: 250, height: 250 },
  { width: 50, height: 50 },
  { width: 300, height: 1050 },
  { width: 160, height: 600 },
  { width: 120, height: 600 },
  { width: 320, height: 480 },
  { width: 970, height: 250 },
  { width: 300, height: 250 },
  { width: 300, height: 50 },
  { width: 320, height: 50 },
  { width: 728, height: 90 },
  { width: 970, height: 90 },
  { width: 120, height: 20 },
  { width: 168, height: 28 },
  { width: 216, height: 36 },
  { width: 480, height: 320 },
];

export function isDimensionValid(width: number, height: number): boolean {
  return rawFormats.some(format => format.width === width && format.height === height);
}
