import { htmlIcons } from './htmlIcons';

export const controlsVideo = (videoId: string, autoplay: boolean, muted: boolean) => `
<!-- Control Buttons -->
<div class="controls">
    <div class="control-button" onclick="togglePlayPause('${videoId}', event)">
        ${autoplay ? htmlIcons.pause('playPauseIcon'+videoId) : htmlIcons.play('playPauseIcon'+videoId)}
    </div>
    <div class="control-button" onclick="toggleMute('${videoId}', event)">
        ${muted ? htmlIcons.mute('muteIcon'+videoId) : htmlIcons.unmute('muteIcon'+videoId)}
    </div>
</div>
`;

export const JSControlsVideo = (videoId: string) => `
  <script>
    document.addEventListener('DOMContentLoaded', function() {
      const video = document.getElementById('${videoId}');
      const playPauseIcon = document.getElementById('playPauseIcon${videoId}');
      let manualToggle = false;

      if (video) {
        video.addEventListener('play', () => {
          if (!manualToggle && playPauseIcon) { // Solo cambia el icono si no se activó manualmente
            playPauseIcon.innerHTML = \`${htmlIcons.pause('playPauseIcon' + videoId)}\`;
          }
          manualToggle = false;
        });

        video.addEventListener('pause', () => {
          if (!manualToggle && playPauseIcon) {
            playPauseIcon.innerHTML = \`${htmlIcons.play('playPauseIcon' + videoId)}\`;
          }
          manualToggle = false;
        });
      }
    });

    function togglePlayPause(videoId, event) {
      event.stopPropagation();
      const video = document.getElementById(videoId);
      const playPauseIcon = document.getElementById('playPauseIcon' + videoId);

      manualToggle = true;
      if (video.paused) {
        video.play();
        if (playPauseIcon) {
          playPauseIcon.innerHTML = \`${htmlIcons.pause('playPauseIcon' + videoId)}\`;
        }
      } else {
        video.pause();
        if (playPauseIcon) {
          playPauseIcon.innerHTML = \`${htmlIcons.play('playPauseIcon' + videoId)}\`;
        }
      }
    }

    function toggleMute(videoId, event) {
      event.stopPropagation();
      const video = document.getElementById(videoId);
      const muteIcon = document.getElementById('muteIcon' + videoId);

      video.muted = !video.muted;
      if (muteIcon) {
        muteIcon.innerHTML = video.muted
          ? \`${htmlIcons.mute('muteIcon' + videoId)}\`
          : \`${htmlIcons.unmute('muteIcon' + videoId)}\`;
      }
    }
  </script>
`;

export const JSListenerVideo = `
  <script>
    window.addEventListener("message", (event) => {
        const action = event.data;
        const videos = document.querySelectorAll("video");

        if (action === "pause") {
            videos.forEach(video => video.pause());
        } else if (action === "mute") {
            videos.forEach(video => (video.muted = true));
        }
    });
  </script>
`

export const XMobileFrameHTML = `
  <figure style="position: absolute;
  top: 1%;
  right: 1.5%;
  z-index: 100;
  width: 7%;
  aspect-ratio: 1 / 1;
  height: auto;
  padding: 1%;
  border: 2px solid white;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;">
    <svg fill="#ffffff" width="100%" height="auto" version="1.1" id="Capa_1" viewBox="-46.08 -46.08 552.93 552.93" xml:space="preserve" stroke="#ffffff" stroke-width="0.00460775" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="5.529299999999999"></g><g id="SVGRepo_iconCarrier"> <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path> </g></svg>
  </figure>
`
