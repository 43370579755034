import { SerializedLayer, SerializedPage } from "@lidojs/design-core";
import { RootLayerProps } from "@lidojs/design-editor";
import { getRandomId } from "libs/design-editor/src/ultils/layer/layers";

export const generateLayerPremium = (data: SerializedPage, relatesToCreative?: string): SerializedPage => {
  const modifiedData = {
    ...data,
    layers: {
      ...data.layers,
      ROOT: {
        ...data.layers.ROOT,
        idCreative: getRandomId(),
        props: {
          ...data.layers.ROOT.props,
          ...(relatesToCreative
            ? { relatesTo: relatesToCreative }
            : {
                redirectCreative: {
                  active: (data.layers.ROOT.props as unknown as RootLayerProps).redirectCreative?.active,
                  idCreative: (data.layers.ROOT.props as unknown as RootLayerProps).redirectCreative?.active ? getRandomId() : null,
                },
              }),
        },
      },
    },
  };

  return modifiedData;
};
