export const FlipUp2 = () => {
  return (
    <svg height={"auto"} id="Capa_1" viewBox="0 0 469 887" width={"100%"} xmlns="http://www.w3.org/2000/svg">
      <rect
        height="10241"
        style={{ fill: 'none', stroke: 'red', strokeWidth: 6 }}
        width="3265"
        x="-1280"
        y="-4331"
      />
      <g>
        <g>
          <rect
            height="697.66"
            style={{ fill: '#e5e5e5', rx: 33.8, ry: 33.8 } as React.CSSProperties}
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            style={{ fill: '#fff', stroke: '#ccc', strokeMiterlimit: 10 }}
          />
        </g>
        <g>
          <rect
            height="31.23"
            style={{ fill: '#fff', rx: 7, ry: 7 } as React.CSSProperties}
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle
            cx="269.8"
            cy="132.79"
            r="4.13"
            style={{ fill: '#e5e5e5' }}
          />
        </g>
      </g>
      <g>
        <rect
          height="506"
          style={{ fill: 'gray' }}
          width="278.83"
          x="95.27"
          y="190"
        />
        <rect
          height="175"
          style={{ fill: '#4d4d4d' }}
          width="279.2"
          x="94.9"
          y="521"
        />
      </g>
      <g>
        <circle
          cx="234.5"
          cy="525.28"
          r="31.53"
          style={{ fill: '#fff' }}
        />
        <polygon
          points="255.11 518.23 235.84 537.49 216.45 518.1 219.56 514.99 235.84 531.27 252 515.11 255.11 518.23"
          style={{ fill: 'gray' }}
        />
      </g>
    </svg>
  )
}
