import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const DeleteIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 11 / 13)}
      style={style}
      viewBox='0 0 11 13'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.0625 13C1.68438 13 1.36068 12.8586 1.09141 12.5757C0.822135 12.2928 0.6875 11.9528 0.6875 11.5556V2.16667H0V0.722222H3.4375V0H7.5625V0.722222H11V2.16667H10.3125V11.5556C10.3125 11.9528 10.1779 12.2928 9.90859 12.5757C9.63932 12.8586 9.31563 13 8.9375 13H2.0625ZM8.9375 2.16667H2.0625V11.5556H8.9375V2.16667ZM3.4375 10.1111H4.8125V3.61111H3.4375V10.1111ZM6.1875 10.1111H7.5625V3.61111H6.1875V10.1111Z'
        fill='#676767'
      />
    </svg>
  )
}

export default DeleteIcon
