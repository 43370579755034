export const Scratch1 = () => {
  return (
    <svg height={"auto"} id="Capa_1" viewBox="0 0 469 887" width={"100%"} xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <rect
            height="697.66"
            rx="33.8"
            ry="33.8"
            style={{ fill: '#e5e5e5' }}
            width="341.06"
            x="64"
            y="95.01"
          />
          <path
            d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
            style={{ fill: '#fff', stroke: '#ccc', strokeMiterlimit: 10 }}
          />
        </g>
        <g>
          <rect
            height="31.23"
            rx="7"
            ry="7"
            style={{ fill: '#fff' }}
            width="97.24"
            x="185.91"
            y="117.18"
          />
          <circle cx="269.8" cy="132.79" r="4.13" style={{ fill: '#e5e5e5' }} />
        </g>
      </g>
      <rect
        height="506"
        style={{ fill: 'gray' }}
        width="278.83"
        x="95.27"
        y="190"
      />
      <rect
        height="163"
        style={{ fill: '#e5e5e5' }}
        width="163"
        x="153"
        y="465"
      />
      <g>
        <path
          d="M256.24,554.45l-13.54,6.15c-.94.43-2.02.4-2.94-.08-3.6-1.87-12.89-6.68-16.24-8.2-4.19-1.9-2.27-6.57,1.45-5.17s9.57,4.08,9.57,4.08c0,0-8.88-12.58-11.47-15.83-2.21-2.77.4-6.69,3.68-3.67,3.27,3.03,8.33,8.41,8.33,8.41,0,0-4.19-9.67-6.2-13.4-1.47-2.72,2.88-5.69,4.63-2.1s7.25,13.34,7.25,13.34c0,0-3.94-12.11-4.66-14.51-.73-2.4,3.59-4.02,5.12-.67,1.28,2.83,5.05,13.73,5.05,13.73,0,0-.86-10.28-.57-12.26.29-1.98,4.23-2.19,4.54.86.31,3.05,1.57,9.59,2.42,11.7,1.33,3.29,3.31,8.84,5.25,13.19.76,1.69,0,3.67-1.68,4.43Z"
          style={{ fill: 'gray' }}
        />
        <path
          d="M256.24,554.45l-13.54,6.15c-.94.43-2.02.4-2.94-.08-3.6-1.87-12.89-6.68-16.24-8.2-4.19-1.9-2.27-6.57,1.45-5.17s9.57,4.08,9.57,4.08c0,0-8.88-12.58-11.47-15.83-2.21-2.77.4-6.69,3.68-3.67,3.27,3.03,8.33,8.41,8.33,8.41,0,0-4.19-9.67-6.2-13.4-1.47-2.72,2.88-5.69,4.63-2.1s7.25,13.34,7.25,13.34c0,0-3.94-12.11-4.66-14.51-.73-2.4,3.59-4.02,5.12-.67,1.28,2.83,5.05,13.73,5.05,13.73,0,0-.86-10.28-.57-12.26.29-1.98,4.23-2.19,4.54.86.31,3.05,1.57,9.59,2.42,11.7,1.33,3.29,3.31,8.84,5.25,13.19.76,1.69,0,3.67-1.68,4.43Z"
          style={{ fill: 'gray' }}
        />
      </g>
    </svg>
  );
};
