import { SerializedLayers } from "@lidojs/design-core";

export function extractProps(data: SerializedLayers) {
  const result = {};

  Object.keys(data).forEach(key => {
      result[key] = data[key].props;
  });

  return result;
}
