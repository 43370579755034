import XIcon from '@duyank/icons/regular/X';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useEditor } from '../../hooks';
import { CarouselPremiumSettings } from './components/CarouselPremiumSettings';
import { ConfigPremiumettings } from './components/ConfigPremiumSettings';
import { LinePremiumSettings } from './components/LinePremiumSettings';
import { MediaPremiumSettings } from './components/MediaPremiumSettings';
import { TextPremiumSettings } from './components/TextPremiumSettings';
import Sidebar, { SidebarProps } from './Sidebar';

type PremiumSettingsSidebarProps = SidebarProps;
const PremiumSettingsSidebar: ForwardRefRenderFunction<
  HTMLDivElement,
  PremiumSettingsSidebarProps
> = ({ ...props }, ref) => {
  const { actions } = useEditor();

  return (
    <Sidebar ref={ref} {...props}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Premium Settings
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            actions.setSidebar();
          }}
        >
          <XIcon />
        </div>
      </div>
      <div css={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: 20 }}>
        <p css={{ marginBottom: 16 }}>
          These are the resources of the premium creative. To change them, click
          on each one to replace it.
        </p>
        <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
          <MediaPremiumSettings />
          <LinePremiumSettings />
        </div>
        <CarouselPremiumSettings />
        <TextPremiumSettings/>
        <ConfigPremiumettings/>
      </div>
    </Sidebar>
  );
};

export default forwardRef<HTMLDivElement, PremiumSettingsSidebarProps>(
  PremiumSettingsSidebar
);
