import { CarouselContent, CarouselContentProps } from '@lidojs/design-layers';
import React from 'react';
import { useEditor } from '../hooks';
import { LayerComponent } from '../types';
import 'swiper/css';

export type CarouselLayerProps = CarouselContentProps;

const CarouselLayer: LayerComponent<CarouselLayerProps> = ({
  layerId,
  boxSize,
  scale = 1,
  rotate,
  position,
  itemsCarousel,
  ...props
}) => {
  const { actions } = useEditor();

  return (
    <div css={{
      width: '100%',
      height: '100%',
    }} onClick={() => {
      actions.setSidebar('CAROUSEL_SETTINGS')
      actions.hideContextMenu();
    }}>
    <CarouselContent
      boxSize={boxSize}
      itemsCarousel={itemsCarousel}
      layerId={layerId}
      position={position}
      rotate={rotate}
      {...props}
    />
    </div>

  );
};

CarouselLayer.info = {
  name: 'Carousel',
  type: 'Carousel',
};
export default CarouselLayer;
