export const Videobox = () => {
  return(
    <svg height={"auto"} id="Capa_1" viewBox="0 0 469 887" width={"100%"} xmlns="http://www.w3.org/2000/svg">
    <defs />
    <rect
      height="10241"
      style={{ fill: 'none', stroke: 'red', strokeWidth: 6 }}
      width="3265"
      x="-517"
      y="-8939"
    />
    <g>
      <g>
        <rect
          height="697.66"
          style={{ fill: '#e5e5e5', rx: 33.8, ry: 33.8 } as React.CSSProperties}
          width="341.06"
          x="64"
          y="95.01"
        />
        <path
          d="M371.27,797.17H97.8c-21.12,0-38.3-17.18-38.3-38.3V128.81c0-21.12,17.18-38.3,38.3-38.3h273.47c21.12,0,38.3,17.18,38.3,38.3v630.06c0,21.12-17.18,38.3-38.3,38.3ZM97.8,99.52c-16.15,0-29.3,13.14-29.3,29.3v630.06c0,16.15,13.14,29.3,29.3,29.3h273.47c16.15,0,29.3-13.14,29.3-29.3V128.81c0-16.15-13.14-29.3-29.3-29.3H97.8Z"
          style={{ fill: '#ccc', stroke: '#ccc', strokeMiterlimit: 10 }}
        />
      </g>
      <g>
        <rect
          height="31.23"
          style={{ fill: '#fff', rx: 7, ry: 7 } as React.CSSProperties}
          width="97.24"
          x="185.91"
          y="117.18"
        />
        <circle
          cx="269.8"
          cy="132.79"
          r="4.13"
          style={{ fill: '#e5e5e5' }}
        />
      </g>
    </g>
    <g>
      <rect
        height="293.28"
        style={{ fill: 'gray' }}
        width="278.63"
        x="95.37"
        y="422.92"
      />
      <rect
        height="195.52"
        style={{ fill: '#4d4d4d' }}
        width="279"
        x="95"
        y="210.56"
      />
      <g>
        <polygon
          points="230.99 295.56 230.99 322.9 244.9 308.99 230.99 295.56"
          style={{ fill: '#fff' }}
        />
        <circle
          cx="234.68"
          cy="308.41"
          r="27.37"
          style={{ stroke: '#fff', fill: 'none', strokeMiterlimit: 10 }}
        />
      </g>
    </g>
  </svg>
  )
}
