
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel8: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='61'
      style={style}
      viewBox='0 0 113 61'
      width='113'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='61' width='113' />
      <rect height='34' rx='4.5' stroke='#676767' width='96' x='8.5' y='8.5' />
      <rect
        height='8'
        rx='1.5'
        stroke='#676767'
        width='31.01'
        x='8.5'
        y='45.5'
      />
      <rect
        height='8'
        rx='1.5'
        stroke='#676767'
        width='31.98'
        x='40.5098'
        y='45.5'
      />
      <rect
        height='8'
        rx='1.5'
        stroke='#676767'
        width='31.01'
        x='73.4902'
        y='45.5'
      />
    </svg>
  )
}

export default CarouselModel8
