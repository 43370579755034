import { FunctionComponent } from 'react';
import { IconProps } from '../Icon';

const MobileIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#000000',
  size = 24,
}) => {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      style={style}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          d="M11 17H13M9 21H15C16.6569 21 18 19.6569 18 18V6C18 4.34315 16.6569 3 15 3H9C7.34315 3 6 4.34315 6 6V18C6 19.6569 7.34315 21 9 21Z"
          stroke={color}
          stroke-linecap="round"
          stroke-width="1.6"
        ></path>{' '}
      </g>
    </svg>
  );
};

export default MobileIcon;
