import { sanitizeProps } from "./sanitizeProps";

export const extractJSProps = (doc: Document) => {
  // Encuentra todos los scripts en el documento
  const scripts = doc.querySelectorAll('script');

  for (const script of scripts) {
    const scriptContent = script.textContent;

    if (scriptContent && scriptContent.includes('const props')) {
      // Extrae la cadena que contiene los props
      const propsString = scriptContent.replace(/const\s+props\s*=\s*/, '').trim().replace(/;$/, '');

      try {
        const props = JSON.parse(propsString);
        const sanitizedProps = sanitizeProps(props);
        return sanitizedProps
      } catch (error) {
        console.error('Error al parsear los props:', error);
        return {};
      }
    }
  }

  return {};
};
