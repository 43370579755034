
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const PasteIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 12 / 13)}
      style={style}
      viewBox='0 0 12 13'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33333 13C0.966667 13 0.652778 12.8727 0.391667 12.6181C0.130556 12.3635 0 12.0575 0 11.7V2.6C0 2.2425 0.130556 1.93646 0.391667 1.68187C0.652778 1.42729 0.966667 1.3 1.33333 1.3H4.11667C4.23889 0.920833 4.47778 0.609375 4.83333 0.365625C5.18889 0.121875 5.57778 0 6 0C6.44444 0 6.84167 0.121875 7.19167 0.365625C7.54167 0.609375 7.77778 0.920833 7.9 1.3H10.6667C11.0333 1.3 11.3472 1.42729 11.6083 1.68187C11.8694 1.93646 12 2.2425 12 2.6V11.7C12 12.0575 11.8694 12.3635 11.6083 12.6181C11.3472 12.8727 11.0333 13 10.6667 13H1.33333ZM1.33333 11.7H10.6667V2.6H9.33333V4.55H2.66667V2.6H1.33333V11.7ZM6 2.6C6.18889 2.6 6.34722 2.53771 6.475 2.41312C6.60278 2.28854 6.66667 2.13417 6.66667 1.95C6.66667 1.76583 6.60278 1.61146 6.475 1.48687C6.34722 1.36229 6.18889 1.3 6 1.3C5.81111 1.3 5.65278 1.36229 5.525 1.48687C5.39722 1.61146 5.33333 1.76583 5.33333 1.95C5.33333 2.13417 5.39722 2.28854 5.525 2.41312C5.65278 2.53771 5.81111 2.6 6 2.6Z'
        fill='#676767'
      />
    </svg>
  )
}

export default PasteIcon
