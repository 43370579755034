
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const TyphographyIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 3 / 1)}
      style={style}
      viewBox='0 0 27 9'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.5125 9C20.1375 9 19.8313 8.88125 19.5938 8.64375C19.3563 8.40625 19.2375 8.1 19.2375 7.725V1.275C19.2375 0.9 19.3563 0.59375 19.5938 0.35625C19.8313 0.11875 20.1375 0 20.5125 0H25.725C26.1 0 26.4063 0.11875 26.6438 0.35625C26.8813 0.59375 27 0.9 27 1.275V3H25.2V1.8H21.0375V7.2H25.2V6H27V7.725C27 8.1 26.8813 8.40625 26.6438 8.64375C26.4063 8.88125 26.1 9 25.725 9H20.5125ZM9.75 9V0H16.2375C16.6125 0 16.9188 0.11875 17.1562 0.35625C17.3938 0.59375 17.5125 0.9 17.5125 1.275V3.225C17.5125 3.6 17.3938 3.90625 17.1562 4.14375C16.9188 4.38125 16.6125 4.5 16.2375 4.5C16.6125 4.5 16.9188 4.61875 17.1562 4.85625C17.3938 5.09375 17.5125 5.4 17.5125 5.775V7.725C17.5125 8.1 17.3938 8.40625 17.1562 8.64375C16.9188 8.88125 16.6125 9 16.2375 9H9.75ZM11.55 3.6H15.7125V1.8H11.55V3.6ZM11.55 7.2H15.7125V5.4H11.55V7.2ZM0 9V1.275C0 0.9 0.11875 0.59375 0.35625 0.35625C0.59375 0.11875 0.9 0 1.275 0H6.4875C6.8625 0 7.16875 0.11875 7.40625 0.35625C7.64375 0.59375 7.7625 0.9 7.7625 1.275V9H5.9625V6.1125H1.8V9H0ZM1.8 4.3125H5.9625V1.8H1.8V4.3125Z'
        fill='#676767'
      />
    </svg>
  )
}

export default TyphographyIcon
