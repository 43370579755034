
export const generatecarouselJS = (props: Record<string, unknown>, id: string) =>  {
  const { typeCarousel, loopCarousel, autoplayCarousel, speedCarousel } = props;
  const idShort = id.split("-")[0]
  switch (typeCarousel) {
    case "carousel1":
      return `<script>
    const swiper1${idShort} = new Swiper(".swiper1${idShort}", {
    spaceBetween: "1%",
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
  });
  const swiper2${idShort} = new Swiper(".swiper2${idShort}", {
    spaceBetween: "1%",
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
    thumbs: {
      swiper: swiper1${idShort},
    },
  });</script>`
    case "carousel2":
      return `<script>
    const swiper${idShort} = new Swiper(".swiper-${idShort}", {
      direction: "horizontal",
      loop: ${loopCarousel},
      speed: ${speedCarousel},
      autoplay: ${autoplayCarousel},
      slidesPerView: 3,
      spaceBetween: 6,
    });
  </script>`
    case "carousel3":
      return`<script>
    const swiper${idShort} = new Swiper(".swiper-${idShort}", {
      direction: "horizontal",
      loop: ${loopCarousel},
      speed: ${speedCarousel},
      autoplay: ${autoplayCarousel},
      slidesPerView: 1.25,
      centeredSlides: true
    });
  </script>`
    case "carousel4":
      return `'<script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js"></script>'`
    case "carousel5":
      return `<script>
    const swiper${idShort} = new Swiper(".swiper-${idShort}", {
      direction: "horizontal",
      loop: ${loopCarousel},
      speed: ${speedCarousel},
      autoplay: ${autoplayCarousel},
      slidesPerView: 3,
      spaceBetween: 8,
    });
  </script>`
    case "carousel6":
      return `<script>
    const swiper${idShort} = new Swiper(".swiper-${idShort}", {
    centeredSlides: true,
    slidesPerView: 1.35,
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
    spaceBetween: 6,
  });</script>`
  case "carousel7":
    return `<script>
    const swiper${idShort} = new Swiper(".swiper-${idShort}", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
    slidesPerView: 3,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  });</script>`
    default:
      return `<script>
    const swiper1${idShort} = new Swiper(".swiper1${idShort}", {
    spaceBetween: 6,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesProgress: true,
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
  });
  const swiper2${idShort} = new Swiper(".swiper2${idShort}", {
    spaceBetween: 6,
    thumbs: {
      swiper: swiper1${idShort},
    },
    loop: ${loopCarousel},
    speed: ${speedCarousel},
    autoplay: ${autoplayCarousel},
  });</script>`
  }



}
