import { LayerComponentProps } from '@lidojs/design-core';

export const generateClasses = (
  props: LayerComponentProps,
): string => {
  const { animation } = props as LayerComponentProps;

  const classes = animation ? `animate__animated animate__${props.animation}` : ''
  return classes;
};
