
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const AppleIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 22 / 26)}
      style={style}
      viewBox='0 0 22 26'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.9785 13.7487C17.9911 12.7722 18.2505 11.8148 18.7325 10.9654C19.2146 10.1161 19.9036 9.40251 20.7355 8.89104C20.207 8.13624 19.5098 7.51507 18.6992 7.07687C17.8887 6.63867 16.9871 6.3955 16.0661 6.36668C14.1015 6.16047 12.1969 7.54228 11.1958 7.54228C10.1753 7.54228 8.63386 6.38716 6.97403 6.42131C5.9004 6.456 4.85407 6.7682 3.93698 7.3275C3.01988 7.8868 2.26331 8.67412 1.74097 9.61276C-0.521698 13.5302 1.16605 19.2876 3.33349 22.4541C4.41791 24.0047 5.68529 25.7368 7.34362 25.6753C8.9664 25.608 9.57248 24.6405 11.5313 24.6405C13.472 24.6405 14.0406 25.6753 15.7326 25.6363C17.4739 25.608 18.571 24.0788 19.6174 22.5135C20.3965 21.4087 20.9961 20.1876 21.3938 18.8955C20.3822 18.4676 19.5189 17.7514 18.9115 16.8362C18.3042 15.9209 17.9797 14.8471 17.9785 13.7487Z'
        fill='white'
      />
      <path
        d='M14.7827 4.28499C15.7321 3.14523 16.1998 1.68025 16.0866 0.201172C14.636 0.353522 13.2962 1.04678 12.3339 2.14282C11.8634 2.67826 11.5031 3.30118 11.2735 3.97597C11.0439 4.65075 10.9495 5.36418 10.9958 6.07545C11.7214 6.08292 12.4391 5.92566 13.0951 5.61553C13.751 5.30539 14.328 4.85046 14.7827 4.28499Z'
        fill='white'
      />
    </svg>
  )
}

export default AppleIcon
