
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const ItalicIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='12'
      style={style}
      viewBox='0 0 6 12'
      width='6'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.51494 2.376C4.4296 2.89867 4.3336 3.45867 4.22694 4.056C4.13094 4.64267 4.03494 5.22933 3.93894 5.816C3.8536 6.40267 3.76827 6.952 3.68294 7.464C3.60827 7.976 3.5496 8.41333 3.50694 8.776C3.46427 9.128 3.44294 9.37333 3.44294 9.512C3.44294 9.768 3.4856 9.944 3.57094 10.04C3.66694 10.136 3.81094 10.184 4.00294 10.184C4.1736 10.184 4.33894 10.1787 4.49894 10.168C4.6696 10.1467 4.75494 10.136 4.75494 10.136L4.80294 10.216L4.56294 11.048C4.56294 11.048 4.48294 11.0427 4.32294 11.032C4.1736 11.032 3.98694 11.0267 3.76294 11.016C3.53894 11.016 3.30427 11.0107 3.05894 11C2.82427 11 2.61627 11 2.43494 11C2.23227 11 2.0136 11.0107 1.77894 11.032C1.55494 11.0533 1.34694 11.0693 1.15494 11.08C0.962938 11.1013 0.802938 11.1227 0.674938 11.144C0.546938 11.1653 0.482938 11.176 0.482938 11.176L0.706938 10.344C1.18694 10.248 1.5176 10.1093 1.69894 9.928C1.89094 9.736 2.00827 9.416 2.05094 8.968L2.86694 2.44C2.9096 2.09867 2.89894 1.86933 2.83494 1.752C2.77094 1.63467 2.62694 1.576 2.40294 1.576C2.29627 1.576 2.16827 1.58133 2.01894 1.592C1.8696 1.60267 1.79494 1.608 1.79494 1.608C1.79494 1.59733 1.7896 1.58667 1.77894 1.576C1.76827 1.56533 1.76294 1.54933 1.76294 1.528L1.97094 0.743999C2.02427 0.743999 2.16294 0.749333 2.38694 0.759999C2.61094 0.759999 2.86694 0.765333 3.15494 0.775999C3.4536 0.786666 3.7096 0.792 3.92294 0.792C4.10427 0.792 4.3016 0.786666 4.51494 0.775999C4.73894 0.754666 4.95227 0.738666 5.15494 0.728C5.3576 0.706666 5.52827 0.690666 5.66694 0.679999C5.8056 0.658666 5.87494 0.648 5.87494 0.648L5.68294 1.48C5.2776 1.53333 4.99494 1.61867 4.83494 1.736C4.6856 1.85333 4.57894 2.06667 4.51494 2.376Z'
        fill='#676767'
      />
    </svg>
  )
}

export default ItalicIcon
