import { CarouselContentProps, ICarouselItem } from "@lidojs/design-layers"
import { generateRedirectionHTML } from './generateRedirectionHTML';

export const generateSlide = (props: CarouselContentProps, urlImage: string, item: ICarouselItem) => {
  const { typeCarousel, objectFit } = props;
  const redirectLink = generateRedirectionHTML(item);
  if (typeCarousel === 'carousel4') {
    return `<swiper-slide style="
    display: flex;
    ${redirectLink}
    class="carousel-item"
    justify-content: center;
    align-items: center;">      <img
    src=${urlImage}
    width="100%"
    height="100%"
    style="display: block; border-radius: 6px; object-fit: ${objectFit};"
    class="carousel-img"

    alt=""
  /></swiper-slide>`
  }
  return `
  <div class="swiper-slide" ${redirectLink}>
    <div
      style="width: 100%; height: 100%"
      id="swiper-item"
      class="carousel-item"
    >
      <img
        src=${urlImage}
        width="100%"
        height="100%"
        style="display: block; border-radius: 6px; object-fit: ${objectFit}"
        class="carousel-img"
        alt=""
      />
    </div>
  </div>`
}
