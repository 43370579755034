export const getImageUrl = (original?: string | Blob, url?: string | Blob): string | undefined => {
  if (typeof original === 'string') {
    return original;
  }

  if (original instanceof Blob) {
    return URL.createObjectURL(original);
  }

  if (typeof url === 'string') {
    return url;
  }

  if (url instanceof Blob) {
    return URL.createObjectURL(url);
  }

  return undefined;
};
