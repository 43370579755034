import React, { useState, useEffect } from 'react';

interface ImageSwitcherProps {
  mainImage: JSX.Element;
  images: JSX.Element[];
  title?: string;
}

const ImageSwitcher: React.FC<ImageSwitcherProps> = ({ mainImage, images, title }) => {
  const [hovered, setHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showMainImage, setShowMainImage] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (hovered && images.length > 0) {
      interval = setInterval(() => {
        setShowMainImage((prev) => !prev);
        if (showMainImage) {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
      }, 300);
    } else {
      setCurrentImageIndex(0);
      setShowMainImage(true);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [hovered, images.length, showMainImage]);

  return (
    <div
      css={{
        display: 'inline-block',
        paddingBottom: 16,
        borderRadius: 8,
        border: '1px solid #e5e7eb',
        ':hover': {
          borderColor: '#000000',
        },
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        css={{
          transition: 'opacity 0.5s ease',
          display: showMainImage ? 'block' : 'none',
        }}
      >
        {mainImage}
      </div>
      {images.length > 0 && (
        <div
          css={{
            transition: 'opacity 0.5s ease',
            display: !showMainImage ? 'block' : 'none',
          }}
        >
          {images[currentImageIndex]}
        </div>
      )}
      {title && (
        <p css={{ textAlign: 'center' }}>{title}</p>
      )}
    </div>
  );
};

export default ImageSwitcher;
