
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const UnderlinedIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='15'
      style={style}
      viewBox='0 0 13 15'
      width='13'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.50006 12.096C5.07073 12.096 3.9454 11.6853 3.12406 10.864C2.30273 10.0427 1.89206 8.82667 1.89206 7.216V0.799999H3.07606V7.168C3.07606 8.49067 3.37473 9.46667 3.97206 10.096C4.5694 10.7253 5.41206 11.04 6.50006 11.04C7.59873 11.04 8.44673 10.7253 9.04406 10.096C9.6414 9.46667 9.94006 8.49067 9.94006 7.168V0.799999H11.0921V7.216C11.0921 8.82667 10.6814 10.0427 9.86006 10.864C9.0494 11.6853 7.9294 12.096 6.50006 12.096Z'
        fill='#676767'
      />
      <path d='M0.164062 14H12.8361V14.8H0.164062V14Z' fill='#676767' />
    </svg>
  )
}

export default UnderlinedIcon
