import CheckIcon from '@duyank/icons/regular/Check';
import XIcon from '@duyank/icons/regular/X';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  useRef,
} from 'react';
import { listAnimationConfig } from '../../config/animation.config';
import { useEditor, useSelectedLayers } from '../../hooks';
import Sidebar, { SidebarProps } from './Sidebar';

type AnimationSidebarProps = SidebarProps;
const AnimationSidebar: ForwardRefRenderFunction<
  HTMLDivElement,
  AnimationSidebarProps
> = ({ ...props }, ref) => {
  const { selectedLayers, selectedLayerIds } = useSelectedLayers();
  const { actions, activePage } = useEditor((state) => ({
    activePage: state.activePage,
  }));


  const scrollRef = useRef<HTMLDivElement>(null);

  const selectedLayer = selectedLayers[0];

  const currentAnimation = selectedLayer?.data?.props?.animation;

  const onChangeAnimation = (value: string) => {
    const selectedAnimation = value === currentAnimation ? null : value;
    actions.setProp(activePage, selectedLayerIds[0], {
      animation: selectedAnimation,
    });
  }

  return (
    <Sidebar {...props}>
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            height: 48,
            borderBottom: '1px solid rgba(57,76,96,.15)',
            padding: '0 20px',
          }}
        >
          <p
            css={{
              lineHeight: '48px',
              fontWeight: 600,
              color: '#181C32',
              flexGrow: 1,
            }}
          >
            Animation
          </p>
          <div
            css={{
              fontSize: 20,
              flexShrink: 0,
              width: 32,
              height: 32,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              actions.setSidebar();
            }}
          >
            <XIcon />
          </div>
        </div>
        <div ref={scrollRef} css={{ flexGrow: 1, overflowY: 'auto' }}>
          <div css={{ borderTop: '1px solid rgba(217, 219, 228, 0.6)' }}>
            {listAnimationConfig.map((animation) => (
              <Fragment key={animation.id}>
                <div css={{ padding: '16px 20px', fontWeight: 700 }}>
                  {animation.title}
                </div>
                {animation.items.map((item) => (
                  <div
                    key={item}
                    css={{
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      padding: '0 24px',
                      ':hover': {
                        background: '#F9F9F9',
                      },
                    }}
                    onClick={() => onChangeAnimation(item)}
                  >
                    <span>{item}</span>
                    {item === currentAnimation && (
                      <span>
                        <CheckIcon />
                      </span>
                    )}
                  </div>
                ))}


              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default forwardRef<HTMLDivElement, AnimationSidebarProps>(
  AnimationSidebar
);
