
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel7: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='105'
      style={style}
      viewBox='0 0 126 105'
      width='126'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='105' width='126' />
      <rect
        fill='white'
        height='51'
        rx='4.5'
        stroke='#676767'
        width='51'
        x='37.5'
        y='26.5'
      />
      <path d='M93.5 37.5L116 15V91L93.5 68.5V37.5Z' stroke='#676767' />
      <path d='M31.5 37.5L9 15V91L31.5 68.5V37.5Z' stroke='#676767' />
    </svg>
  )
}

export default CarouselModel7
