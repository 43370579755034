import { LayerComponentProps } from '@lidojs/design-core';
import { fetchSvgContent } from '@lidojs/design-utils';
import React, { FC, useState } from 'react';
import { useAsync } from 'react-use';
import { applyColorsToSvg } from '../utils/applyColorsToSvg';

export interface SvgContentProps extends LayerComponentProps {
  image: string;
  colors: string[];
}

export const SvgContent: FC<SvgContentProps> = ({ image, boxSize, colors }) => {
  const [url, setUrl] = useState<string>();
  useAsync(async () => {
    const ele = await fetchSvgContent(image);
    const formatSVG = applyColorsToSvg(ele, colors);

    const svgBlob = new Blob([formatSVG.outerHTML], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    setUrl(svgUrl);
  }, [image, colors]);

  return (
    <div css={{ width: '100%', height: '100%' }}>
      {image && (
        <div
          css={{
            width: boxSize.width,
            height: boxSize.height,
            position: 'relative',
            userSelect: 'none',
          }}
        >
          <img
            alt={url}
            crossOrigin={'anonymous'}
            css={{
              objectFit: 'fill',
              width: '100%',
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
            }}
            src={url}
          />
        </div>
      )}
    </div>
  );
};
