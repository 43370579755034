
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const LinkedinIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#D8D8D8'
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 40 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_818_1463)'>
        <path
          d='M18.7504 0C19.5804 0 20.4204 0 21.2504 0C21.3504 0.02 21.4504 0.06 21.5504 0.06C24.6604 0.29 27.5804 1.19 30.2404 2.82C35.4004 6 38.5804 10.57 39.7004 16.55C39.8404 17.28 39.9104 18.01 40.0104 18.75V21.25C39.9504 21.73 39.9004 22.2 39.8304 22.68C39.0404 28.05 36.5404 32.46 32.2604 35.79C27.8704 39.21 22.8504 40.53 17.3404 39.82C12.6304 39.21 8.61038 37.13 5.39038 33.63C1.12038 29 -0.599622 23.52 0.180377 17.3C0.680377 13.31 2.32038 9.79 5.00038 6.78C8.11038 3.28 11.9704 1.11 16.5904 0.3C17.3104 0.18 18.0304 0.1 18.7504 0ZM31.7304 30.22C31.7304 30.08 31.7504 29.98 31.7504 29.88C31.7504 27.2 31.7504 24.52 31.7504 21.84C31.7504 21.31 31.7104 20.77 31.6404 20.25C31.2804 17.4 29.5804 15.6 26.8204 15.29C24.8304 15.06 23.1404 15.66 21.9604 17.37C21.9204 17.43 21.8704 17.47 21.7504 17.61V15.62H16.9104V30.23H21.7604V29.74C21.7604 27.75 21.7604 25.76 21.7604 23.77C21.7604 23.04 21.7604 22.31 21.7904 21.58C21.8304 20.7 22.2804 20.02 22.9804 19.53C24.3904 18.55 26.2004 19.18 26.6604 20.82C26.8104 21.36 26.8604 21.95 26.8704 22.51C26.8904 24.94 26.8704 27.38 26.8704 29.81C26.8704 29.95 26.8704 30.09 26.8704 30.23H31.7204L31.7304 30.22ZM9.34038 15.57V30.21H14.1904V29.72C14.1904 25.27 14.1904 20.82 14.1904 16.36C14.1904 16.22 14.1804 16.07 14.1904 15.93C14.2304 15.63 14.1004 15.56 13.8104 15.56C12.5104 15.57 11.2104 15.56 9.90038 15.56C9.72038 15.56 9.54038 15.56 9.33038 15.56L9.34038 15.57ZM9.03038 11.05C9.03038 12.53 10.1604 13.59 11.7504 13.59C13.3504 13.59 14.5104 12.53 14.5104 11.08C14.5104 9.6 13.3804 8.53 11.8004 8.53C10.1704 8.53 9.04038 9.57 9.04038 11.06L9.03038 11.05Z'
          fill={color}
        />
        <path
          d='M31.7299 30.2195H26.8799C26.8799 30.0695 26.8799 29.9295 26.8799 29.7995C26.8799 27.3695 26.8899 24.9295 26.8799 22.4995C26.8799 21.9295 26.8199 21.3495 26.6699 20.8095C26.2099 19.1695 24.3899 18.5495 22.9899 19.5195C22.2799 20.0095 21.8399 20.6895 21.7999 21.5695C21.7699 22.2995 21.7699 23.0295 21.7699 23.7595C21.7699 25.7495 21.7699 27.7395 21.7699 29.7295V30.2195H16.9199V15.6095H21.7599V17.5995C21.8799 17.4695 21.9299 17.4195 21.9699 17.3595C23.1599 15.6495 24.8399 15.0495 26.8299 15.2795C29.5799 15.5995 31.2899 17.3895 31.6499 20.2395C31.7199 20.7695 31.7499 21.2995 31.7599 21.8295C31.7699 24.5095 31.7599 27.1895 31.7599 29.8695C31.7599 29.9695 31.7499 30.0695 31.7399 30.2095L31.7299 30.2195Z'
          fill='white'
        />
        <path
          d='M9.33984 15.5703C9.54984 15.5703 9.72984 15.5703 9.90984 15.5703C11.2098 15.5703 12.5098 15.5703 13.8198 15.5703C14.0998 15.5703 14.2398 15.6403 14.1998 15.9403C14.1798 16.0803 14.1998 16.2303 14.1998 16.3703C14.1998 20.8203 14.1998 25.2703 14.1998 29.7303V30.2203H9.34984V15.5803L9.33984 15.5703Z'
          fill='white'
        />
        <path
          d='M9.02953 11.0495C9.02953 9.55953 10.1695 8.51953 11.7895 8.51953C13.3695 8.51953 14.4995 9.58953 14.4995 11.0695C14.4995 12.5195 13.3395 13.5795 11.7395 13.5795C10.1595 13.5795 9.01953 12.5295 9.01953 11.0395L9.02953 11.0495Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_818_1463'>
          <rect fill='white' height={40} width={40} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedinIcon
