interface Filters {
  brightness?: number;
  contrast?: number;
  saturation?: number;
  hueRotate?: number;
  grayscale?: number;
  blur?: number;
}

export function generateFilters(filters: Filters): string {
  const filterMap: { [key: string]: string } = {
    brightness: filters.brightness ? `brightness(${(filters.brightness / 100) * 300}%)` : '',
    contrast: filters.contrast ? `contrast(${(filters.contrast / 100) * 200}%)` : '',
    saturation: (filters.saturation) ? `saturate(${(filters.saturation/ 100) * 200}%)` : '',
    hueRotate: filters.hueRotate ? `hue-rotate(${filters.hueRotate}deg)` : '',
    grayscale: filters.grayscale ? `grayscale(${filters.grayscale}%)` : '',
    blur: filters.blur ? `blur(${filters.blur}px)` : ''
  };

  return Object.values(filterMap).filter(Boolean).join(' ');
}
