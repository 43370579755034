import { Color } from '@lidojs/design-utils';
import React from 'react';
import { useEditor } from '../../../hooks';
import { LineLayerProps } from '../../../layers';
import ColorPickerPopover from '../ColorPickerPopover';

export const LinePremiumSettings = () => {
  const { actions, activePage, pages } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
  }));

  const onChangeLine = (id: string, color: string) => {
    actions.setProp<LineLayerProps>(activePage, id, {
      color: new Color(color).toRgbString(),
    });
  };

  const currentPage = pages[activePage].layers;
  const { ROOT, ...components } = currentPage;

  const lineComponentes = Object.values(components).filter(
    (component) => component.data.type === 'Line'
  );

  if (!lineComponentes.length) return null;

  return lineComponentes.map((line, index) => (
    <div key={line.id}>
      <p
        css={{
          fontWeight: 'bolder',
          marginTop: 24,
          textAlign: 'center',
        }}
      >
        Line {index + 1}
      </p>
      <p css={{ marginBottom: 8, textAlign: 'center' }}>Color</p>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <ColorPickerPopover
          key={line.id}
          color={(line.data.props as LineLayerProps).color}
          event={'click'}
          onChange={(color) => onChangeLine(line.id, color)}
        >
          <div
            className="w-14 h-14 border rounded-full"
            style={{
              backgroundColor: (line.data.props as LineLayerProps).color,
            }}
          ></div>
        </ColorPickerPopover>
      </div>
    </div>
  ));
};
