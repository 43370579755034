import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const FacebookIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#D8D8D8'
}) => {
  return (
    <svg
      className={className}
      fill='none'
      style={style}
      viewBox='0 0 40 41'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.75 0C19.58 0 20.42 0 21.25 0C21.73 0.06 22.2 0.11 22.68 0.18C26.97 0.79 30.74 2.56 33.83 5.64C39.03 10.83 41 17.14 39.55 24.37C38.31 30.55 34.74 35.1 29.26 38.11C28.09 38.75 26.86 39.24 25.57 39.6V24.66H29.84C30.06 22.98 30.27 21.32 30.49 19.6H25.57C25.57 18.25 25.51 16.95 25.59 15.66C25.64 14.7 26.17 14.17 27.11 14.03C27.83 13.92 28.56 13.95 29.29 13.94C29.73 13.93 30.17 13.94 30.61 13.94V9.39C29.67 9.34 28.75 9.24 27.83 9.25C26.87 9.25 25.89 9.25 24.96 9.45C22.26 10.04 20.55 12.17 20.4 15.02C20.33 16.38 20.36 17.75 20.35 19.12C20.35 19.27 20.35 19.43 20.35 19.63H16.09V24.7H20.32V40.38C20.25 40.39 20.21 40.4 20.18 40.4C20.08 40.4 19.97 40.4 19.87 40.4C16.98 40.37 14.2 39.8 11.6 38.52C5.41 35.48 1.64 30.54 0.31 23.72C0.17 22.98 0.1 22.22 0 21.46C0 20.62 0 19.78 0 18.94C0.03 18.68 0.07 18.42 0.09 18.16C0.56 13.79 2.22 9.95 5.13 6.69C8.23 3.25 12.05 1.1 16.59 0.3C17.31 0.18 18.03 0.1 18.75 0Z'
        fill={color}
      />
    </svg>
  )
}

export default FacebookIcon
