import { SerializedPage } from '@lidojs/design-core';
import { uploadFile } from '../services/upload/api';
import { generateHTML } from './export/generateHTML';

export const downloadObjectAsJson = (exportName: string, data: unknown) => {
  const dataStr =
    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + '.json');
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadObjectAsHTML = async (page: SerializedPage, pages:SerializedPage[]) => {
  const html = await generateHTML({ page, pages });
  const blob = new Blob([html], { type: 'text/html' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'creative.html';
  link.click();
}

export const uploadPageHTMLAndGetURL = async ({page, hasMobileFrame, pages}: {
  page: SerializedPage,
  hasMobileFrame?: boolean,
  pages?: SerializedPage[]
}
): Promise<string> => {
  const html = await generateHTML({page, hasMobileFrame, pages});
  const fileURL = await uploadHTMLAndGetURLs(html);
  return fileURL;
};

export const uploadHTMLAndGetURLs = async (html:string) => {
  const blob = new Blob([html], { type: 'text/html' });
  const file = new File([blob], 'creative.html', { type: 'text/html' });
  const fileURL = await uploadFile(file);
  return fileURL;
};
