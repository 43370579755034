import DotsThreeBoldIcon from '@duyank/icons/bold/DotsThreeBold';
import DuplicateIcon from '@duyank/icons/external/Duplicate';
import LockKeyIcon from '@duyank/icons/regular/LockKey';
import TrashIcon from '@duyank/icons/regular/Trash';
import { boundingRect, PageContext } from '@lidojs/design-core';
import React, { Fragment, useContext, useMemo, useRef } from 'react';
import { useEditor, useSelectedLayers } from '../hooks';
import { isGroupLayer } from '../ultils/layer/layers';
import { duplicate } from '../ultils/menu-actions/duplicate';

const Toolbar = () => {
  const { pageIndex } = useContext(PageContext);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { selectedLayerIds, selectedLayers } = useSelectedLayers();
  const {
    actions,
    state,
    isDragging,
    isResizing,
    isRotating,
    controlBox,
    pageSize,
    isOpenMenu,
    scale,
    isPageLocked,
    isUpdatingLine,
  } = useEditor((state, query) => ({
    isGroup: state.selectedLayers[state.activePage].length > 1,
    isDragging: state.dragData.status,
    isResizing: state.resizeData.status,
    isRotating: state.rotateData.status,
    isUpdatingLine: state.updateLineData.status,
    controlBox: state.controlBox,
    pageSize: query.getPageSize(),
    isPageLocked: state.pages[state.activePage].layers.ROOT.data.locked,
    isPremium: state.pages[state.activePage].layers.ROOT.data?.isPremium,
    isOpenMenu: !!state.openMenu,
    scale: state.scale,
  }));
  const isLocked = selectedLayers.find((i) => i.data.locked);
  const boundingBoxRect = useMemo(() => {
    if (!controlBox) {
      return {
        x: 0,
        y: 80,
        width: pageSize.width,
        height: pageSize.height,
      };
    }
    return boundingRect(
      controlBox.boxSize,
      controlBox.position,
      controlBox.rotate
    );
  }, [controlBox, pageSize.height, pageSize.width]);
  const handleDuplicate = () => {
    duplicate(state, { pageIndex, layerIds: selectedLayerIds, actions });
  };
  const showContextMenu = () => {
    if (isOpenMenu) {
      actions.hideContextMenu();
    } else {
      const rect = toolbarRef.current?.getBoundingClientRect() as DOMRect;
      actions.showContextMenu({
        clientX: rect.right - 42,
        clientY: rect.bottom + 4,
      });
    }
  };

  const handleUngroup = () => {
    if (selectedLayerIds.length === 1) {
      actions.ungroup(selectedLayerIds[0]);
    }
  };

  const handleGroup = () => {
    actions.group(selectedLayerIds);
  };
  if (
    isDragging ||
    isResizing ||
    isRotating ||
    isUpdatingLine ||
    (selectedLayerIds.includes('ROOT') && !isLocked && !isPageLocked) ||
    !controlBox
  ) {
    return null;
  }
  const containerGroupLayer = !!selectedLayers.find((l) => isGroupLayer(l));
  return (
    <div
      ref={toolbarRef}
      css={{
        position: 'absolute',
        left: (boundingBoxRect.x + boundingBoxRect.width / 2) * scale,
        top: boundingBoxRect.y * scale - 60,
        transform: 'translateX(-50%)',
      }}
    >
      <div
        css={{
          height: 40,
          borderRadius: 4,
          padding: '0 4px',
          display: 'inline-flex',
          alignItems: 'center',
          background: '#fff',
          boxShadow:
            '0 0 0 1px rgba(64,87,109,.07),0 2px 12px rgba(53,71,90,.2)',
          overflow: 'hidden',
          pointerEvents: 'auto',
          color: '#0d1216',
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          css={{
            alignItems: 'center',
            display: 'flex',
            whiteSpace: 'nowrap',
          }}
        >
          {!isPageLocked && !isLocked && !selectedLayerIds.includes('ROOT') && (
            <Fragment>
              {selectedLayerIds.length > 1 && (
                <div
                  css={{
                    lineHeight: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontSize: 14,
                    padding: '0 8px',
                    fontWeight: 700,
                    ':hover': {
                      backgroundColor: 'rgba(64,87,109,.07)',
                    },
                  }}
                  onClick={handleGroup}
                >
                  Group
                </div>
              )}
              {containerGroupLayer && (
                <div
                  css={{
                    lineHeight: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontSize: 14,
                    padding: '0 8px',
                    fontWeight: 700,
                    ':hover': {
                      backgroundColor: 'rgba(64,87,109,.07)',
                    },
                  }}
                  onClick={handleUngroup}
                >
                  Ungroup
                </div>
              )}
              <div
                css={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: 24,
                  ':hover': {
                    backgroundColor: 'rgba(64,87,109,.07)',
                  },
                }}
                onClick={handleDuplicate}
              >
                <DuplicateIcon />
              </div>

              <div
                css={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: 24,
                  ':hover': {
                    backgroundColor: 'rgba(64,87,109,.07)',
                  },
                }}
                onClick={() => actions.deleteLayer(pageIndex, selectedLayerIds)}
              >
                <TrashIcon />
              </div>

              <div
                css={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: 24,
                  ':hover': {
                    backgroundColor: 'rgba(64,87,109,.07)',
                  },
                }}
                onClick={showContextMenu}
              >
                <DotsThreeBoldIcon />
              </div>
            </Fragment>
          )}
          {(isLocked || isPageLocked) && (
            <div
              css={{
                width: 32,
                height: 32,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: 24,
                color: isPageLocked ? 'rgba(36,49,61,.4)' : undefined,
                ':hover': {
                  backgroundColor: isPageLocked
                    ? undefined
                    : 'rgba(64,87,109,.07)',
                },
              }}
              onClick={() => {
                actions.unlock(pageIndex, selectedLayerIds);
              }}
            >
              <LockKeyIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Toolbar);
