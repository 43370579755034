import { LayerComponentProps } from '@lidojs/design-core';
import { FunctionComponent } from 'react';
import 'swiper/css';
import { ObjectFit } from '../enum/objectFit.enum';
import { Carousel1, Carousel2, Carousel3, Carousel4, Carousel5, Carousel6, Carousel7, Carousel8, Carousel9 } from './carousel/carouselTypes';

export interface ICarouselItem{
  id: string;
  image: string | File | undefined;
  hasHyperlink?: boolean;
  typeLink?: 'a-href' | 'click-tag';
  linkRedirect?: string;
}

export interface CarouselContentProps extends LayerComponentProps {
  itemsCarousel: ICarouselItem[];
  autoplayCarousel: boolean;
  loopCarousel: boolean;
  speedCarousel: number;
  objectFit: ObjectFit;
  typeCarousel: 'carousel1' | 'carousel2' | 'carousel3' | 'carousel4' | 'carousel5' | 'carousel6' | 'carousel7' | 'carousel8' | 'carousel9';
}

export const CarouselContent: FunctionComponent<CarouselContentProps> = (props) => {

  switch (props.typeCarousel) {
    case  "carousel1":
      return  <Carousel1 {...props}/>
    case  "carousel2":
      return  <Carousel2 {...props}/>
    case  "carousel3":
      return  <Carousel3 {...props}/>
    case  "carousel4":
      return  <Carousel4 {...props}/>
    case  "carousel5":
      return  <Carousel5 {...props}/>
    case "carousel6":
      return  <Carousel6 {...props}/>
    case "carousel7":
      return  <Carousel7 {...props}/>
    case "carousel8":
      return  <Carousel8 {...props}/>
    default:
      return  <Carousel9 {...props}/>
    }
};
