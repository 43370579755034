import { convertToDataURI } from "./convertToDataURI";

const configSwiperPremium = `{
    direction: "horizontal",
    loop: true,
    speed: 1000,
    autoplay: true,
    slidesPerView: 1,
    on: {
      slideChangeTransitionEnd: function () {
        document.querySelectorAll('.swiper-slide iframe').forEach((iframe, index) => {
          if (index !== this.activeIndex) {
            iframe.contentWindow.postMessage("pause", "*");
          }
        });
      }
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    }
}
`

export const generateCollectionHTML = (slides: string, mobileFrame: boolean) => `
<!-- HTML Generated by Creative Maker -->
<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Creatives</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
  <script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>
    <style>
        * { margin: 0; padding: 0; box-sizing: border-box;}

        .mobile-preview {
          height: 100vh;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
          ${mobileFrame
            ? `background-image: url(https://creatives.wortise.com/1726030755970.png);
              padding: 13vh 2.6vh 10vh 2.6vh;
              aspect-ratio: 402/822;
              width: auto;
              overflow: hidden;
              `
            : `
              width: 100%
              `}
          }
          .swiper-wrapper{
            align-items: center;
          }
          .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-left{
            background-image: none
          }

          #ROOT{
            aspect-ratio: 1080 / 1920;
          }

          #ROOT.with-mobile-frame {
            width: 100%;
            height: auto;
          }
          #ROOT.without-mobile-frame {
            height: 100%;
            width: auto;
          }

    </style>
</head>
<body >

<div style="width: 100%; height: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center">
<div class="mobile-preview">
<div data-locked="false"
    data-id="ROOT"
    data-type="RootLayer"
    class="${mobileFrame ? "with-mobile-frame" : "without-mobile-frame"}"
    data-child='["2dd821c5-209a-45b2-9215-bc204bc4a07d"]'
    data-parent="null"
    id="ROOT"
    style="
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  ">

<div data-id="2dd821c5-209a-45b2-9215-bc204bc4a07d"
        data-locked="false"
        data-type="CarouselLayer"
        data-child='[]'
        data-parent="ROOT"
        style="width: 100%; height: 100%;
z-index: 1;">
            <div class="swiper swiper-2dd821c5"
  style="height: 100%; position: relative; width: 100%; ">
<!-- Additional required wrapper -->
<div class="swiper-wrapper">
  <!-- Slides -->
${slides}
</div>
${mobileFrame ? "" : "</div>"}
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
${mobileFrame ? "</div>" : ""}



<script>
const swiper2dd821c5 = new Swiper(".swiper-2dd821c5",
  ${configSwiperPremium});
</script>
      </div></div>
<div>
</div>
</body>
</html>
<!-- HTML Generated by Creative Maker -->`


export const generateSlideCollectionHTML = (html: string, boxSize: {width: number, height: number}) => `
  <div class="swiper-slide">
    <div
      style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;"
      id="swiper-item"
      class="carousel-item"
    >
      <div style="  position: relative;
      ${(boxSize.width / boxSize.height) > 0.54 ? 'width: 100%;' : 'height: 100%;'}
      aspect-ratio: ${boxSize.width} / ${boxSize.height};
      ">
        <iframe width="100%" src=${`${convertToDataURI(html)}`} style="position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border: none;
        right: 0;
        width: 100%;
        aspect-ratio: ${boxSize.width} / ${boxSize.height};
        "></iframe>
      </div>
    </div>
  </div>`
