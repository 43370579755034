import { useEditor } from "../hooks";
import PremiumSettingsSibebar from "./sidebar/PremiumSettingsSidebar";

export const PremiumSettings = () => {
  const { sidebar } = useEditor((state) => ({
    sidebar: state.sidebar,
  }));

  return (
    sidebar === 'PREMIUM_SETTINGS' && <PremiumSettingsSibebar open={true} />
  );
}
