import { FunctionComponent } from 'react';

interface IEmptySlideProps {
  children?: React.ReactNode;
}

export const EmptySlide: FunctionComponent<IEmptySlideProps> = ({
  children,
}) => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #676767',
        borderRadius: 10,
        fontSize: 20,
      }}
    >
      {children}
    </div>
  );
};
