import { FunctionComponent } from 'react';
import { IconProps } from '../Icon';

const MobileOrientationIcon: FunctionComponent<IconProps> = ({
  className,
  style = {},
  color = '#000000',
  size = 24,
}) => {
  return (
    <svg
      className={className}
      fill={color}
      height={size}
      style={style}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M8.919,2,2,8.919a2,2,0,0,0,0,2.828L12.252,22a2,2,0,0,0,2.829,0L22,15.081a2,2,0,0,0,0-2.829L11.748,2A2.005,2.005,0,0,0,8.919,2Zm4.748,18.586L3.414,10.333l6.919-6.919L20.586,13.667ZM20.256,7.419a2.744,2.744,0,0,0-.63-3.045,2.75,2.75,0,0,0-3.046-.631,1,1,0,1,1-.827-1.82A4.751,4.751,0,0,1,21.041,2.96a4.752,4.752,0,0,1,1.036,5.287,1,1,0,0,1-.911.586.985.985,0,0,1-.414-.09A1,1,0,0,1,20.256,7.419ZM3.744,16.581a2.744,2.744,0,0,0,.63,3.045,2.753,2.753,0,0,0,3.046.631,1,1,0,1,1,.827,1.82,4.481,4.481,0,0,1-1.859.4A4.87,4.87,0,0,1,2.959,21.04a4.752,4.752,0,0,1-1.036-5.287,1,1,0,0,1,1.821.828Zm10.882-.541a1,1,0,1,1,1.414,0A1,1,0,0,1,14.626,16.04Z"></path>
      </g>
    </svg>
  );
};

export default MobileOrientationIcon;
