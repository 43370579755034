import LinkIcon from '@duyank/icons/regular/Link';
import { Checkbox, Input, Radio, RadioGroup } from '@nextui-org/react';
import React, { ChangeEvent, FC, Fragment, useRef, useState } from 'react';
import Popover from '../common/popover/Popover';

import { useEditor, useSelectedLayers } from '../hooks';
import { Hyperlink } from '../types';
import SettingButton from './SettingButton';

const HyperlinkSettings: FC = () => {
  const hyperlinkButtonref = useRef<HTMLDivElement>(null);
  const [openHyperlinkSetting, setOpenHyperlinkSetting] = useState(false);
  const { selectedLayers, selectedLayerIds } = useSelectedLayers();
  const { actions, activePage, isPremium, pages } = useEditor(
    (state) => ({
      activePage: state.activePage,
      isPremium: state.pages[state.activePage].layers.ROOT.data?.isPremium,
      pages: state.pages,
    })
  );

  const currentContainer = pages[activePage].layers.ROOT;
  const selectedLayer = selectedLayers[0];

  const hyperLink = isPremium ? currentContainer?.data?.props?.hyperlink : selectedLayer?.data?.props?.hyperlink;

  const onChange = (name: keyof Hyperlink, value: boolean | string) => {
    const newHyperLink = {...hyperLink, [name]: value}
    actions.setHyperlink(activePage, isPremium ? 'ROOT' : selectedLayerIds[0], newHyperLink);
  };

  return (
    <Fragment>
      <SettingButton
        ref={hyperlinkButtonref}
        css={{ fontSize: 20 }}
        isActive={hyperLink?.hasHyperlink}
        onClick={() => setOpenHyperlinkSetting(true)}
      >
        <span>
          <LinkIcon />
        </span>
      </SettingButton>
      <Popover
        anchorEl={hyperlinkButtonref.current}
        offsets={{
          'bottom-end': { x: 0, y: 8 },
        }}
        open={openHyperlinkSetting}
        placement={'bottom-end'}
        onClose={() => setOpenHyperlinkSetting(false)}
      >
        <div css={{ padding: 16, width: 240, display: "flex", flexDirection: "column", gap: 8 }}>
          <div>
            <Checkbox
              color='default'
              isSelected={hyperLink?.hasHyperlink}
              onValueChange={(value) => onChange("hasHyperlink", value)}
            >
              Activate hyperlink
            </Checkbox>
          </div>
          {hyperLink?.hasHyperlink && (
            <div>
              <RadioGroup
                css={{paddingLeft: 16, marginBottom: 8}}
                value={hyperLink?.typeLink}
                onValueChange={(value) => onChange("typeLink", value)}
                >
                <Radio
                  value="a-href"
                >
                  Link HREF
                </Radio>
                <Radio
                  value="clickTag"
                >
                  Click Tag
                </Radio>
              </RadioGroup>
              <Input
                placeholder='Type URL'
                size='lg'
                value={hyperLink?.linkRedirect}
                variant='bordered'
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange("linkRedirect", e.target.value)}
              />
            </div>
          )}
        </div>
      </Popover>
    </Fragment>
  );
};

export default HyperlinkSettings;
