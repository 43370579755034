import React, {
  FC,
  Fragment,
  useRef,
  useState,
} from 'react';
import Popover from '../common/popover/Popover';
import { useEditor } from '../hooks';
import { getScale } from '../ultils/getScale';
import { formats } from './formats';
import SettingButton from './SettingButton';

const FormatSettings: FC = () => {
  const { actions, activePage, pages } = useEditor(
    (state) => ({
      activePage: state.activePage,
      pages: state.pages,
    })
  );

  const [openResizeSetting, setOpenResizeSetting] = useState<number | null>(
    null
  );

  const resizeButtonRefs = useRef(
    formats.map(() => React.createRef<HTMLDivElement>())
  );

  const handleFormatSelection = (width: number, height: number) => {
    const page = pages[activePage];
    const hasMobileFrame = page?.layers.ROOT.data.props?.hasMobileFrame;
    const mobileOrientation = hasMobileFrame ? page?.layers.ROOT.data.props?.mobileOrientation : undefined;
    actions.changePageSize(activePage, { width, height });
    const newScale = getScale(width, height, mobileOrientation);
    actions.setScale(newScale);
    setOpenResizeSetting(null);
  };

  return (
    <Fragment>
      {formats.map((format, index) => (
        <Fragment key={format.id}>
          <SettingButton
            key={format.id}
            ref={resizeButtonRefs.current[index]}
            onClick={() => setOpenResizeSetting(index)}
          >
            <span css={{ padding: '0 4px' }}>{format.icon}</span>
          </SettingButton>
          {openResizeSetting === index && (
            <Popover
              anchorEl={resizeButtonRefs.current[index].current}
              offsets={{
                'bottom-end': { x: 40, y: 8 },
              }}
              open={openResizeSetting === index}
              placement={'bottom-end'}
              onClose={() => setOpenResizeSetting(null)}
            >
              <div css={{ padding: 16 }}>
                <p css={{marginBottom: 8, fontWeight: 'bolder'}}>{format.title}</p>
                <div css={{ display: 'grid', gridTemplateColumns: format.child.length === 1 ? '1fr' : '1fr 1fr', gap: 8 }}>
                  {format.child.map((child, idx) => (
                    <button
                      key={idx}
                      css={{
                        width: 100,
                        background: '#f0f0f0',
                        borderRadius: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: 4,
                        ':hover': {
                          background: '#d6d6d6',
                        },
                      }}
                      onClick={() => handleFormatSelection(child.width, child.height)}
                    >
                      {child.width} x {child.height}px
                    </button>
                  ))}
                </div>
              </div>
            </Popover>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default FormatSettings;
