
import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const SizeTextIcon: FunctionComponent<IconProps> = ({ className, style = {} }) => {
  return (
    <svg
      className={className}
      fill='none'
      height='13'
      style={style}
      viewBox='0 0 21 13'
      width='21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.89488 12V10.144L6.84688 9.84V6.736C6.84688 6.02133 6.64421 5.472 6.23888 5.088C5.84421 4.704 5.25221 4.512 4.46288 4.512C3.91888 4.512 3.40154 4.60267 2.91088 4.784C2.42021 4.96533 2.00421 5.20533 1.66288 5.504L1.15088 4.656C1.57754 4.29333 2.08954 4.016 2.68688 3.824C3.28421 3.62133 3.91354 3.52 4.57488 3.52C5.66288 3.52 6.50021 3.792 7.08688 4.336C7.68421 4.86933 7.98288 5.68533 7.98288 6.784V12H6.89488ZM3.99888 12.08C3.36954 12.08 2.82021 11.9787 2.35088 11.776C1.89221 11.5627 1.54021 11.2747 1.29488 10.912C1.04954 10.5387 0.926875 10.112 0.926875 9.632C0.926875 9.19467 1.02821 8.8 1.23088 8.448C1.44421 8.08533 1.78554 7.79733 2.25488 7.584C2.73488 7.36 3.37488 7.248 4.17488 7.248H7.07088V8.096H4.20688C3.39621 8.096 2.83088 8.24 2.51088 8.528C2.20154 8.816 2.04688 9.17333 2.04688 9.6C2.04688 10.08 2.23354 10.464 2.60688 10.752C2.98021 11.04 3.50288 11.184 4.17488 11.184C4.81488 11.184 5.36421 11.04 5.82288 10.752C6.29221 10.4533 6.63354 10.0267 6.84688 9.472L7.10288 10.256C6.88954 10.8107 6.51621 11.2533 5.98288 11.584C5.46021 11.9147 4.79888 12.08 3.99888 12.08ZM9.51638 12L14.6364 0.799999H15.8044L20.9244 12H19.6764L14.9724 1.488H15.4524L10.7484 12H9.51638ZM11.5324 9.008L11.8844 8.048H18.3964L18.7484 9.008H11.5324Z'
        fill='#676767'
      />
    </svg>
  )
}

export default SizeTextIcon
