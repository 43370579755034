import { CarouselContentProps } from '@lidojs/design-layers';
import { generateSlides } from './generateSlides';

export const generateCarouselHTML = (
  props: Record<string, unknown>,
  id: string
) => {
  const { autoplayCarousel, loopCarousel, speedCarousel } =
    props as unknown as CarouselContentProps;
  const slides = generateSlides(props as unknown as CarouselContentProps);
  const idShort = id.split('-')[0];
  switch (props?.typeCarousel) {
    case 'carousel1':
      return `     <div
    class="swiper swiper2${idShort}"
    style="height: 80%; width: 100%"
  >
    <div class="swiper-wrapper">
      ${slides}
    </div>
  </div>
    <div thumbsSlider="" class="swiper swiper1${idShort}"
      style="height: 20%; width: 100%">
    <div class="swiper-wrapper">
      ${slides}
    </div>
  </div>`;
    case 'carousel2':
      return `  <div class="swiper swiper-${idShort}"
      style="height: 100%; position: relative; width: 100%; ">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
     ${slides}
    </div>
    </div>`;
    case 'carousel3':
      return `  <div class="swiper swiper-${idShort}"
    style="height: 100%; position: relative; width: 100%; ">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
   ${slides}
  </div>
  </div>`;
    case 'carousel4':
      return `<swiper-container class="mySwiper"
    direction="vertical" slides-per-view="3" style="height: 100%; width: 100%;"
    loop="${loopCarousel}" autoplay="${autoplayCarousel}"
    speed="${speedCarousel}">
      ${slides}
    </swiper-container>`;
    case 'carousel5':
      return `  <div class="swiper swiper-${idShort}"
    style="height: 100%; position: relative; width: 100%; ">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
   ${slides}
  </div>
  </div>`;
    case 'carousel6':
      return `  <div class="swiper swiper-${idShort}"
    style="height: 100%; position: relative; width: 100%; ">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
   ${slides}
  </div>
  </div>`;
    case 'carousel7':
      return `  <div class="swiper swiper-${idShort}"
  style="height: 100%; position: relative; width: 100%; ">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
   ${slides}
  </div>
  </div>`;

    default:
      return `     <div
  class="swiper swiper2${idShort}"
  style="height: 80%; width: 100%"
>
  <div class="swiper-wrapper">
    ${slides}
  </div>
</div>
  <div thumbsSlider="" class="swiper swiper1${idShort}"
  style="height: 20%; width: 100%"
  >
  <div class="swiper-wrapper">
    ${slides}
  </div>
</div>`;
  }
};
