import { BoxSize } from "@lidojs/design-core";
import Compressor from "compressorjs";

interface IOptionsCompress{
  containerSize: BoxSize;
  quality?: number;
}

export const getImageCompressed = (file: File, optionsCompress: IOptionsCompress): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        img.src = event.target.result as string;
      }
    };

    reader.readAsDataURL(file);

    img.onload = () => {
      const { width: imgWidth, height: imgHeight } = img;
      const { width: containerWidth, height: containerHeight } = optionsCompress.containerSize;

      const { newWidth, newHeight } = calculateNewDimensions(imgWidth, imgHeight, containerWidth, containerHeight);

      new Compressor(file, {
        quality: optionsCompress.quality || 0.8,
        width: newWidth,
        height: newHeight,
        convertTypes: [],
        convertSize: Infinity,
        success(result) {
          const compressedFileAsFile = new File([result], file.name, {
            type: result.type,
            lastModified: Date.now(),
          });

          resolve(compressedFileAsFile);
        },
        error(err) {
          console.error('Compression error:', err.message);
          resolve(file);
        },
      });
    };

    img.onerror = () => {
      reject(new Error('Could not load image'));
    };
  });
};


const calculateNewDimensions = (
  imgWidth: number,
  imgHeight: number,
  containerWidth: number,
  containerHeight: number
): { newWidth: number; newHeight: number } => {
  const aspectRatioImg = imgWidth / imgHeight;
  const aspectRatioContainer = containerWidth / containerHeight;

  let newWidth: number;
  let newHeight: number;

  if (aspectRatioImg > aspectRatioContainer) {
    // Landscape
    newWidth = Math.min(containerWidth, imgWidth);
    newHeight = (newWidth / imgWidth) * imgHeight;

    if (newHeight > containerHeight) {
      newHeight = containerHeight;
      newWidth = (newHeight / imgHeight) * imgWidth;
    }
  } else {
    // Portrait
    newHeight = Math.min(containerHeight, imgHeight);
    newWidth = (newHeight / imgHeight) * imgWidth;

    if (newWidth > containerWidth) {
      newWidth = containerWidth;
      newHeight = (newWidth / imgWidth) * imgHeight;
    }
  }

  return { newWidth, newHeight };
};
