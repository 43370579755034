import { getTransformStyle } from '@lidojs/design-core';
import { FrameLayerProps } from '@lidojs/design-editor';
import { generateFilters } from './generateFilters';

export const generateFrameHTML = (props: Record<string, unknown>): string => {
  const { clipPath, frameSize, image } = props as unknown as FrameLayerProps;
  const filterString = generateFilters(image);

  const imageDefault = image ?? {
    boxSize: {
      width: 396,
      height: 264,
    },
    position: {
      x: -102.5,
      y: 0,
    },
    rotate: 0,
    url: 'https://creatives.wortise.com/1730783348032.png',
    thumb: 'https://creatives.wortise.com/1730783348032.png',
  };

  return `<div class="frame-layer-container" data-frame-width="${frameSize?.width}" style="width: 100%; height: 100%;">
            <div class="frame-layer-transform" style="width: 100%; height: 100%; transform-origin: 0 0;">
              <div style="pointer-events: auto; width: 100%; height: 100%;">
                <div style="width: 100%; height: 100%; clip-path: path('${clipPath}')">
                  <div style="width: ${frameSize.width}px; height: ${frameSize.height}px">
                    <div
                      style="
                        width: ${imageDefault.boxSize.width}px;
                        height: ${imageDefault.boxSize.height}px;
                        transform: ${getTransformStyle({
                          position: imageDefault.position,
                          rotate: imageDefault.rotate,
                        })};
                        position: relative;
                        user-select: none;
                      "
                    >
                      <img
                        alt="${imageDefault.url}"
                        style="
                          object-fit: fill;
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          pointer-events: none;
                          filter: ${filterString};
                        "
                        src="${imageDefault.url}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
};
