import { BoxSize } from "@lidojs/design-core";
import { Page } from "../../types";

const isSameBoxSize = (boxSize1: BoxSize, boxSize2: BoxSize) =>
  boxSize1.width === boxSize2.width && boxSize1.height === boxSize2.height;

export const haveDifferentSizes = (pages: Page[]) => {
  for (let i = 0; i < pages.length; i++) {
    for (let j = i + 1; j < pages.length; j++) {
      const boxSize1 = pages[i].layers.ROOT.data.props.boxSize;
      const boxSize2 = pages[j].layers.ROOT.data.props.boxSize;

      if (!isSameBoxSize(boxSize1, boxSize2)) {
        return true;
      }
    }
  }
  return false;
};
