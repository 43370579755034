import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const CarouselModel3: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='83'
      style={style}
      viewBox='0 0 122 83'
      width='122'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill='white' height='83' width='122' />
      <rect
        fill='white'
        height='62'
        rx='4.5'
        stroke='#676767'
        width='95'
        x='13.5'
        y='10.5'
      />
      <path
        d='M113 10.5H121.5V72.5H113C110.515 72.5 108.5 70.4853 108.5 68V15C108.5 12.5147 110.515 10.5 113 10.5Z'
        fill='white'
        stroke='#676767'
      />
      <path
        d='M9 10.5H0.5V72.5H9C11.4853 72.5 13.5 70.4853 13.5 68V15C13.5 12.5147 11.4853 10.5 9 10.5Z'
        fill='white'
        stroke='#676767'
      />
    </svg>
  )
}

export default CarouselModel3
