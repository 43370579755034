import { redirectURLDefault } from "../../constant/redirectURL";

export const templates = [
  {
    id: 1,
    img: 'https://i.imgur.com/zNDi6Os.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '9febed47-ba8e-4f5a-b11b-bd64c0fd008a',
            'dafea75b-7f5e-43c9-82a2-f8e155181c67',
            '90874ad4-579a-48bc-9376-d43be4831726',
            '5ece12a7-5eab-41f6-a4c7-dc191a56f282',
            '625097ec-e420-4686-8116-76b0091f6711',
          ],
          props: {
            color: 'rgb(35, 31, 32)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '5ece12a7-5eab-41f6-a4c7-dc191a56f282': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Lato;font-size: 32px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">Only for this weekend</span></p>',
            fonts: [
              {
                name: 'Lato',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    ],
                    style: 'Bold_Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 32,
              y: 859,
              width: 465.5,
              height: 45,
            },
            position: {
              x: 32,
              y: 859,
            },
            fontSizes: [32],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '625097ec-e420-4686-8116-76b0091f6711': {
          type: {
            resolvedName: 'GroupLayer',
          },
          child: [
            '843c2238-c470-461c-90f7-666efb9230eb',
            'ea8db774-ceef-4fb0-9f13-e8df163fc442',
          ],
          props: {
            scale: 1,
            rotate: 0,
            boxSize: {
              width: 717.5,
              height: 156,
            },
            position: {
              x: 23,
              y: 528,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '843c2238-c470-461c-90f7-666efb9230eb': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Lilita One;font-size: 80px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">BANH MI</span></p>',
            fonts: [
              {
                name: 'Lilita One',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lilita+One&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 36,
              y: 57,
              width: 676.5,
              height: 112,
            },
            position: {
              x: 0,
              y: 0,
            },
            fontSizes: [80],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '625097ec-e420-4686-8116-76b0091f6711',
        },
        '90874ad4-579a-48bc-9376-d43be4831726': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/RqfoZjE.png',
              thumb: 'https://i.imgur.com/RqfoZjEm.png',
              rotate: 0,
              boxSize: {
                width: 1112.4117263843648,
                height: 739.1999999999999,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1112.4117263843648,
              height: 739.1999999999999,
            },
            position: {
              x: 559.7941368078176,
              y: 351.40000000000003,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '9f2e6a40-a37f-4763-9088-ffee601f63e0': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Anton;font-size: 100px;color: rgb(255, 223, 43);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 223, 43);">20%</span></p>',
            fonts: [
              {
                name: 'Anton',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 0.5119496855345911,
            colors: ['rgb(255, 223, 43)'],
            rotate: 0,
            boxSize: {
              x: 1242,
              y: 377.39119496855346,
              width: 190.49999999999997,
              height: 71.67295597484275,
            },
            position: {
              x: 46,
              y: 131.39119496855346,
            },
            fontSizes: [100],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'dafea75b-7f5e-43c9-82a2-f8e155181c67',
        },
        '9febed47-ba8e-4f5a-b11b-bd64c0fd008a': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Anton; color: rgb(255, 223, 43); font-size: 100px; text-align: center; line-height: 1.4; letter-spacing: 0em;">SPECIAL PROMOTION</p>',
            fonts: [
              {
                name: 'Anton',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Anton&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 223, 43)'],
            rotate: 0,
            boxSize: {
              x: 36,
              y: 102,
              width: 672.5,
              height: 280,
            },
            position: {
              x: 36,
              y: 102,
            },
            fontSizes: [100],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'dafea75b-7f5e-43c9-82a2-f8e155181c67': {
          type: {
            resolvedName: 'GroupLayer',
          },
          child: [
            'e02e001a-8641-4e3e-9dd1-b7f403eb1ef9',
            'e41739b4-a232-4616-925e-bf59849f8419',
            '9f2e6a40-a37f-4763-9088-ffee601f63e0',
          ],
          props: {
            scale: 1,
            rotate: 0,
            boxSize: {
              width: 284,
              height: 284,
            },
            position: {
              x: 1196,
              y: 246,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'e02e001a-8641-4e3e-9dd1-b7f403eb1ef9': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(168, 65, 54)',
            shape: 'circle',
            rotate: 0,
            boxSize: {
              x: 155,
              y: 328,
              width: 284,
              height: 284,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'dafea75b-7f5e-43c9-82a2-f8e155181c67',
        },
        'e41739b4-a232-4616-925e-bf59849f8419': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Oswald;font-size: 40px;color: rgb(255, 223, 43); line-height: 1.4; letter-spacing: 0em;"><span style="color: rgb(255, 223, 43);">DISCOUNTS</span></p>',
            fonts: [
              {
                name: 'Oswald',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 223, 43)'],
            rotate: 0,
            boxSize: {
              x: 1226,
              y: 324.39119496855346,
              width: 223.49999999999997,
              height: 56,
            },
            position: {
              x: 30,
              y: 78.39119496855346,
            },
            fontSizes: [40],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'dafea75b-7f5e-43c9-82a2-f8e155181c67',
        },
        'ea8db774-ceef-4fb0-9f13-e8df163fc442': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Lilita One;font-size: 60px;color: rgb(168, 65, 54);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(168, 65, 54);">DAC BIET</span></p>',
            fonts: [
              {
                name: 'Lilita One',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lilita+One&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(168, 65, 54)'],
            rotate: 0,
            boxSize: {
              x: 36,
              y: 57,
              width: 676.5,
              height: 84,
            },
            position: {
              x: 41,
              y: 72,
            },
            fontSizes: [60],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '625097ec-e420-4686-8116-76b0091f6711',
        },
      },
    },
  },
  {
    id: 2,
    img: 'https://i.imgur.com/RDvpeV0.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            'b3a7e890-7d5b-4105-a0ea-8483ad85bd09',
            '1669d999-8ac2-4b61-9ef3-936b1ca79f23',
            '8081ea92-357b-4f57-bb30-4ab10a702c7a',
            '96258207-40cf-492c-afcf-6986791690fe',
            'b1948286-37d0-43ed-b045-662cfb2639e0',
            '992cabd2-0ca9-4c0f-a219-2184ff9c6eb6',
            'bb3dd8c5-5bee-4a48-ae24-ba02ec3b406a',
            'df62d22a-0d02-4233-ba89-eb6c669ee8cc',
            'beb3d715-064e-415f-89d0-295312d0a0b2',
            'ab4ccedf-466a-4f8c-80ba-281c98a8a25e',
          ],
          props: {
            color: 'rgb(255, 255, 255)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '1669d999-8ac2-4b61-9ef3-936b1ca79f23': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(229, 107, 18)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: 635.7151702786377,
              y: 765.1455108359132,
              width: 277.17647058823525,
              height: 81.1888544891641,
            },
            position: {
              x: 317.96284829721355,
              y: 522.4829721362229,
            },
            roundedCorners: 100,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '8081ea92-357b-4f57-bb30-4ab10a702c7a': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Bebas Neue;text-transform: uppercase;font-size: 40px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">ORDER NOW</span></p>',
            fonts: [
              {
                name: 'Bebas Neue',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1.2148130507263637,
            colors: ['rgb(255, 255, 255)'],
            rotate: 0,
            boxSize: {
              x: 651.0216718266255,
              y: 575.6130030959752,
              width: 247.60560305896217,
              height: 68.02953084067636,
            },
            position: {
              x: 332.7482820618501,
              y: 529.0626339604668,
            },
            fontSizes: [40],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '96258207-40cf-492c-afcf-6986791690fe': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(229, 107, 18)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: -65,
              y: -80,
              width: 1781,
              height: 200,
            },
            position: {
              x: -56,
              y: -136,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '992cabd2-0ca9-4c0f-a219-2184ff9c6eb6': {
          type: {
            resolvedName: 'ImageLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/6L0yer5.png',
              thumb: 'https://i.imgur.com/6L0yer5m.png',
              rotate: 0,
              boxSize: {
                width: 805.2287581699347,
                height: 739.2,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 805.2287581699347,
              height: 739.2,
            },
            position: {
              x: 855.3856209150326,
              y: 85.39999999999998,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'ab4ccedf-466a-4f8c-80ba-281c98a8a25e': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Lilita One;font-size: 18px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(0, 0, 0);">www.food.com</span></p>',
            fonts: [
              {
                name: 'Lilita One',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Lilita+One&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0.5251966021647263,
            boxSize: {
              x: 41,
              y: 275,
              width: 259.91666666666606,
              height: 25,
            },
            position: {
              x: 24.995416859293783,
              y: 832.9999789943801,
            },
            fontSizes: [18],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b1948286-37d0-43ed-b045-662cfb2639e0': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(229, 107, 18)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: -65,
              y: -80,
              width: 1781,
              height: 200,
            },
            position: {
              x: -25,
              y: 870,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b3a7e890-7d5b-4105-a0ea-8483ad85bd09': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Chewy;text-transform: uppercase;font-size: 95px;color: rgb(229, 107, 18);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(229, 107, 18);">SALE</span></p>',
            fonts: [
              {
                name: 'Chewy',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Chewy&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1.9013035381750465,
            colors: ['rgb(229, 107, 18)'],
            effect: {
              name: 'hollow',
              settings: {
                thickness: 50,
              },
            },
            rotate: 0,
            boxSize: {
              x: 71,
              y: 206,
              width: 510.5,
              height: 252.8733705772812,
            },
            position: {
              x: 218,
              y: 127.23836126629423,
            },
            fontSizes: [95],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'bb3dd8c5-5bee-4a48-ae24-ba02ec3b406a': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Chewy;text-transform: uppercase;font-size: 120px;color: rgb(229, 107, 18);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(229, 107, 18);">Special Food</span></p>',
            fonts: [
              {
                name: 'Chewy',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Chewy&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(229, 107, 18)'],
            rotate: 0,
            boxSize: {
              x: -65,
              y: 155.23836126629425,
              width: 794.5,
              height: 168,
            },
            position: {
              x: 69,
              y: 307.23836126629425,
            },
            fontSizes: [120],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'beb3d715-064e-415f-89d0-295312d0a0b2': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Ultra;font-size: 22px;color: rgb(229, 107, 18);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(229, 107, 18);">More Info:</span></p>',
            fonts: [
              {
                name: 'Ultra',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Ultra&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(229, 107, 18)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 41,
              y: 275,
              width: 259.91666666666606,
              height: 31,
            },
            position: {
              x: 27.00000000000003,
              y: 759,
            },
            fontSizes: [22],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'df62d22a-0d02-4233-ba89-eb6c669ee8cc': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Oswald;text-transform: uppercase;font-size: 18px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(0, 0, 0);">+123-456-789</span></strong></p>',
            fonts: [
              {
                name: 'Oswald',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0.5251966021647263,
            boxSize: {
              x: 41,
              y: 275,
              width: 259.91666666666606,
              height: 25,
            },
            position: {
              x: 25.995416859199334,
              y: 798.9999789943791,
            },
            fontSizes: [18],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
  {
    id: 3,
    img: 'https://i.imgur.com/ypUE7hH.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '08ff8f25-7990-485f-8bce-840ae8ffe21a',
            '56fc9db9-3201-4f65-9da7-51c3acb45058',
            'abef1a60-d036-4626-92f2-0dd5fa384643',
            '51174bcc-7263-4866-a938-300bbbf28f1f',
            '7518275a-3642-4ec2-9906-45976d114b85',
            'dbb15c7e-5a42-4d4d-8371-c08bc6814ccc',
            'cb45b3bd-5665-4149-9825-7e68681758f4',
            '038969b1-59a3-4fd3-8f8e-24540c5932e9',
            '3d01117f-6816-454e-b741-9038c874b7b9',
          ],
          props: {
            color: 'rgb(239, 238, 238)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '038969b1-59a3-4fd3-8f8e-24540c5932e9': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Almarai;text-transform: uppercase;font-size: 104px;color: rgb(52, 49, 47);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(52, 49, 47);">Modern room</span></p>',
            fonts: [
              {
                name: 'Almarai',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(52, 49, 47)'],
            rotate: 0,
            boxSize: {
              x: 581.6066350710901,
              y: 115.34597156398104,
              width: 857.1176935229068,
              height: 146,
            },
            position: {
              x: 410.6113744075829,
              y: 70.00631911532385,
            },
            fontSizes: [104],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '08ff8f25-7990-485f-8bce-840ae8ffe21a': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/Vm0PVfp.jpg',
              thumb: 'https://i.imgur.com/Vm0PVfpm.jpg',
              rotate: 0,
              boxSize: {
                width: 440.6849252942363,
                height: 661.0273879413545,
              },
              position: {
                x: -101.73995880314791,
                y: -49.808885516534986,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 94.50197778830972,
              y: 327.30330430989534,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '3d01117f-6816-454e-b741-9038c874b7b9': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Almarai;text-transform: uppercase;font-size: 24px;color: rgb(52, 49, 47);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(52, 49, 47);">Save time and money while satisfying all your fashion, clothing and accessory needs by visiting these best showroom</span></p>',
            fonts: [
              {
                name: 'Almarai',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(52, 49, 47)'],
            rotate: 0,
            boxSize: {
              x: 581.6066350710901,
              y: 115.34597156398104,
              width: 857.1176935229068,
              height: 67,
            },
            position: {
              x: 384.33965244865715,
              y: 814.5086887835704,
            },
            fontSizes: [24],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '51174bcc-7263-4866-a938-300bbbf28f1f': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/uSa1yQV.jpg',
              thumb: 'https://i.imgur.com/uSa1yQVm.jpg',
              rotate: 0,
              boxSize: {
                width: 404.6205159655355,
                height: 541.0783159403112,
              },
              position: {
                x: -18.645993989828895,
                y: 0,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 691.460328861764,
              y: 238.8138601817034,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '56fc9db9-3201-4f65-9da7-51c3acb45058': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/iZ2cmsm.jpg',
              thumb: 'https://i.imgur.com/iZ2cmsmm.jpg',
              rotate: 0,
              boxSize: {
                width: 336.61,
                height: 506.89505882352944,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 308.2838349864708,
              y: 239.6814311816677,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '7518275a-3642-4ec2-9906-45976d114b85': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/wXPmETk.jpg',
              thumb: 'https://i.imgur.com/wXPmETkm.jpg',
              rotate: 0,
              boxSize: {
                width: 367.3285279858777,
                height: 550.2610618433865,
              },
              position: {
                x: -30.71852798587771,
                y: -40.17038275076316,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 862.692439919951,
              y: 324.4840508306378,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'abef1a60-d036-4626-92f2-0dd5fa384643': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/9pHKSeF.jpg',
              thumb: 'https://i.imgur.com/9pHKSeFm.jpg',
              rotate: 0,
              boxSize: {
                width: 394.5026104349234,
                height: 591.753915652385,
              },
              position: {
                x: 0,
                y: -34.26297352270976,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 478.7227693377513,
              y: 325.6425566030247,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'cb45b3bd-5665-4149-9825-7e68681758f4': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/ZQ4CBCt.jpg',
              thumb: 'https://i.imgur.com/ZQ4CBCtm.jpg',
              rotate: 0,
              boxSize: {
                width: 386.2322375156486,
                height: 579.3483562734729,
              },
              position: {
                x: 0,
                y: -41.35186459637384,
              },
            },
            scale: 0.8463947234695957,
            rotate: 0,
            boxSize: {
              x: 93.20655914691945,
              y: 260.7946287519747,
              width: 284.9049278671006,
              height: 423.19736173479777,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 1246.9132314693925,
              y: 327.21180098242166,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'dbb15c7e-5a42-4d4d-8371-c08bc6814ccc': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/XsIiZRC.jpg',
              thumb: 'https://i.imgur.com/XsIiZRCm.jpg',
              rotate: 0,
              boxSize: {
                width: 495.9196813647603,
                height: 743.8174855596042,
              },
              position: {
                x: 0,
                y: -118.00469267401644,
              },
            },
            scale: 0.847423926404743,
            rotate: 0,
            boxSize: {
              x: 1074.1345808025735,
              y: 242.5859080076356,
              width: 285.25136786710056,
              height: 423.7119632023715,
            },
            clipPath: 'M 336.62 0 L 215.49 500 H 0 L 119.72 0 H 336.62 Z',
            position: {
              x: 1074.1345808025735,
              y: 242.5859080076356,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
  {
    id: 4,
    img: 'https://i.imgur.com/881XAHt.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '6a51bc8c-38db-4895-8c63-4f07ee734977',
            '6c0a32bb-a627-4878-8ef9-ed5218d43839',
            'b5a45579-fc2b-44ac-ac46-1fe799577669',
            '7c9a9f83-a26b-4e8b-925b-c89bebe7f787',
            'aa01c180-ac1a-40a4-b27c-4b9735d95ee9',
            'cbfc4f8e-51b2-4ce7-b5e3-686cb901e3e1',
            '709a7773-0389-4ec6-9e51-413f2fe7493d',
            '18aa2d7c-15d2-4e96-9172-b90510786b88',
            '21e05f88-20e6-4848-8f93-97d25130f2e2',
            '33df7a97-afad-4d51-b3dd-9a3bf345de4f',
          ],
          props: {
            color: 'rgb(255, 255, 255)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '18aa2d7c-15d2-4e96-9172-b90510786b88': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Armata;font-size: 36px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(0, 0, 0);">Boost your online store business endeavors SEO and online advertising</span></p>',
            fonts: [
              {
                name: 'Armata',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Armata&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 192.1848341232227,
              y: 538.1785150078988,
              width: 642.0651658767766,
              height: 151,
            },
            position: {
              x: 189.59399684044232,
              y: 468.2259083728276,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '21e05f88-20e6-4848-8f93-97d25130f2e2': {
          type: {
            resolvedName: 'GroupLayer',
          },
          child: [
            '80b640f9-59ca-42b9-b1d1-e05cef633d20',
            'dc34c475-3ed6-441a-9cde-8f25835d4ef2',
          ],
          props: {
            scale: 1,
            rotate: 0,
            boxSize: {
              width: 324.36018957345965,
              height: 69.16271721958924,
            },
            position: {
              x: 188.87835703001585,
              y: 701.3996840442338,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '33df7a97-afad-4d51-b3dd-9a3bf345de4f': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Acme;font-size: 36px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(0, 0, 0);">www.website.com</span></p>',
            fonts: [
              {
                name: 'Acme',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Acme&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 193.1169036334913,
              y: 844.8293838862556,
              width: 345.4142969984196,
              height: 50,
            },
            position: {
              x: 194.4123222748815,
              y: 802.0805687203789,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '37ddb2b9-c287-4b5a-89e5-f32695c107e8': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Armata;font-size: 36px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(0, 0, 0);">With Liquid editor</span></p>',
            fonts: [
              {
                name: 'Armata',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Armata&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 193.8436018957346,
              y: 357.1832543443918,
              width: 389.45853080568656,
              height: 50,
            },
            position: {
              x: 7.339652448657148,
              y: 272.92733017377566,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '709a7773-0389-4ec6-9e51-413f2fe7493d',
        },
        '6a51bc8c-38db-4895-8c63-4f07ee734977': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(252, 191, 1)',
            shape: 'rectangle',
            rotate: 50.61800892926624,
            boxSize: {
              x: 735.6649925410832,
              y: 410.2049023395328,
              width: 895.8202501148279,
              height: 104.652156812699,
            },
            position: {
              x: 802.7328654484733,
              y: 412.03914957572647,
            },
            roundedCorners: 100,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '6c0a32bb-a627-4878-8ef9-ed5218d43839': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(252, 191, 1)',
            shape: 'rectangle',
            rotate: 50.61800892926624,
            boxSize: {
              x: 752.3569645187401,
              y: 480.4849835370161,
              width: 725.6499178542341,
              height: 104.652156812699,
            },
            position: {
              x: 773.3781341199224,
              y: 489.18593313960133,
            },
            roundedCorners: 100,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '709a7773-0389-4ec6-9e51-413f2fe7493d': {
          type: {
            resolvedName: 'GroupLayer',
          },
          child: [
            'cbd9da02-72ab-480e-b1d5-817bf9d2f418',
            '37ddb2b9-c287-4b5a-89e5-f32695c107e8',
          ],
          props: {
            scale: 1,
            rotate: 0,
            boxSize: {
              width: 557.8759873617694,
              height: 322.92733017377566,
            },
            position: {
              x: 186.50394944707745,
              y: 88.14218009478674,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '7c9a9f83-a26b-4e8b-925b-c89bebe7f787': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/uSa1yQV.jpg',
              thumb: 'https://i.imgur.com/uSa1yQVm.jpg',
              rotate: 0,
              boxSize: {
                width: 500,
                height: 668.6244204018548,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            scale: 1.1545845181674568,
            rotate: 0,
            boxSize: {
              x: 888.2417061611374,
              y: 120.88941548183264,
              width: 577.2922590837284,
              height: 577.2922590837284,
            },
            clipPath:
              'M 500 250.002 c 0 138.065 -111.931 249.996 -250 249.996 c -138.071 0 -250 -111.931 -250 -249.996 C 0 111.93 111.929 0 250 0 s 250 111.93 250 250.002 Z',
            position: {
              x: 953.0126382306476,
              y: 170.11532385466023,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '80b640f9-59ca-42b9-b1d1-e05cef633d20': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(255, 255, 255)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: 445.3712480252765,
              y: 542.0631911532386,
              width: 324.3601895734597,
              height: 69.16271721958924,
            },
            position: {
              x: 0,
              y: 0,
            },
            roundedCorners: 63,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '21e05f88-20e6-4848-8f93-97d25130f2e2',
        },
        'aa01c180-ac1a-40a4-b27c-4b9735d95ee9': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(255, 145, 77)',
            shape: 'rectangle',
            rotate: 60.88543301633808,
            boxSize: {
              x: -331.66416060648993,
              y: 476.2357833412124,
              width: 982.230864942439,
              height: 498.5238023828933,
            },
            position: {
              x: -360.5248703642174,
              y: 459.27436951488346,
            },
            roundedCorners: 0,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b5a45579-fc2b-44ac-ac46-1fe799577669': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(252, 191, 1)',
            shape: 'rectangle',
            rotate: 50.61800892926624,
            boxSize: {
              x: 957.6402702331276,
              y: 329.85307200463063,
              width: 720.7931524242068,
              height: 104.652156812699,
            },
            position: {
              x: 1012.750139157596,
              y: 341.72641748454055,
            },
            roundedCorners: 100,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'cbd9da02-72ab-480e-b1d5-817bf9d2f418': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Oswald;font-size: 42px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(0, 0, 0);">GROW ONLINE BUSINESS</span></strong></p>',
            fonts: [
              {
                name: 'Oswald',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 2.1892325643885435,
            colors: ['rgb(0, 0, 0)'],
            rotate: 0,
            boxSize: {
              x: 231.84360189573465,
              y: 27.25750394944709,
              width: 557.8759873617694,
              height: 258.32944259784813,
            },
            position: {
              x: 0,
              y: 0,
            },
            fontSizes: [42],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '709a7773-0389-4ec6-9e51-413f2fe7493d',
        },
        'cbfc4f8e-51b2-4ce7-b5e3-686cb901e3e1': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(252, 191, 1)',
            shape: 'rectangle',
            rotate: 64.0442490257555,
            boxSize: {
              x: -231.47576949079837,
              y: 253.22447187503784,
              width: 1327.6580527675085,
              height: 536.4080410683518,
            },
            position: {
              x: -221.0109403106412,
              y: 248.27503490456564,
            },
            roundedCorners: 0,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'dc34c475-3ed6-441a-9cde-8f25835d4ef2': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Alfa Slab One;font-size: 36px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(0, 0, 0);">Learn More</span></p>',
            fonts: [
              {
                name: 'Alfa Slab One',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(0, 0, 0)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 191.8214849921011,
              y: 699.7424960505527,
              width: 246.96248025276407,
              height: 50,
            },
            position: {
              x: 34.0331753554502,
              y: 11.29699842022103,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '21e05f88-20e6-4848-8f93-97d25130f2e2',
        },
      },
    },
  },
  {
    id: 5,
    img: 'https://i.imgur.com/wiC1eEj.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '0394ef8f-3fc2-48dc-8686-9575b9b24584',
            '7f113269-bd42-4f6e-ad74-ebf235f32c64',
          ],
          props: {
            color: '#fff',
            image: {
              url: 'https://i.imgur.com/CcFOSpE.jpg',
              thumb: 'https://i.imgur.com/CcFOSpEm.jpg',
              rotate: 0,
              boxSize: {
                width: 1642.6666666666663,
                height: 924,
              },
              position: {
                x: -1.3333333333332575,
                y: 0,
              },
            },
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '0394ef8f-3fc2-48dc-8686-9575b9b24584': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Acme;font-size: 144px;color: rgb(10, 46, 68);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(10, 46, 68);">Welcome</span></p>',
            fonts: [
              {
                name: 'Acme',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Acme&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(10, 46, 68)'],
            effect: {
              name: 'shadow',
              settings: {
                blur: 0,
                color: 'rgb(0, 0, 0)',
                offset: 50,
                direction: 45,
                transparency: 40,
              },
            },
            rotate: 0,
            boxSize: {
              x: 750.0110584518168,
              y: 278.5687203791469,
              width: 801.414691943128,
              height: 202,
            },
            position: {
              x: 419.6793048973144,
              y: 211.20695102685625,
            },
            fontSizes: [144],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '7f113269-bd42-4f6e-ad74-ebf235f32c64': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Joti One;font-size: 180px;color: rgb(10, 46, 68);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(10, 46, 68);">Summer</span></p>',
            fonts: [
              {
                name: 'Joti One',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Joti+One&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(10, 46, 68)'],
            effect: {
              name: 'lift',
              settings: {
                intensity: 50,
              },
            },
            rotate: 0,
            boxSize: {
              x: 520.2890995260664,
              y: 390.8641390205371,
              width: 851.9229857819898,
              height: 252,
            },
            position: {
              x: 411.4739336492891,
              y: 459.521327014218,
            },
            fontSizes: [180],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
  {
    id: 6,
    img: 'https://i.imgur.com/NXLE4hq.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '9bd81de2-6ccb-4766-9063-4e9ee0754168',
            'f3ec6d81-e706-4d20-a00d-ce9b50f199a4',
            'bb405677-185e-4dcc-bd12-a408779a081e',
            'b889f2e8-0313-47a9-ab8a-c9715b83ceaa',
            '9162ff43-0c71-4d40-9236-ebd133054b46',
          ],
          props: {
            color: 'rgb(84, 101, 116)',
            image: {
              url: 'https://i.imgur.com/s7d65tS.jpg',
              thumb: 'https://i.imgur.com/s7d65tSm.jpg',
              rotate: 0,
              boxSize: {
                width: 1640,
                height: 2050,
              },
              position: {
                x: 0,
                y: -563,
              },
              transparency: 0.26,
            },
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '9162ff43-0c71-4d40-9236-ebd133054b46': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: A Bee Zee;font-size: 42px;color: rgb(22, 22, 22);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(22, 22, 22);">Shop now at www.website.com</span></p>',
            fonts: [
              {
                name: 'A Bee Zee',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
                    ],
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
                    ],
                    style: 'Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(22, 22, 22)'],
            rotate: 0,
            boxSize: {
              x: 502.5860979462875,
              y: 788.9636650868879,
              width: 692.5789889415482,
              height: 59,
            },
            position: {
              x: 475.5860979462875,
              y: 798.9636650868879,
            },
            fontSizes: [42],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '9bd81de2-6ccb-4766-9063-4e9ee0754168': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/hjGyWbU.jpg',
              thumb: 'https://i.imgur.com/hjGyWbUm.jpg',
              rotate: 0,
              boxSize: {
                width: 409.46534653465346,
                height: 273,
              },
              position: {
                x: -190.61608774384547,
                y: 0,
              },
            },
            scale: 1.6923076923076923,
            rotate: 0,
            boxSize: {
              width: 312.0446153846154,
              height: 462,
            },
            clipPath:
              'M 57.3 267.8 c 10.8 3.5 22.3 5.2 34.2 5.2 c 13.1 0 25.4 -1.6 36.4 -4.6 c 11.5 -3.2 21.6 -8.4 29.9 -15.4 c 8.5 -7.1 15.1 -16.4 19.8 -27.6 c 4.6 -11 6.9 -24.3 6.9 -39.5 c 0 -12.5 -1.9 -23.3 -5.7 -32.2 s -8.9 -16.4 -15.1 -22.5 c -6.1 -5.9 -13 -10.9 -20.8 -14.7 c -7.2 -3.6 -14.7 -6.8 -22.3 -9.5 c -7.3 -2.6 -14.5 -5 -21.6 -7.2 c -6.4 -2 -12.2 -4.3 -17.2 -7 c -4.4 -2.3 -8 -5.1 -10.6 -8.3 C 69 82 68 78.7 68 74.5 c 0 -5.1 1.8 -9.1 5.6 -12.4 c 4.1 -3.5 10.7 -5.3 19.5 -5.3 c 7.8 0 13.7 2.1 17.8 6.5 c 4.3 4.6 6.3 10.8 6.3 19.1 v 11.8 H 180 V 81.3 c 0 -13.8 -2.4 -26 -7 -36.3 c -4.7 -10.3 -11.1 -19 -19.1 -25.7 c -7.9 -6.6 -17.3 -11.6 -27.8 -14.7 C 115.9 1.5 105 0 93.5 0 C 82.2 0 71.1 1.5 60.7 4.6 C 50 7.7 40.5 12.5 32.3 18.9 c -8.3 6.5 -15 14.7 -19.9 24.5 c -5 9.8 -7.5 21.4 -7.5 34.4 c 0 10.2 1.2 19.3 3.6 27.1 s 5.8 14.8 10.2 20.6 c 4.3 5.8 9.3 10.8 14.9 14.9 c 5.4 3.9 11.1 7.4 17.2 10.2 c 5.8 2.8 12 5.2 18.2 7.3 c 5.9 2 11.6 3.9 17.2 5.9 c 5.3 1.9 10.4 3.8 15.2 5.9 c 4.3 1.9 8 4.1 11.2 6.6 c 2.8 2.3 5 4.9 6.6 8 c 1.5 2.9 2.3 6.7 2.3 11.1 c 0 2.7 -0.4 5.2 -1.4 7.7 c -0.8 2.3 -2.1 4.3 -4.1 6.1 c -2 1.9 -4.7 3.5 -8.3 4.8 c -3.7 1.4 -8.5 2 -14.2 2 c -5 0 -9.5 -0.7 -13.2 -2.2 c -3.6 -1.4 -6.6 -3.4 -9 -6 c -2.5 -2.6 -4.3 -5.7 -5.6 -9.4 c -1.4 -3.9 -2.1 -8.3 -2.1 -13.2 c 0 -0.7 0 -1.6 0.1 -2.6 c 0.1 -1.7 0.2 -2.7 0.2 -3.2 v -8.5 H 0.3 v 8.5 c 0 0.8 -0.1 1.8 -0.1 2.9 c -0.1 1.7 -0.2 3.4 -0.2 5 c 0 13.9 2.5 26.3 7.5 37 s 11.9 19.8 20.4 27 c 8.4 7.5 18.3 12.9 29.4 16.5 Z',
            position: {
              x: 135.44688662048853,
              y: 212.86413902053712,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b889f2e8-0313-47a9-ab8a-c9715b83ceaa': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/UfLwAPI.jpg',
              thumb: 'https://i.imgur.com/UfLwAPIm.jpg',
              rotate: 0,
              boxSize: {
                width: 229.91186415363785,
                height: 343.9896606382033,
              },
              position: {
                x: -46.83445939468279,
                y: -54.81712589674675,
              },
            },
            scale: 1.7487414360876643,
            rotate: 0,
            boxSize: {
              width: 300.43377871986075,
              height: 462,
            },
            clipPath:
              'M 171.8 206.2 H 61.7 v -47.9 h 92.7 v -58.2 H 61.7 V 58.2 h 108.1 V 0 H 0 v 264.2 h 171.8 Z',
            position: {
              x: 1219.0406145895167,
              y: 211.56872037914695,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'bb405677-185e-4dcc-bd12-a408779a081e': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            color: 'rgb(241, 192, 191)',
            image: {
              url: 'https://i.imgur.com/P9Rnk1v.jpg',
              thumb: 'https://i.imgur.com/P9Rnk1vm.jpg',
              rotate: 0,
              boxSize: {
                width: 307.24330563591224,
                height: 460.8649584538684,
              },
              position: {
                x: -144.15330563591223,
                y: -135.56127079871155,
              },
            },
            scale: 1.7487414360876643,
            rotate: 0,
            boxSize: {
              width: 285.2022408115372,
              height: 462,
            },
            clipPath: 'M 0 0 v 264.2 h 163.1 v -60.3 H 61.7 V 0 Z',
            position: {
              x: 902.8017231961272,
              y: 214.15955766192735,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'f3ec6d81-e706-4d20-a00d-ce9b50f199a4': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/cgjMEhr.jpg',
              thumb: 'https://i.imgur.com/cgjMEhrm.jpg',
              rotate: 0,
              boxSize: {
                width: 655.1848040663917,
                height: 436.78986937759447,
              },
              position: {
                x: -192.98175179349352,
                y: -168.8960095197062,
              },
            },
            scale: 1.7487414360876643,
            rotate: 0,
            boxSize: {
              width: 401.49354631136686,
              height: 462,
            },
            clipPath:
              'M 75.6 215.7 h 75.7 l 14.7 48.6 h 63.6 L 147.2 0 H 82.6 L 0 264.2 h 61.3 l 14.3 -48.5 Z m 38 -128.2 l 20.4 70 H 92.9 l 20.7 -70 Z',
            position: {
              x: 470.280083084443,
              y: 214.15955766192735,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
  {
    id: 7,
    img: 'https://i.imgur.com/P4qF5We.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            'b9d3c639-b0f4-4bca-9f00-917b908f646a',
            '2dbc4e55-c280-4a6e-b379-0098e56a66e1',
            'cbfe7c02-f0aa-4f93-8d16-d42b069171e7',
            '485ece66-b915-4813-b43a-df108d67b50c',
            '20e8d9ae-6bf4-4560-8398-9e1200a189a1',
            '3ee58ef6-95ac-454e-8228-a363946750fa',
          ],
          props: {
            color: 'rgb(255, 255, 255)',
            image: {
              url: 'https://i.imgur.com/vbBynfJ.jpg',
              thumb: 'https://i.imgur.com/vbBynfJm.jpg',
              rotate: 0,
              boxSize: {
                width: 1640,
                height: 1230,
              },
              position: {
                x: 0,
                y: -153,
              },
              transparency: 0.26,
            },
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '20e8d9ae-6bf4-4560-8398-9e1200a189a1': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/IHTcWOG.jpg',
              thumb: 'https://i.imgur.com/IHTcWOGm.jpg',
              rotate: 0,
              boxSize: {
                width: 888.8899999999996,
                height: 666.6674999999998,
              },
              position: {
                x: 0,
                y: 0,
              },
            },
            scale: 0.577917925469892,
            rotate: 0,
            boxSize: {
              x: 25.260663507109,
              y: 342.78107283882497,
              width: 513.705464770932,
              height: 288.95896273494594,
            },
            clipPath:
              'M 0 458.6 V 41.4 C 0 18.5 18.5 0 41.4 0 h 806 c 22.9 0 41.4 18.5 41.4 41.4 v 417.1 c 0 22.9 -18.5 41.4 -41.4 41.4 h -806 C 18.6 500 0 481.5 0 458.6 C 0 458.6 0 458.6 0 458.6 Z',
            position: {
              x: 1107.5039494470773,
              y: 432.733679473896,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '2dbc4e55-c280-4a6e-b379-0098e56a66e1': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(166, 166, 166)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: -54.66034755134274,
              y: 316.6603475513428,
              width: 1746.7298578199052,
              height: 326.9510268562401,
            },
            position: {
              x: -57.251184834123144,
              y: 411.2259083728278,
            },
            transparency: 0.43,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '3ee58ef6-95ac-454e-8228-a363946750fa': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Alatsi;text-transform: uppercase;font-size: 24px;color: rgb(84, 84, 84);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(84, 84, 84);">NHA TRANG BEACH</span></p>',
            fonts: [
              {
                name: 'Alatsi',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alatsi&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(84, 84, 84)'],
            rotate: 0,
            boxSize: {
              x: 581.6066350710901,
              y: 115.34597156398104,
              width: 857.1176935229068,
              height: 34,
            },
            position: {
              x: 387.86255924170615,
              y: 210.48025276461317,
            },
            fontSizes: [24],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '485ece66-b915-4813-b43a-df108d67b50c': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/xqLlVks.jpg',
              thumb: 'https://i.imgur.com/xqLlVksm.jpg',
              rotate: 0,
              boxSize: {
                width: 888.8899999999996,
                height: 1185.1866666666665,
              },
              position: {
                x: 0,
                y: -573.8309153955626,
              },
            },
            scale: 0.577917925469892,
            rotate: 0,
            boxSize: {
              x: 25.260663507109,
              y: 342.78107283882497,
              width: 513.705464770932,
              height: 288.95896273494594,
            },
            clipPath:
              'M 0 458.6 V 41.4 C 0 18.5 18.5 0 41.4 0 h 806 c 22.9 0 41.4 18.5 41.4 41.4 v 417.1 c 0 22.9 -18.5 41.4 -41.4 41.4 h -806 C 18.6 500 0 481.5 0 458.6 C 0 458.6 0 458.6 0 458.6 Z',
            position: {
              x: 563.7914691943129,
              y: 431.8016099636274,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b9d3c639-b0f4-4bca-9f00-917b908f646a': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: center;font-family: Akronim;text-transform: uppercase;font-size: 104px;color: rgb(84, 84, 84);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(84, 84, 84);">LIQUID EDITOR</span></p>',
            fonts: [
              {
                name: 'Akronim',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Akronim&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(84, 84, 84)'],
            rotate: 0,
            boxSize: {
              x: 581.6066350710901,
              y: 115.34597156398104,
              width: 857.1176935229068,
              height: 146,
            },
            position: {
              x: 410.6113744075829,
              y: 71.30173775671405,
            },
            fontSizes: [104],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'cbfe7c02-f0aa-4f93-8d16-d42b069171e7': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/2MvVGbT.jpg',
              thumb: 'https://i.imgur.com/2MvVGbTm.jpg',
              rotate: 0,
              boxSize: {
                width: 888.8899999999996,
                height: 666.6674999999998,
              },
              position: {
                x: 0,
                y: -134.49162079583496,
              },
            },
            scale: 0.577917925469892,
            rotate: 0,
            boxSize: {
              x: 25.260663507109,
              y: 342.78107283882497,
              width: 513.705464770932,
              height: 288.95896273494594,
            },
            clipPath:
              'M 0 458.6 V 41.4 C 0 18.5 18.5 0 41.4 0 h 806 c 22.9 0 41.4 18.5 41.4 41.4 v 417.1 c 0 22.9 -18.5 41.4 -41.4 41.4 h -806 C 18.6 500 0 481.5 0 458.6 C 0 458.6 0 458.6 0 458.6 Z',
            position: {
              x: 21.37440758293844,
              y: 430.8695404533589,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
  {
    id: 8,
    img: 'https://i.imgur.com/WX2aUtf.png',
    elements: {
      layers: {
        ROOT: {
          type: {
            resolvedName: 'RootLayer',
          },
          child: [
            '1daee7b6-feac-4858-bc2f-b1e287e58cee',
            '5edd3cf1-b6dc-4155-b571-be5f58067cf4',
            'a2460d24-9bd3-42a3-b584-2ca0fba5ffd4',
            'c45ecf9d-dbff-48f8-80cd-2f48ec06f8e6',
            '4a0b3f5f-caed-4ee6-915e-e5c7ff009187',
            '0917107f-1412-43ef-9947-458729f1b364',
            'f1e74aa4-4a92-4222-876d-ff173e8a9265',
            '17009779-23e3-4d22-b27b-983ae67656b9',
            '7afe61af-92c2-48c9-b32c-61715d817abd',
            '37070d10-7b00-49d5-9f61-4b86d9053d62',
            'dbab2668-4782-448e-815c-7e97ccad6516',
            '65d56456-fa65-48ed-908b-ba9960fe1379',
            '5c6049b9-7253-4193-9054-703f71ecebc6',
            'ad11e998-3f3e-4f69-ab95-66a3a5cf705d',
            'e8c9bf1f-6481-4b50-ae15-be0448216672',
            '98575875-1d7f-42ce-8a05-72fa557b949a',
            'b39cbc06-7500-4d85-a641-cae472460832',
            '0004151d-e42f-43f6-a83e-f7a5bf5fea91',
            '29f86fe3-b9a8-4049-8ed2-0517e775fd03',
            '83e0e3ea-9431-46dc-b1d5-bd41c4510428',
            '2ecfba73-5817-48f6-85d6-f80138214469',
            '30d5f9bc-f900-41b5-a66a-d1d4b2532ea1',
            'b7d18dea-f62e-448e-a6e8-e03b2e7f3d04',
            '9b8d8202-50f5-4335-a851-72ce38ccec78',
          ],
          props: {
            color: 'rgb(255, 220, 181)',
            image: null,
            rotate: 0,
            boxSize: {
              width: 1640,
              height: 924,
            },
            position: {
              x: 0,
              y: 0,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            },
            hasMobileFrame: false,
            mobileOrientation: 'portrait',
          },
          locked: false,
          parent: null,
        },
        '0004151d-e42f-43f6-a83e-f7a5bf5fea91': {
          type: {
            resolvedName: 'GroupLayer',
          },
          child: [
            '7acaa20f-d138-4559-a5a7-7b1e7341d815',
            '3f7a324c-f80a-4439-b28e-596ce5a1eedc',
          ],
          props: {
            scale: 1,
            rotate: 0,
            boxSize: {
              width: 618.3973143759878,
              height: 34.93206951026866,
            },
            position: {
              x: 935.09794628752,
              y: 593.1974723538704,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '0917107f-1412-43ef-9947-458729f1b364': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Aleo;font-size: 36px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(102, 58, 34);">SHAKE</span></strong></p>',
            fonts: [
              {
                name: 'Aleo',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold_Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 928.052132701422,
              y: 117.21011058451812,
              width: 331.5410742496051,
              height: 50,
            },
            position: {
              x: 928.052132701422,
              y: 117.21011058451812,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '17009779-23e3-4d22-b27b-983ae67656b9': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1362.586097946288,
              y: 185.1406003159557,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '1daee7b6-feac-4858-bc2f-b1e287e58cee': {
          type: {
            resolvedName: 'ShapeLayer',
          },
          child: [],
          props: {
            color: 'rgb(102, 58, 34)',
            shape: 'rectangle',
            rotate: 0,
            boxSize: {
              x: -15.797788309636644,
              y: -9.78515007898892,
              width: 891.7535545023696,
              height: 1038.135860979463,
            },
            position: {
              x: -62.43285939968404,
              y: -27.921011058451796,
            },
            roundedCorners: 0,
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '29f86fe3-b9a8-4049-8ed2-0517e775fd03': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Americano</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 932.1437598736178,
              y: 656.3096366508688,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '2ecfba73-5817-48f6-85d6-f80138214469': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Cortado</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 930.1216429699844,
              y: 783.8293838862559,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '30d5f9bc-f900-41b5-a66a-d1d4b2532ea1': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1360.5639810426544,
              y: 655.9462875197472,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '37070d10-7b00-49d5-9f61-4b86d9053d62': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1363.1548183254347,
              y: 242.7077409162717,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '3f7a324c-f80a-4439-b28e-596ce5a1eedc': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Aldrich; color: rgb(102, 58, 34); font-size: 28px; text-align: right">$17</p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 34,
            },
            position: {
              x: 421.94312796208567,
              y: 0.9320695102686614,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '0004151d-e42f-43f6-a83e-f7a5bf5fea91',
        },
        '4a0b3f5f-caed-4ee6-915e-e5c7ff009187': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Alata;font-size: 36px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">+123456789</span></p>',
            fonts: [
              {
                name: 'Alata',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alata&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 52.712480252764735,
              y: 423.2922590837282,
              width: 346.5410742496051,
              height: 50,
            },
            position: {
              x: 52.712480252764735,
              y: 423.2922590837282,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '5c6049b9-7253-4193-9054-703f71ecebc6': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Mango</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 933.2812006319116,
              y: 373.7503949447077,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '5edd3cf1-b6dc-4155-b571-be5f58067cf4': {
          type: {
            resolvedName: 'FrameLayer',
          },
          child: [],
          props: {
            image: {
              url: 'https://i.imgur.com/CFJ0AJ3.jpg',
              thumb: 'https://i.imgur.com/CFJ0AJ3m.jpg',
              rotate: 0,
              boxSize: {
                width: 1157.523098281392,
                height: 1736.2846474220885,
              },
              position: {
                x: -335.0704061604536,
                y: -593.0325598572044,
              },
            },
            scale: 0.6934154818325435,
            rotate: 0,
            boxSize: {
              x: 41.037914691943115,
              y: 593.7172195892574,
              width: 346.70774091627175,
              height: 346.70774091627175,
            },
            clipPath:
              'path("M 500 250.002 c 0 138.065 -111.931 249.996 -250 249.996 c -138.071 0 -250 -111.931 -250 -249.996 C 0 111.93 111.929 0 250 0 s 250 111.93 250 250.002 Z")',
            position: {
              x: 41.037914691943115,
              y: 565.2180094786729,
            },
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '65d56456-fa65-48ed-908b-ba9960fe1379': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1359.8372827804112,
              y: 309.3428120063191,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '7acaa20f-d138-4559-a5a7-7b1e7341d815': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Aldrich; color: rgb(102, 58, 34); font-size: 28px; text-align: justify">Espresso</p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 34,
            },
            position: {
              x: 0,
              y: 0,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: '0004151d-e42f-43f6-a83e-f7a5bf5fea91',
        },
        '7afe61af-92c2-48c9-b32c-61715d817abd': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Peach</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 934.7345971563982,
              y: 241.7756714060031,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '83e0e3ea-9431-46dc-b1d5-bd41c4510428': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Late</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 934.3712480252766,
              y: 720.7172195892575,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '98575875-1d7f-42ce-8a05-72fa557b949a': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1359.6793048973143,
              y: 437.431279620853,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        '9b8d8202-50f5-4335-a851-72ce38ccec78': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1361.1327014218016,
              y: 783.4660347551342,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'a2460d24-9bd3-42a3-b584-2ca0fba5ffd4': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Alata;font-size: 36px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">Drink &amp; Refreshment</span></p>',
            fonts: [
              {
                name: 'Alata',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alata&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 60.84834123222761,
              y: 184.00315955766192,
              width: 472.3783570300159,
              height: 50,
            },
            position: {
              x: 49.18957345971576,
              y: 248.77409162717217,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'ad11e998-3f3e-4f69-ab95-66a3a5cf705d': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1360.4060031595582,
              y: 374.68246445497624,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b39cbc06-7500-4d85-a641-cae472460832': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Aleo;font-size: 36px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><strong><span style="color: rgb(102, 58, 34);">COFFEE</span></strong></p>',
            fonts: [
              {
                name: 'Aleo',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold_Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Bold',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                    style: 'Italic',
                  },
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 47.53080568720392,
              y: 348.1579778830963,
              width: 341.5410742496051,
              height: 50,
            },
            position: {
              x: 925.09794628752,
              y: 526.1990521327014,
            },
            fontSizes: [36],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'b7d18dea-f62e-448e-a6e8-e03b2e7f3d04': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: right;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">$17</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 1350.927330173776,
              y: 182.5497630331753,
              width: 196.45418641390216,
              height: 39,
            },
            position: {
              x: 1361.496050552923,
              y: 720.3538704581357,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'c45ecf9d-dbff-48f8-80cd-2f48ec06f8e6': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="font-family: Alatsi;font-size: 80px;color: rgb(255, 255, 255);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(255, 255, 255);">LIQUID COFFEE</span></p>',
            fonts: [
              {
                name: 'Alatsi',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Alatsi&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(255, 255, 255)'],
            effect: {
              name: 'lift',
              settings: {
                intensity: 50,
              },
            },
            rotate: 0,
            boxSize: {
              x: 51.34755134281203,
              y: 65.71406003159557,
              width: 643.3605845181668,
              height: 112,
            },
            position: {
              x: 48.75671406003162,
              y: 144.73459715639808,
            },
            fontSizes: [80],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'dbab2668-4782-448e-815c-7e97ccad6516': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Peach</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 936.5987361769354,
              y: 309.70616113744074,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'e8c9bf1f-6481-4b50-ae15-be0448216672': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Green Tea</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 931.575039494471,
              y: 182.91311216429693,
              width: 439.9928909952607,
              height: 39,
            },
            position: {
              x: 931.259083728278,
              y: 436.4992101105845,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
        'f1e74aa4-4a92-4222-876d-ff173e8a9265': {
          type: {
            resolvedName: 'TextLayer',
          },
          child: [],
          props: {
            text: '<p style="text-align: justify;font-family: Aldrich;font-size: 28px;color: rgb(102, 58, 34);line-height: 1.4;letter-spacing: 0em;"><span style="color: rgb(102, 58, 34);">Strawberry</span></p>',
            fonts: [
              {
                name: 'Aldrich',
                fonts: [
                  {
                    urls: [
                      'https://fonts.googleapis.com/css2?family=Aldrich&display=swap',
                    ],
                  },
                ],
              },
            ],
            scale: 1,
            colors: ['rgb(102, 58, 34)'],
            effect: null,
            rotate: 0,
            boxSize: {
              x: 930.2796208530808,
              y: 184.20853080568716,
              width: 427.9928909952607,
              height: 39,
            },
            position: {
              x: 930.2796208530808,
              y: 184.20853080568716,
            },
            fontSizes: [28],
            hyperlink: {
              hasHyperlink: false,
              typeLink: 'a-href',
              linkRedirect: redirectURLDefault
            }
          },
          locked: false,
          parent: 'ROOT',
        },
      },
    },
  },
];
