import { generateValidURL } from "./generateValidURL";

export interface RedirectionProps {
  hasHyperlink?: boolean;
  linkRedirect?: string;
  typeLink?: 'a-href' | 'click-tag';
}

export const generateRedirectionHTML = (props: RedirectionProps, isContainer?: boolean) => {
  const { hasHyperlink = true, linkRedirect = '', typeLink = 'a-href' } = props;

  if (!hasHyperlink) {
    return '';
  }

  return typeLink === 'a-href'
    ? `onClick="redirectToURL(${isContainer ? `clickTag` : `'${generateValidURL(linkRedirect)}'`}, event)"`
    : `onClick="redirectClickTag(${isContainer ? `clickTag` : `'${generateValidURL(linkRedirect)}'`}, event)"`;
};
