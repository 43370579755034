import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  onChange?: (file: File | null) => void;
  preview?: string;
  previewFileType?: 'image' | 'video';
  enableRemove?: boolean;
  enableEdit?: boolean;
  styles?: {
    container?: React.CSSProperties;
    image?: React.CSSProperties;
    text?: React.CSSProperties;
    closeButton?: React.CSSProperties;
  };
  dimensionsRequired?: {
    width: number;
    height: number;
  };
  acceptedFormats?: string[];
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onChange,
  preview,
  previewFileType = 'image',
  styles = {},
  enableRemove = false,
  enableEdit = false,
  dimensionsRequired,
  acceptedFormats = ['image/*', 'video/*'],
}) => {
  const [localPreview, setLocalPreview] = useState<string | null>(preview || null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setLocalPreview(preview || null);
    setErrorMessage(null);
  }, [preview, previewFileType]);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0] || null;
    handleFile(file);
  };

  const handleFile = (file: File | null) => {
    if (file) {
      const fileType = file.type;

      if (fileType.startsWith('image/')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const { width, height } = img;

          if (dimensionsRequired) {
            const { width: expectedWidth, height: expectedHeight } = dimensionsRequired;
            if (width !== expectedWidth || height !== expectedHeight) {
              setErrorMessage(
                `The image must be exactly ${expectedWidth}px width and ${expectedHeight}px height.`
              );
              onChange?.(null);
              setLocalPreview(null);
              return;
            }
          }

          const previewURL = URL.createObjectURL(file);
          setLocalPreview(previewURL);
          setErrorMessage(null);
          onChange?.(file);
        };

        img.onerror = () => {
          setErrorMessage('Error loading the image.');
          handleFile(null);
        };
      } else if (fileType.startsWith('video/')) {

        const previewURL = URL.createObjectURL(file);
        setLocalPreview(previewURL);
        setErrorMessage(null);
        onChange?.(file);
      }
    } else {
      setLocalPreview(null);
      onChange?.(null);
      setErrorMessage(null);
    }
  };

  const handleRemoveImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: localPreview && !enableEdit,
  });

  return (
    <div
      {...getRootProps()}
      style={{
        border: '2px dashed #000',
        borderRadius: '8px',
        padding: '2px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
        height: '150px',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...styles.container,
      }}
    >
      <input {...getInputProps()} accept={acceptedFormats.join(',')} />
      {errorMessage ? (
        <p style={{ color: 'red' }}>{errorMessage}</p>
      ) : localPreview ? (
        <>
          {previewFileType === 'image' ? (
            <img
              alt="Preview"
              src={localPreview}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                ...styles.image,
              }}
            />
          ) : previewFileType === 'video' ? (
            <video
              muted
              src={localPreview}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                ...styles.image,
              }}
            />
          ) : null}
          {enableRemove && (
            <button
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                ...styles.closeButton,
              }}
              onClick={handleRemoveImage}
            >
              X
            </button>
          )}
        </>
      ) : (
        <p style={{ color: '#777', ...styles.text }}>
          Drag and drop an image or video, or click to select
        </p>
      )}
    </div>
  );
};
