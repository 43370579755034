import { ImageLayerProps } from "@lidojs/design-editor";
import { RedirectionProps, generateRedirectionHTML } from "../generateRedirectionHTML";
import { generateValidURL } from "../generateValidURL";
import { PageExport, containerPremium } from "./generatePremiumHTML";

export const generateFlipUp = ({page,hasMobileFrame}: PageExport) => {
  const { ROOT: container, ...components } = page;

  const resources = {
    image1: {
      url:  (components.flipUp_image1.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipUp_image1.props.hyperlink,
    },
    image2: {
      url:  (components.flipUp_image2.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipUp_image2.props.hyperlink,
    },
    image3: {
      url:  (components.flipUp_image3.props as unknown as ImageLayerProps).image?.url,
      hyperlink: components.flipUp_image3.props.hyperlink,
    },
    line1: {
      color: components.flipUp_line1.props.color,
    }
  }

  const redirectionContainer = generateRedirectionHTML(
    container.props.hyperlink, true
  );

  return `
      <title>Creative Premium - FlipUp</title>
      ${flipUpStyles}
      <style>
        .deslizador {
          position: absolute;
          left: 0;
          width: 100%;
          height: 10px;
          cursor: ns-resize;
          top: 50%; /* Centrar verticalmente */
          transform: translateY(-50%); /* Ajustar para centrar */
          background: ${resources.line1.color};
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .deslizador::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 50px;
          height: 50px;
          transform: translate(-50%, -50%);
          background-image: url(${resources.image3.url});
          background-size: cover;
          background-position: center;
          border-radius: 50%;
        }
      </style>

        <script type="text/javascript">
          var clickTag = "${generateValidURL((container?.props?.hyperlink as RedirectionProps)?.linkRedirect)}";
        </script>
    </head>
    <body ${redirectionContainer}>
      ${containerPremium(hasMobileFrame)}
          <div class="comparador">
            <img class="imagen" src="${resources.image1.url}" id="img1" alt="Image 1">
            <img  class="imagen" src="${resources.image2.url}" id="img2" alt="Image 2">
            <div class="deslizador" id="slider">
            </div>
          </div>
        </div>
        ${hasMobileFrame ? "</div>" : ""}
      </div>
     ${flipUpJS1}
  `
}


const flipUpJS1 = `
 <script>
          const slider = document.getElementById('slider');
          const img2 = document.getElementById('img2');
          const comparador = document.querySelector('.comparador');

          let isDragging = false;
          let initialY = 0;

          // Actualizar posición del deslizador
          function updateSliderPosition(y) {
            const rect = comparador.getBoundingClientRect();
            let positionY = y - rect.top;

            // Limitar el movimiento del deslizador
            if (positionY < 0) positionY = 0;
            if (positionY > rect.height) positionY = rect.height;

            img2.style.clipPath = 'inset(' + positionY + 'px 0 0 0)'; // Usar comillas simples
            slider.style.top = (positionY - 5) + 'px'; // Y aquí también
          }

          // Prevenir el comportamiento predeterminado de desplazamiento
          function preventDefault(e) {
              e.preventDefault();
          }

          // Eventos para mouse
          slider.addEventListener('mousedown', (e) => {
              isDragging = true;
              updateSliderPosition(e.clientY);
              window.addEventListener('mousemove', preventDefault, { passive: false });
          });

          window.addEventListener('mouseup', () => {
              isDragging = false;
              window.removeEventListener('mousemove', preventDefault);
          });

          window.addEventListener('mousemove', (e) => {
              if (isDragging) {
                  updateSliderPosition(e.clientY);
              }
          });

          // Eventos para touch
          slider.addEventListener('touchstart', (e) => {
              isDragging = true;
              updateSliderPosition(e.touches[0].clientY);
              window.addEventListener('touchmove', preventDefault, { passive: false });
          });

          window.addEventListener('touchend', () => {
              isDragging = false;
              window.removeEventListener('touchmove', preventDefault);
          });

          window.addEventListener('touchmove', (e) => {
              if (isDragging) {
                  updateSliderPosition(e.touches[0].clientY);
              }
          });
      </script>

`

const flipUpStyles = `
<style>


  body {
    background-color: #000000;
  }

  .comparador {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .imagen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  #img1 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
  }

  #img2 {
    clip-path: inset(50% 0 0 0);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
  }
</style>
`
