import { FunctionComponent } from 'react'

import { DynamicIconProps } from '../../types/common';
import * as allIcons from './icons/index'

export const IconNames = [
  'AdjustIcon',
  'AlignTextIcon',
  'AnimateIcon',
  'AnimationIcon',
  'AppleIcon',
  'BoldIcon',
  'ButtonIcon',
  'CarouselIcon',
  'CarouselModel1Icon',
  'CarouselModel2Icon',
  'CarouselModel3Icon',
  'CarouselModel4Icon',
  'CarouselModel5Icon',
  'CarouselModel6Icon',
  'CarouselModel7Icon',
  'CarouselModel8Icon',
  'ColorTextIcon',
  'CopyIcon',
  'DeleteIcon',
  'DownloadIcon',
  'DuplicateIcon',
  'FacebookIcon',
  'FormatIcon',
  'GridIcon',
  'HyperlinkIcon',
  'ImgIcon',
  'InstagramIcon',
  'InternetIcon',
  'ItalicIcon',
  'LinkedinIcon',
  'MobileIcon',
  'MobileOffIcon',
  'MobileOrientationIcon',
  'PasteIcon',
  'PhoneIcon',
  'SizeTextIcon',
  'TemplateIcon',
  'TyphographyIcon',
  'TwitterIcon',
  'UploadIcon',
  'Upload2Icon',
  'UnderlinedIcon',
  'VideoIcon',
  'WallpaperIcon',
  'XIcon'
] as const

export type IconName = (typeof IconNames)[number]

export interface IconProps extends DynamicIconProps {
  name: IconName;
}

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size = 24,
  className,
  color,
  style = {}
}) => {
  const DynamicIcon = (
    allIcons as { [key: string]: FunctionComponent<DynamicIconProps> }
  )[name]

  return (
    <DynamicIcon className={className} color={color} size={size} style={style}></DynamicIcon>
  )
}
