
import { FunctionComponent } from 'react'
import { calculateHeight } from '../../../ultils/calculateSize'
import { IconProps } from '../Icon'

const DuplicateIcon: FunctionComponent<IconProps> = ({
  className,
  size = 24,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height={calculateHeight(size, 17 / 13)}
      style={style}
      viewBox='0 0 17 13'
      width={size}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.91667 9.38889H11.3333V7.22222H13.4583V5.77778H11.3333V3.61111H9.91667V5.77778H7.79167V7.22222H9.91667V9.38889ZM4.25 12.6389C2.96319 12.1815 1.93316 11.39 1.1599 10.2646C0.386632 9.13912 0 7.88426 0 6.5C0 5.11574 0.386632 3.86088 1.1599 2.73542C1.93316 1.60995 2.96319 0.818519 4.25 0.361111V1.95C3.37639 2.3713 2.68576 2.9912 2.17812 3.80972C1.67049 4.62824 1.41667 5.525 1.41667 6.5C1.41667 7.475 1.67049 8.37176 2.17812 9.19028C2.68576 10.0088 3.37639 10.6287 4.25 11.05V12.6389ZM10.625 13C9.73958 13 8.91024 12.8285 8.13698 12.4854C7.36372 12.1424 6.6908 11.6789 6.11823 11.0951C5.54566 10.5113 5.09115 9.82523 4.75469 9.03681C4.41823 8.24838 4.25 7.40278 4.25 6.5C4.25 5.59722 4.41823 4.75162 4.75469 3.96319C5.09115 3.17477 5.54566 2.48866 6.11823 1.90486C6.6908 1.32106 7.36372 0.857639 8.13698 0.514583C8.91024 0.171528 9.73958 0 10.625 0C11.5104 0 12.3398 0.171528 13.113 0.514583C13.8863 0.857639 14.5592 1.32106 15.1318 1.90486C15.7043 2.48866 16.1589 3.17477 16.4953 3.96319C16.8318 4.75162 17 5.59722 17 6.5C17 7.40278 16.8318 8.24838 16.4953 9.03681C16.1589 9.82523 15.7043 10.5113 15.1318 11.0951C14.5592 11.6789 13.8863 12.1424 13.113 12.4854C12.3398 12.8285 11.5104 13 10.625 13ZM10.625 11.5556C12.0063 11.5556 13.178 11.065 14.1401 10.084C15.1023 9.10301 15.5833 7.90833 15.5833 6.5C15.5833 5.09167 15.1023 3.89699 14.1401 2.91597C13.178 1.93495 12.0063 1.44444 10.625 1.44444C9.24375 1.44444 8.07205 1.93495 7.1099 2.91597C6.14774 3.89699 5.66667 5.09167 5.66667 6.5C5.66667 7.90833 6.14774 9.10301 7.1099 10.084C8.07205 11.065 9.24375 11.5556 10.625 11.5556Z'
        fill='#676767'
      />
    </svg>
  )
}

export default DuplicateIcon
