import { LayerId, SerializedLayer, SerializedLayers } from '@lidojs/design-core';
import { applyColorsToSvg } from '@lidojs/design-layers';
import { fetchSvgContent } from '@lidojs/design-utils';
import { uploadFile } from '../../services/upload/api';

interface IMedia {
  url: string | File;
  thumb?: string | File;
}

export async function processLayers(
  layers: SerializedLayers
): Promise<Record<LayerId, SerializedLayer>> {
  const clonedLayers = structuredClone(layers);

  async function checkAndUploadMedia(
    mediaProps: { url: string | File; thumb?: string | File } | File | string,
    type: string
  ): Promise<string | undefined> {
    if (mediaProps instanceof File) {
      try {
        const uploadedUrl = await uploadFile(mediaProps);
        console.log(`${type} File uploaded successfully: ${uploadedUrl}`);
        return uploadedUrl;
      } catch (error) {
        console.error(`Error uploading ${type} file: ${error.message}`);
        return undefined;
      }
    } else if (typeof mediaProps === 'string') {
      return mediaProps;
    } else if (mediaProps && mediaProps.url instanceof File) {
      try {
        const uploadedUrl = await uploadFile(mediaProps.url);
        mediaProps.url = uploadedUrl;
        if (type === 'Image') mediaProps.thumb = uploadedUrl;
        return uploadedUrl;
      } catch (error) {
        console.error(`Error uploading ${type} file: ${error.message}`);
        return undefined;
      }
    } else if (mediaProps && typeof mediaProps.url === 'string') {
      console.log(`Using existing ${type} URL: ${mediaProps.url}`);
      return mediaProps.url;
    } else {
      console.error(`Unexpected ${type} media type.`);
      return undefined;
    }
  }

  if (clonedLayers.ROOT.props.image) {
    await checkAndUploadMedia(clonedLayers.ROOT.props.image as IMedia, 'Image');
  }
  if (clonedLayers.ROOT.props.video) {
    await checkAndUploadMedia(clonedLayers.ROOT.props.video as IMedia, 'Video');
  }

  for (const key in clonedLayers) {
    if (key !== 'ROOT') {
      const layer = clonedLayers[key];
      const { resolvedName } = layer.type;
      switch (resolvedName) {
        case 'ImageLayer':
        case 'FrameLayer': {
          if (layer.props.image) {
            await checkAndUploadMedia(layer.props.image as IMedia, 'Image');
          }
          break;
        }

        case 'VideoLayer': {
          if (layer.props.video) {
            await checkAndUploadMedia(layer.props.video as IMedia, 'Video');
          }
          break;
        }

        case 'CarouselLayer': {
          const itemsCarousel = layer.props.itemsCarousel;
          if (itemsCarousel && Array.isArray(itemsCarousel)) {
            for (const item of itemsCarousel) {
              if (item.image) {
                const uploadedImageUrl = await checkAndUploadMedia(
                  item.image,
                  'Carousel Image'
                );
                if (uploadedImageUrl) {
                  item.image = uploadedImageUrl;
                }
              }
            }
          }
          break;
        }

        case 'SvgLayer': {
          let svgHTML: HTMLElement
          const svgUrl = layer.props.image as string;
          const colors = layer.props.colors;

          svgHTML = await fetchSvgContent(svgUrl);

          if(colors && svgHTML) {
            svgHTML = applyColorsToSvg(svgHTML, colors as string[]);
          }

          const serializer = new XMLSerializer();
          const svgString = serializer.serializeToString(svgHTML);
          if (svgString) {
            layer.props.image = svgString;
          }
          break;
        }

        default:
          break;
      }
    }
  }

  return clonedLayers;
}
