import {
  BoxSize,
  Delta,
  getGradientBackground,
  getTransformStyle,
  GradientStyle,
  LayerComponentProps,
} from '@lidojs/design-core';
import React, { FC, PropsWithChildren, useMemo } from 'react';

export interface FrameContentProps extends LayerComponentProps {
  clipPath: string;
  scale: number;
  color: string | null;
  gradientBackground: {
    colors: string[];
    style: GradientStyle;
  } | null;
  image: {
    url: string;
    original?: string | null;
    position: Delta;
    rotate: number;
    boxSize: BoxSize;
    flipHorizontal?: boolean;
    flipVertical?: boolean;
    brightness?: number | null;
    contrast?: number | null;
    grayscale?: number | null;
    saturation?: number | null;
    hueRotate?: number | null;
    blur?: number | null;
  } | null;
}

export const FrameContent: FC<PropsWithChildren<FrameContentProps>> = ({
  clipPath,
  image,
  color,
  scale,
  gradientBackground,
  boxSize,
  children,
}) => {
  const { flipVertical, flipHorizontal } = useMemo(
    () => ({
      flipVertical: image?.flipVertical,
      flipHorizontal: image?.flipHorizontal,
    }),
    [image]
  );
  const wrapperStyle = useMemo(
    () => ({
      transform:
        flipVertical || flipHorizontal
          ? `scale(${flipHorizontal ? -1 : 1}, ${flipVertical ? -1 : 1})`
          : undefined,
      width: boxSize.width / scale,
      height: boxSize.height / scale,
    }),
    [flipVertical, flipHorizontal, boxSize.width, boxSize.height, scale]
  );

  return (
    <div
      css={{
        overflow: 'hidden',
        pointerEvents: 'auto',
      }}
    >
      <div
        css={{
          width: '100%',
          height: '100%',
          clipPath: `path("${clipPath}")`,
          background: gradientBackground
            ? getGradientBackground(
                gradientBackground.colors,
                gradientBackground.style
              )
            : color ?? undefined,
        }}
      >
        {image && (
          <div css={wrapperStyle}>
            <div
              css={{
                width: image.boxSize.width,
                height: image.boxSize.height,
                transform: getTransformStyle({
                  position: image.position,
                  rotate: image.rotate,
                }),
                position: 'relative',
                userSelect: 'none',
              }}
            >
              {children ? (
                children
              ) : (
                <img
                  alt={typeof image?.url === 'string' ? image.url : URL.createObjectURL(image.url)}
                  css={{
                    objectFit: 'fill',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    pointerEvents: 'none',
                  }}
                  src={typeof image?.url === 'string' ? image.url : URL.createObjectURL(image.url)}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
