import { FunctionComponent } from 'react'
import { IconProps } from '../Icon'

const ColorTextIcon: FunctionComponent<IconProps> = ({
  className,
  style = {}
}) => {
  return (
    <svg
      className={className}
      fill='none'
      height='24'
      style={style}
      viewBox='0 0 20 24'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.29763 16L9.41763 4.8H10.5856L15.7056 16H14.4576L9.75363 5.488H10.2336L5.52963 16H4.29763ZM6.31363 13.008L6.66563 12.048H13.1776L13.5296 13.008H6.31363Z'
        fill='#676767'
      />
      <rect fill='#959595' height='3' width='20' y='21' />
    </svg>
  )
}

export default ColorTextIcon
