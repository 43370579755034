import React from 'react';
import { useEditor } from '../../../hooks';
import { Input } from '@nextui-org/react';
import { TextLayerProps } from 'libs/design-editor/src/layers';
import { extractText } from '../../../ultils';

export const TextPremiumSettings = () => {
  const { actions, activePage, pages } = useEditor((state) => ({
    activePage: state.activePage,
    pages: state.pages,
  }));

  const onChangeText = (id: string, newValue: string) => {
    const currentComponent = pages[activePage].layers[id];

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = (currentComponent.data.props as TextLayerProps).text;

    const updateTextContent = (element) => {
      element.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          node.textContent = newValue?.length ? newValue : ' ';
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          updateTextContent(node);
        }
      });
    };

    updateTextContent(tempDiv);
    const updatedHtml = tempDiv.innerHTML;

    actions.setProp<TextLayerProps>(activePage, id, {
      text: updatedHtml,
    });
  };

  const currentPage = pages[activePage].layers;
  const { ROOT, ...components } = currentPage;

  const textComponents = Object.values(components).filter(
    (component) => component.data.type === 'Text' && !component.data.props?.isHideSettings
  );

  if (!textComponents.length) return null;

  return textComponents.map((text, index) => (
    <div key={text.id}>
      <div css={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <label htmlFor={text.id} css={{fontWeight: 'bolder'}}>{text.data.props?.label || `Text ${index + 1}`}</label>
        <Input name={text.id} onChange={(e) => onChangeText(text.id, e.target.value)} value={extractText((text.data.props as TextLayerProps).text)}/>
      </div>
    </div>
  ));
};
